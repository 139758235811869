import React, { lazy } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { globalUiReadyPocketSelector, globalUiErrorSelector } from './config';
import MainRouter from '@router/main.router';
import useWebview from '@hooks/use-webview';
import useSessionManager from '@hooks/use-session-manager';
import UiDashSkeleton from '@ui/ui-dash-skeleton';
const UiGeneralError = lazy(() => import('@ui/ui-general-error'));

function App() {
  const isReadyToUse = useSelector(globalUiReadyPocketSelector);
  const isGlobalError = useSelector(globalUiErrorSelector);
  useWebview();

  if (isGlobalError) return <UiGeneralError />

  if (!isReadyToUse) return <SessionManager />

  return (
    <BrowserRouter basename="/">
      <MainRouter />
    </BrowserRouter>
  );
}

export default App;

const SessionManager = () => {
  useSessionManager();

  return (
    <UiDashSkeleton />
  );
};
