export const credits = {
  spanish: {
    "detail": {
      "resume_title": "Deudas",
      "history_title": "Movimientos",
      "button_text": "Abonar",
      "payment_transaction_name": "Abono",
      "credit_transaction_name": "Fiado",
      "notification_error": "Lo sentimos. No pudimos obtener el detalle de su crédito",
    },
    "payment": {
      "header_title": "Abonar",
      "title": "Monto",
      "notification_message": 'Abono por {{amount}} a {{provider}} registrado exitosamente',
      "notification_error_message": 'Lo sentimos. No pudimos registrar su abono',
    },
    "my_doubts": {
      "subtitle_provider_card_text": "Crédito",
      "header_title": "Mis deudas",
      "placeholder_text": "Buscar",
    },
    "next_step": 'Continuar',
    "transaction_name": 'gasto',
    "header_name": 'Abonar',
    "quantity": 'Monto'
  },
  portuguese: {
    "detail": {
      "resume_title": "Dívidas",
      "history_title": "Movimentos",
      "button_text": "Descontar",
      "payment_transaction_name": "Pago",
      "credit_transaction_name": "Fiado",
      "notification_error": "Desculpe. Não pudemos obter seus detalhes de crédito",
    },
    "payment": {
      "header_title": "Descontar",
      "title": "Valor",
      "notification_message": 'Pagamento de {{amount}} para {{provider}} registrada com sucesso',
      "notification_error_message": 'Desculpe. Não pudemos obter seu pagamento',
    },
    "my_doubts": {
      "subtitle_provider_card_text": "Crédito",
      "header_title": "Minhas dívidas",
      "placeholder_text": "Buscar",
    },
    "next_step": 'Proseguir',
    "transaction_name": 'gasto',
    "header_name": 'Descontar',
    "quantity": 'Valor'
  }
}
