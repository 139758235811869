import React, { FC } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { OTHERS_TABS, PERSONAL_TABS, PROVIDER_TABS, PUBLIC_SERVICE_TABS, RENT_TABS, TABS } from '../constants';
import ExpensesHome from '../pages/expenses-home';
import ProviderExpensesPaymentMethod from '../pages/provider-expenses-payment-method';
import ProviderExpensesAmount from '../pages/provider-expenses-amount';
import PublicServiceType from '../pages/public-service-type';
import PublicServicePaymentMethod from '../pages/public-service-payment-method';
import PublicServiceAmount from '../pages/public-service-amount';
import PersonalExpensesPaymentMethod from '../pages/personal-expenses-payment-method';
import PersonalExpensesAmount from '../pages/personal-expenses-amount';
import RentPaymentMethod from '../pages/rent-payment-method';
import RentAmount from '../pages/rent-amount';
import OthersExpensesAmount from '../pages/others-expenses-amount';
import OthersExpensesPaymentMethod from '../pages/others-expenses-payment-method';
import { useSelector } from 'react-redux';
import { currentExpenseSelector } from '../store';
import { ROUTES } from '@constants';
import ProviderExpensesSelectionName from '../pages/provider-expenses-selection-name';
import OthersExpensesType from '../pages/others-expenses-type';

interface ExpensesRouterProps { }

const RefreshOutlet = () => {
  const currentExpense = useSelector(currentExpenseSelector);

  return (currentExpense) ? <Outlet /> : <Navigate to={ROUTES.expenses} />
};

const ExpensesRouter: FC<ExpensesRouterProps> = () => {

  return (
    <Routes>
      <Route index element={<ExpensesHome />} />

      <Route path={TABS.provider} element={<RefreshOutlet />}>
        <Route index element={<Navigate to={PROVIDER_TABS.paymentMethod} />} />
        <Route path={PROVIDER_TABS.paymentMethod} element={<ProviderExpensesPaymentMethod />} />
        <Route path={PROVIDER_TABS.name} element={<ProviderExpensesSelectionName />} />
        <Route path={PROVIDER_TABS.amount} element={<ProviderExpensesAmount />} />
      </Route>

      <Route path={TABS.publicService} element={<RefreshOutlet />}>
        <Route index element={<Navigate to={PUBLIC_SERVICE_TABS.serviceType} />} />
        <Route path={PUBLIC_SERVICE_TABS.serviceType} element={<PublicServiceType />} />
        <Route path={PUBLIC_SERVICE_TABS.paymentMethod} element={<PublicServicePaymentMethod />} />
        <Route path={PUBLIC_SERVICE_TABS.amount} element={<PublicServiceAmount />} />
      </Route>

      <Route path={TABS.personal} element={<RefreshOutlet />} >
        <Route index element={<Navigate to={PERSONAL_TABS.paymentMethod} />} />
        <Route path={PERSONAL_TABS.paymentMethod} element={<PersonalExpensesPaymentMethod />} />
        <Route path={PERSONAL_TABS.amount} element={<PersonalExpensesAmount />} />
      </Route>

      <Route path={TABS.rent} element={<RefreshOutlet />} >
        <Route index element={<Navigate to={RENT_TABS.paymentMethod} />} />
        <Route path={RENT_TABS.paymentMethod} element={<RentPaymentMethod />} />
        <Route path={RENT_TABS.amount} element={<RentAmount />} />
      </Route>

      <Route path={TABS.others} element={<RefreshOutlet />} >
        <Route index element={<Navigate to={OTHERS_TABS.type} />} />
        <Route path={OTHERS_TABS.type} element={<OthersExpensesType />} />
        <Route path={OTHERS_TABS.paymentMethod} element={<OthersExpensesPaymentMethod />} />
        <Route path={OTHERS_TABS.amount} element={<OthersExpensesAmount />} />
      </Route>

      <Route path="*" element={<Navigate to={ROUTES.expenses} />} />
    </Routes>
  );
}

export default ExpensesRouter;
