import { Path, Route } from "@entities";

export const PATHS: Path = {
  home: 'home',
  income: 'income',
  expenses: 'expenses',
  loans: 'loans',
  customers: 'customers',
  boxClosure: 'box_closure',
  base: 'base',
  credits: 'credits',
  onboarding: 'onboarding',
};

export const ROUTES: Route = {
  empty: '',
  home: `/${PATHS.home}`,
  income: `/${PATHS.income}`,
  expenses: `/${PATHS.expenses}`,
  loans: `/${PATHS.loans}`,
  customers: `/${PATHS.customers}`,
  boxClosure: `/${PATHS.boxClosure}`,
  base: `/${PATHS.base}`,
  credits: `/${PATHS.credits}`,
  onboarding: `/${PATHS.onboarding}`,
};

export const CUSTOMERS_DETAIL_PATH = 'detail';
export const CUSTOMERS_DELETED_PATH = 'deleted';
