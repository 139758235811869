import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionType } from '@constants';
import { BoxClosureDetailResumenItem } from '@modules/box-closure/entities';
import styles from './box-closure-detail-resume-list.module.sass';
import UiMoneyValue from '@ui/ui-money-value';
import UiNoDataMessage from '@ui/ui-no-data-message';

interface BoxClosureDetailResumeListProps {
  resume: BoxClosureDetailResumenItem[],
  title: string;
}

const BoxClosureDetailResumeList: FC<BoxClosureDetailResumeListProps> = ({ resume, title }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>

      <p className={styles.title}>{t(`box_closure.title.${title}`)}</p>

      {!!resume ? resume.map((item, index) => (
        <div className={styles.item} key={`resume-${title}-${index}-item`}>
          {item.name}
          <UiMoneyValue value={item.total} type={TransactionType.income} />
        </div>
      ))
        : <UiNoDataMessage message={t(`box_closure.no_resume`)} />
      }

    </div>
  )
}

export default memo(BoxClosureDetailResumeList);
