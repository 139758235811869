import { CustomerStepMap } from "../entities";


export const STEPS_MAP_V2: CustomerStepMap = {
  1: {
    1: {
      tootltip: {
        title: 'Bolsillo.crearCliente.tooltip.texto1',
        x: 'center',
        y: 'top'
      },
      distance: 'top1'
    },
    2: {
      tootltip: {
        title: 'Bolsillo.crearCliente.tooltip.texto2',
        x: 'center',
        y: 'top'
      },
      distance: 'top2'
    },
    3: {
      tootltip: {
        title: 'Bolsillo.fiar.tooltip.texto2',
        x: 'center',
        y: 'bottom'
      },
      distance: 'bottom'
    },
  },
  2: {
    1: {
      tootltip: {
        title: 'Bolsillo.fiar.tooltip.texto1',
        x: 'center',
        y: 'top'
      },
      distance: 'top3'
    },
    2: {
      tootltip: {
        title: 'Bolsillo.fiar.tooltip.texto2',
        x: 'center',
        y: 'bottom'
      },
      distance: 'bottom',
    }
  },
  3: {
    1: {
      tootltip: {
        title: 'Bolsillo.pagar.tooltip.texto1',
        x: 'center',
        y: 'top'
      },
      distance: 'top3'
    },
    2: {
      tootltip: {
        title: 'Bolsillo.fiar.tooltip.texto2',
        x: 'center',
        y: 'bottom'
      },
      distance: 'bottom',
    }
  },
}