export const START_LEDGER = "StartLedgerPocket";

export const OB_START = "ObStartPocket";
export const OB_COMPLETE = "ObCompletePocket";
export const OB_SKIPPED = "ObSkippedPocket";

export const ON_DASH = "OnDashboardPocket";
export const OUT_DASH = "OutDashboardPocket";
export const SEARCH_MOVEMENT = "SearchMovementPocket";

export const MOVEMENT_START = "MovementPocket";
export const MOVEMENT_COMPLETE = "MovementCompletePocket";

export const CLOSE_CASH_START = "CloseCashPocket";
export const CLOSE_CASH_COMPLETE = "CloseCashCompletePocket";

export const CUSTOMERS_START = 'StartDebtPocket';
export const CUSTOMER_DETAIL_START = 'ClientResumePocket';
export const CUSTOMER_TRANSACTION = 'MovementClientPocket';
export const CUSTOMER_CREATION = 'CreateClientPocket';
export const CUSTOMER_EDITION = 'EditClientPocket';
export const CUSTOMER_DELETION = 'DeleteClientPocket';
export const CUSTOMER_REMINDER = 'WpClientPocket';
export const CUSTOMER_SEARCH_START = 'SearchDebtStartPocket'
export const CUSTOMER_SEARCH_COMPLETE = 'SearchDebtCompletePocket'
