import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { To } from 'react-router-dom';
import { customerDetailSelector } from '@modules/customers/store';
import { FIRST_PAYMENT_ONBOARDING_ID } from '@constants';
import useCustomerTransaction from '@modules/customers/hooks/use-customer-transaction';
import CustomersTransaction from '@modules/customers/components/customers-transaction';

interface CustomersPaymentTransactionProps { }

const CustomersPaymentTransaction: FC<CustomersPaymentTransactionProps> = () => {
  const { t } = useTranslation();
  const customer = useSelector(customerDetailSelector);
  const { createNewPayment } = useCustomerTransaction();
  
  return (
    <CustomersTransaction
      header={{
        title: t('Bolsillo.pagar.topBar.titulo3'),
        hasRightClick: true,
        hasBackClick: false,
        routeOnRightClick: -1 as To
      }}
      title={t('Bolsillo.pagar.title1')}
      onSubmit={createNewPayment}
      maxAmount={customer.debt}
      obId={FIRST_PAYMENT_ONBOARDING_ID}
    />
  );
}

export default CustomersPaymentTransaction;
