import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HOME_ONBOARDINGS } from '@constants';
import { SERVICES_MENU_ITEMS } from '@modules/home/constants';
import { actions as gloablActions, globalOnboardingSelector } from '@config/store';
import styles from './home-dashboard-ob-tooltip.module.sass';
import UiOnboardingTooltip from '@ui/ui-onboarding-tooltip';

interface HomeDashboardObTooltipProps { }

const HomeDashboardObTooltip: FC<HomeDashboardObTooltipProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onboarding = useSelector(globalOnboardingSelector);

  const completeStep = useCallback(() => {
    const newSteps = [...onboarding.homeStepsCompleted, onboarding.pushNotificationId]
    dispatch(gloablActions.setOnboarding({ homeStepsCompleted: newSteps }));
  }, [dispatch, onboarding]);

  const handleSubmitClick = useCallback(() => {
    completeStep();
    const item = SERVICES_MENU_ITEMS.find(e => e.onboardingId === onboarding.pushNotificationId)!
    navigate(item.routeTo);
  }, [completeStep, navigate, onboarding.pushNotificationId]);

  const handleCloseClick = useCallback(() => completeStep(), [completeStep]);

  const showOnboarding = () => (
    !!onboarding.pushNotificationId
    && !onboarding.done.includes(onboarding.pushNotificationId)
    && !onboarding.homeStepsCompleted.includes(onboarding.pushNotificationId)
    && HOME_ONBOARDINGS.includes(onboarding.pushNotificationId)
  );

  if (!showOnboarding()) return <></>

  return (
    <div className={styles.wrapper}>

      <div className={styles.blocker} />

      <div className={styles.trianguleWrapper}>
        {SERVICES_MENU_ITEMS.map((item) => (
          <div
            key={`item-${item.onboardingId}`}
            className={`${styles.item} ${item.onboardingId === onboarding.pushNotificationId && styles.current}`}
          />
        ))}
      </div>

      <UiOnboardingTooltip
        onBackClick={handleCloseClick}
        onCloseClick={handleCloseClick}
        onNextClick={handleSubmitClick}
        title={t(`home.onboarding.id-${onboarding.pushNotificationId}.title`)}
        text={t(`home.onboarding.id-${onboarding.pushNotificationId}.description`)}
        positionX='none'
        positionY='top'
        hasFirstStepValidation
      />

    </div>
  );
}

export default HomeDashboardObTooltip;
