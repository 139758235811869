import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { dashboard, servicesMenu, home, ob } from "@modules/home/translations";
import { expenses, provider, publicService, others } from "@modules/expenses/translations";
import { income } from "@modules/income/translations";
import { errors } from "./errors.translations";
import { currency } from "./currency.translations";
import { country } from "./country.translations";
import { customers, messages, modification } from "@modules/customers/translations";
import { base } from "@modules/base/translations"
import { box_closure } from "@modules/box-closure/translations";
import { credits } from "@modules/credits/translations";
import { general } from "./general.translations";
import { transactions } from "./transactions.translations";

export default i18n
  .use(initReactI18next)
  .init({
    resources: {
      co: {
        translation: {
          "country": { ...country.colombia },
          "currency": { ...currency.colombia },
          "general": { ...general.spanish },
          "errors": { ...errors.spanish },
          "dashboard": { ...dashboard.spanish },
          "home": {
            ...home.spanish,
            ...ob.spanish
          },
          "service": { ...servicesMenu.spanish },
          "expenses": {
            ...expenses.spanish,
            ...publicService.spanish,
            ...provider.spanish,
            ...others.spanish
          },
          "income": { ...income.spanish },
          "customers": {
            ...customers.spanish,
            ...messages.spanish,
            ...messages.colombia,
            ...modification.spanish,
          },
          "base_module": { ...base.spanish },
          "box_closure": { ...box_closure.spanish },
          "credits": { ...credits.spanish },
          "transactions": { ...transactions.spanish }
        }
      },
      mx: {
        translation: {
          "country": { ...country.mexico },
          "currency": { ...currency.mexico },
          "general": { ...general.spanish },
          "errors": { ...errors.spanish },
          "dashboard": { ...dashboard.spanish },
          "home": {
            ...home.spanish,
            ...ob.spanish
          },
          "service": { ...servicesMenu.spanish },
          "expenses": {
            ...expenses.spanish,
            ...publicService.spanish,
            ...provider.spanish,
            ...others.spanish
          },
          "income": { ...income.spanish },
          "customers": {
            ...customers.spanish,
            ...messages.spanish,
            ...messages.mexico,
            ...modification.spanish
          },
          "base": { ...base.spanish },
          "box_closure": { ...box_closure.spanish },
          "credits": { ...credits.spanish },
          "transactions": { ...transactions.spanish }
        }
      },
      br: {
        translation: {
          "country": { ...country.brazil },
          "currency": { ...currency.brazil },
          "general": { ...general.portuguese },
          "errors": { ...errors.portuguese },
          "dashboard": { ...dashboard.portuguese },
          "home": {
            ...home.portuguese,
            ...ob.portuguese
          },
          "service": { ...servicesMenu.portuguese },
          "expenses": {
            ...expenses.portuguese,
            ...publicService.portuguese,
            ...provider.portuguese,
            ...others.portuguese
          },
          "income": { ...income.portuguese },
          "customers": {
            ...customers.portuguese,
            ...messages.brazil,
            ...messages.portuguese,
            ...modification.portuguese
          },
          "base_module": { ...base.portuguese },
          "box_closure": { ...box_closure.portuguese },
          "credits": { ...credits.portuguese },
          "transactions": { ...transactions.portuguese }
        },
      }
    },
    lng: "co",
    fallbackLng: "co",
    debug: false,
    interpolation: {
      escapeValue: false
    }
  });
