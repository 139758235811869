import { FC, memo } from 'react';
import styles from "./ui-steps.module.sass";

interface UiStepsProps {
  step?: string,
  stepName?: string
  clarification?: string
}

const UiSteps: FC<UiStepsProps> = ({ step, stepName, clarification }) => {
  return (
    <div className={styles.steps}>
      <div className={styles.step}>
        {step}
      </div>
      {stepName}
      <span className={styles.clarification}>{clarification}</span>
    </div>
  )
};

export default memo(UiSteps);
