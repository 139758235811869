import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@modules/expenses/store';
import { Expenses, PERSONAL_AMOUNT_STEP, PERSONAL_TABS } from '@modules/expenses/constants';
import InputAmount from '@modules/expenses/components/input-amount';

interface PersonalExpensesAmountProps { }

const PersonalExpensesAmount: FC<PersonalExpensesAmountProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setStep(PERSONAL_AMOUNT_STEP));
    dispatch(actions.setFooter({
      routeToNext: '/',
      routeToBack: `${Expenses.personal}/${PERSONAL_TABS.paymentMethod}`,
      hasSubmitFunction: true,
    }));
    return () => { dispatch(actions.setFooter({ hasSubmitFunction: false })); }
  }, [dispatch]);

  return (
    <InputAmount />
  )
}

export default PersonalExpensesAmount;
