import * as yup from 'yup';
import { t } from 'i18next';
import { CUSTOMER_NAME_LENGTH, REG_EXP_ONLY_LETTERS, REG_EXP_ONLY_NUMBERS } from '@constants';

export const customersFormSchema = (maxPhone: number) => yup.object().shape({
  name: yup.string()
    .max(CUSTOMER_NAME_LENGTH, t('errors.max_characters', { max: CUSTOMER_NAME_LENGTH }))
    .matches(REG_EXP_ONLY_LETTERS, t('errors.only_letters')),
  phone: yup.string()
    .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
    .max(maxPhone, t('Bolsillo.crearCliente.textfieldError.texto1', { value: maxPhone }))
});
