import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { actions as globalActions } from '@config/store';
import { creditsDetailSelector, creditsUiSelector } from '@modules/credits/store';
import { TABS } from '@modules/credits/constants';
import styles from './credit-detail.module.sass';
import UiMoneyText from '@ui/ui-money-text';
import CreditDetailHistory from '@modules/credits/components/credit-detail-history';
import UiButton from '@ui/ui-button';
import useCreditsFetch from '@modules/credits/hooks';
import { PATHS } from '@constants';
import UiGlobalLoader from '@ui/ui-global-loader/ui-global-loader';

interface CreditDetailProps { }

const CreditDetail: FC<CreditDetailProps> = () => {
  const provider = useSelector(creditsDetailSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getProviderCreditDetail } = useCreditsFetch();
  const { isLoading } = useSelector(creditsUiSelector);

  useEffect(() => {
    if (!provider.id) getProviderCreditDetail();
  }, [getProviderCreditDetail, provider.id]);

  useEffect(() => {
    dispatch(globalActions.header({
      title: provider.name,
      hasBackClick: true,
      hasRightClick: false,
      routeOnBackClick: `${PATHS.credits}/${TABS.debts}`
    }));
  }, [dispatch, provider.name]);

  const handleButtonClick = useCallback(() => {
    navigate(TABS.pay)
  }, [navigate]);

  if (isLoading || !provider.id) return <UiGlobalLoader />

  return (
    <div className={styles.container}>
      <div className={styles.resume}>
        {t('credits.detail.resume_title')}
        <UiMoneyText value={provider.debt} isLarge negative={!!provider.debt} />
      </div>

      <CreditDetailHistory />

      <div className={styles.button}>
        <UiButton
          text={t('credits.detail.button_text')}
          isSecondary
          leftIcon='icon-plus'
          onClick={handleButtonClick}
          isDisabled={!provider.debt}
        />
      </div>
    </div>
  );
}

export default CreditDetail;
