import React, { FC } from 'react';
import styles from './customer-detail-skeleton.module.sass';
import UiDivider from '@ui/ui-divider';

interface CustomerDetailSkeletonProps { }

const CustomerDetailSkeleton: FC<CustomerDetailSkeletonProps> = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper} data-testid='customer-detail-skeleton'>
      
        <div className={styles.detail}>
          <div className={styles.detailTitle}/>
          <div className={styles.detailValue}/>
        </div>

        {Array(2).fill('').map((_, i) => (
          <div className={styles.customerInfo} key={`customer-info-${i}`}>
            <div className={styles.customerTitle}/>
            <div className={styles.customerText}/>
          </div>
        ))}

        <UiDivider />

        <div className={styles.debtTitle}/>

        <div className={styles.debtInfo}>
          <div className={styles.debtText}/>
          <div className={styles.debtValue}/>
        </div>

        <div className={styles.buttonsWrapper}>
          <div className={styles.button}/>
          <div className={styles.button}/>
        </div>

        <div className={styles.whatsapp}/>

        <div className={styles.transactionsTitle}/>

        <div className={styles.date}/>

        <div className={styles.transactionWrapper}>
          <div className={styles.transactionInfo}>
            <div className={styles.type}/>
            <div className={styles.time}/>
          </div>
          <div className={styles.value}/>
        </div>

      </div>
      
    </div>
  )
}

export default CustomerDetailSkeleton;