import { Transaction } from "@entities";
import {
  BASE_TRANSACTION_TYPE_ID, BOX_CLOSURE_TRANSACTION_TYPE_ID, CREDIT_TRANSACTION_TYPE_ID, CUSTOMERS_DELETED_PATH,
  CUSTOMERS_DETAIL_PATH, DELETION_TRANSACTION_TYPE_ID, EXPENSE_TRANSACTION_TYPE_ID, INCOME_TRANSACTION_TYPE_ID,
  LOAN_TRANSACTION_TYPE_ID, PAYMENT_TRANSACTION_TYPE_ID, ROUTES, TransactionType
} from "@constants";
import { TABS } from "@modules/box-closure/constants";

const CUSTOMER_ROUTE = `${ROUTES.customers}/${CUSTOMERS_DETAIL_PATH}`;

const getTransactionProperties = (transaction: Transaction) => {
  if (transaction.wasDeleted) return {
    icon: `user`,
    type: TransactionType.deletion,
    routeTo: `${CUSTOMER_ROUTE}/${transaction.customerId}/${CUSTOMERS_DELETED_PATH}`,
  };

  switch (transaction.transactionTypeId) {
    case INCOME_TRANSACTION_TYPE_ID: return { icon: 'income', type: TransactionType.income };

    case EXPENSE_TRANSACTION_TYPE_ID: return { icon: 'egress', type: TransactionType.expense };

    case BOX_CLOSURE_TRANSACTION_TYPE_ID: return { 
      icon: 'calculator', 
      type: TransactionType.closure,
      routeTo: `${ROUTES.boxClosure}/${TABS.detail}/${transaction.cashDeckControlId}`
    };

    case BASE_TRANSACTION_TYPE_ID: return {
      icon: 'coins',
      type: TransactionType.base,
      routeTo: ROUTES.base,
    };

    case LOAN_TRANSACTION_TYPE_ID: return {
      icon: `user`,
      type: TransactionType.loan,
      routeTo: `${CUSTOMER_ROUTE}/${transaction.customerId}`,
    };

    case PAYMENT_TRANSACTION_TYPE_ID: return {
      icon: `user`,
      type: TransactionType.paymentLoan,
      routeTo: `${CUSTOMER_ROUTE}/${transaction.customerId}`,
    };

    case DELETION_TRANSACTION_TYPE_ID: return {
      icon: `user`,
      type: TransactionType.deletion,
    };

    case CREDIT_TRANSACTION_TYPE_ID: return {
      icon: `egress`,
      type: TransactionType.credit,
      routeTo: `${ROUTES.credits}/${transaction.providerId}`,
    };

    default: return {
      icon: `egress`,
      type: TransactionType.paymentCredit,
      routeTo: `${ROUTES.credits}/${transaction.providerId}`,
    };
  }
};

export default getTransactionProperties;
