import React, { FC, memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, currentPaymentMethodSelector } from "@modules/income/store";
import { PaymentMethod } from "@entities";
import UiSelectionList from '@ui/ui-selection-list';
import { CREDIT_PAYMENT_METHOD_ID } from "@constants";
import { globalPaymentMethodsSelector } from "@config/store";
import styles from './payment-method.module.sass';

interface PaymentMethodsProps { }

const PaymentMethods: FC<PaymentMethodsProps> = () => {
  const dispatch = useDispatch();
  const currentPaymentMethodId = useSelector(currentPaymentMethodSelector);
  const paymentMethods = useSelector(globalPaymentMethodsSelector);

  useEffect(() => {
    dispatch(actions.setFooter({ isDisabled: !currentPaymentMethodId }));
  }, [currentPaymentMethodId, dispatch]);

  const handleCurrentPayment = useCallback((item): void => {
    dispatch(actions.setCurrentPaymentMethod(item.id))
  }, [dispatch]);

  const getPaymentMethods = useMemo((): PaymentMethod[] => {
    return paymentMethods.filter(e => e.id !== CREDIT_PAYMENT_METHOD_ID);
  }, [paymentMethods]);

  return (
    <div className={styles.wrapper}>
      <UiSelectionList
        list={getPaymentMethods}
        selected={currentPaymentMethodId}
        onItemClick={handleCurrentPayment}
      />
    </div>
  );
};

export default memo(PaymentMethods);
