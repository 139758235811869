import { useDispatch, useSelector } from 'react-redux';
import {
  actions as globalActions, globalBalancesSelector,
  globalPaymentMethodsSelector, globalTransactionsSelector
} from '@config/store';
import { ExpensesDataState } from '@modules/expenses/store';
import { useCallback } from 'react';
import { format } from 'date-fns';
import { Transaction, TransactionsByDate } from '@entities';
import {
  BASE_TRANSACTION_TYPE_ID, BOX_CLOSURE_TRANSACTION_TYPE_ID, CREDIT_PAYMENT_METHOD_ID, CREDIT_TRANSACTION_TYPE_ID, DELETION_TRANSACTION_TYPE_ID,
  EXPENSE_TRANSACTION_TYPE_ID, INCOME_TRANSACTION_TYPE_ID,
  LOAN_TRANSACTION_TYPE_ID, PAYMENT_TRANSACTION_TYPE_ID
} from '@constants';
import { Base } from '@modules/base/entities';
import { BASE_PAYMENT_METHOD } from '@modules/base/constants';
import { CUSTOMERS_DEFAULT_IMAGE, CUSTOMERS_PAYMENT_METHOD_ID } from '@modules/customers/constants';
import { CreditPaymentBody } from '@modules/credits/entities';
import { useTranslation } from 'react-i18next';

const useUpdateWallet = () => {
  const dispatch = useDispatch();
  const balances = useSelector(globalBalancesSelector);
  const transactions = useSelector(globalTransactionsSelector);
  const paymentMethods = useSelector(globalPaymentMethodsSelector);
  const { t } = useTranslation();

  const getTransactionsWithToday = useCallback((): TransactionsByDate[] => {
    const date = format(new Date(), 'dd MMM yyyy');
    const transactionsWithoutToday = transactions.filter(e => format(new Date(e.date), 'dd MMM yyyy') !== date);
    if (transactionsWithoutToday.length === transactions.length) {
      return [{ date, transactionsByDate: [] }, ...transactionsWithoutToday]
    }
    return [...transactions];
  }, [transactions]);

  const setNewIncome = useCallback((id: string, total: number, paymentMethodId: number): void => {
    const currentTransactions = getTransactionsWithToday();
    const todayTransactions = currentTransactions.splice(-currentTransactions.length, 1)[0];

    dispatch(globalActions.setTransactions([{
      date: todayTransactions.date,
      transactionsByDate: [{
        id,
        total,
        paymentMethodId,
        transactionTypeId: INCOME_TRANSACTION_TYPE_ID,
        description: 'transactions.income',
        subTitle: paymentMethods.find(e => +e.id === paymentMethodId)?.name || '',
        imageUrl: '',
      }, ...todayTransactions.transactionsByDate]
    }, ...currentTransactions]))

    dispatch(globalActions.setBalance({
      ...balances,
      balance: balances.balance + total,
      income: balances.income + total
    }));
  }, [balances, dispatch, getTransactionsWithToday, paymentMethods]);

  const setNewExpense = useCallback((id: string, expense: ExpensesDataState, providerId?: number): void => {
    const isCredit = expense.currentPaymentMethodId === CREDIT_PAYMENT_METHOD_ID;
    const currentTransactions = getTransactionsWithToday();
    const todayTransactions = currentTransactions.splice(-currentTransactions.length, 1)[0];

    dispatch(globalActions.setTransactions([{
      date: todayTransactions.date,
      transactionsByDate: [{
        id,
        total: +expense.currentAmount,
        paymentMethodId: +expense.currentPaymentMethodId,
        transactionTypeId: isCredit ? CREDIT_TRANSACTION_TYPE_ID : EXPENSE_TRANSACTION_TYPE_ID,
        description: expense.currentMicroEgressName,
        subTitle: paymentMethods.find(e => +e.id === +expense.currentPaymentMethodId)?.name || '',
        imageUrl: '',
        providerId,
      }, ...todayTransactions.transactionsByDate]
    }, ...currentTransactions]));

    dispatch(globalActions.setBalance({
      ...balances,
      balance: isCredit ? balances.balance : balances.balance - +expense.currentAmount,
      egress: isCredit ? balances.egress : balances.egress + +expense.currentAmount,
      debt: isCredit ? balances.debt + +expense.currentAmount : balances.debt
    }));

  }, [balances, dispatch, getTransactionsWithToday, paymentMethods]);

  const generateBaseTransaction = useCallback((id: string, total: number, description: string) => {
    const currentTransactions = getTransactionsWithToday();
    const todayTransactions = currentTransactions.splice(-currentTransactions.length, 1)[0];

    dispatch(globalActions.setTransactions([{
      date: todayTransactions.date,
      transactionsByDate: [{
        id,
        total,
        description: description,
        subTitle: t('transactions.money'),
        paymentMethodId: BASE_PAYMENT_METHOD,
        transactionTypeId: BASE_TRANSACTION_TYPE_ID,
        imageUrl: '',
      }, ...todayTransactions.transactionsByDate]
    }, ...currentTransactions]));
  }, [dispatch, getTransactionsWithToday, t]);

  const setNewBase = useCallback((id: string, base: Base): void => {
    generateBaseTransaction(id, +base.total, base.description);
    dispatch(globalActions.setBalance({ ...balances, base: base.total, hasBase: true }));
  }, [balances, dispatch, generateBaseTransaction]);

  const setNewClosure = useCallback((realBalance: number, realBase: number, newCashId: number, lastCashId: number): void => {
    let newBase: Transaction = {} as Transaction;
    if (realBase !== balances.base) {
      newBase = {
        id: String(newCashId),
        total: realBase,
        description: 'transactions.box_closure',
        subTitle: t('transactions.money'),
        paymentMethodId: BASE_PAYMENT_METHOD,
        transactionTypeId: BASE_TRANSACTION_TYPE_ID,
        imageUrl: '',
      }
    }

    const newClosure = {
      id: String(newCashId + 1),
      total: realBalance,
      description: 'transactions.box_closure',
      subTitle: format(new Date(), "hh:mm aaaaa'm'").toUpperCase(),
      paymentMethodId: 1,
      cashDeckControlId: lastCashId,
      transactionTypeId: BOX_CLOSURE_TRANSACTION_TYPE_ID,
      imageUrl: '',
    }
    const currentTransactions = getTransactionsWithToday();
    const todayTransactions = currentTransactions.splice(-currentTransactions.length, 1)[0];
    const elements = realBase !== balances.base ? [newClosure, newBase] : [newClosure]
    dispatch(globalActions.setTransactions([{
      date: todayTransactions.date,
      transactionsByDate: [...elements, ...todayTransactions.transactionsByDate]
    }, ...currentTransactions]));

    dispatch(globalActions.setBalance({
      ...balances,
      balance: 0,
      egress: 0,
      income: 0,
      base: realBase,
      cashId: newCashId
    }));
  }, [balances, dispatch, getTransactionsWithToday, t]);

  const setNewCustomerTransaction = useCallback((id: string, description: string, total: number, transactionTypeId: number, customerId: number): void => {
    const currentTransactions = getTransactionsWithToday();
    const todayTransactions = currentTransactions.splice(-currentTransactions.length, 1)[0];

    dispatch(globalActions.setTransactions([{
      date: todayTransactions.date,
      transactionsByDate: [{
        id,
        total,
        transactionTypeId,
        customerId,
        description,
        subTitle: transactionTypeId === PAYMENT_TRANSACTION_TYPE_ID ? t('transactions.debt_payment') : 'Fiado',
        paymentMethodId: CUSTOMERS_PAYMENT_METHOD_ID,
        imageUrl: CUSTOMERS_DEFAULT_IMAGE,
      }, ...todayTransactions.transactionsByDate]
    }, ...currentTransactions]))

    dispatch(globalActions.setBalance(transactionTypeId === DELETION_TRANSACTION_TYPE_ID
      ? { ...balances, loan: balances.loan - total }
      : {
        ...balances,
        balance: transactionTypeId === PAYMENT_TRANSACTION_TYPE_ID ? balances.balance + total : balances.balance,
        income: transactionTypeId === PAYMENT_TRANSACTION_TYPE_ID ? balances.income + total : balances.income,
        loan: transactionTypeId === LOAN_TRANSACTION_TYPE_ID ? balances.loan + total : balances.loan - total,
      }));
  }, [balances, dispatch, getTransactionsWithToday, t]);

  const setNewCreditPayment = useCallback((id: string, payment: CreditPaymentBody, providerId?: number): void => {
    const currentTransactions = getTransactionsWithToday();
    const todayTransactions = currentTransactions.splice(-currentTransactions.length, 1)[0];

    dispatch(globalActions.setTransactions([{
      date: todayTransactions.date,
      transactionsByDate: [{
        id,
        total: payment.total,
        paymentMethodId: payment.paymentMethodId,
        transactionTypeId: payment.transactionTypeId,
        description: payment.microType.name,
        subTitle: t('transactions.debt_payment'),
        imageUrl: payment.microType.image,
        providerId,
      }, ...todayTransactions.transactionsByDate]
    }, ...currentTransactions]));

    dispatch(globalActions.setBalance({
      ...balances,
      balance: balances.balance - payment.total,
      egress: balances.egress + payment.total,
      debt: balances.debt - payment.total
    }));
  }, [balances, dispatch, getTransactionsWithToday, t]);

  return {
    setNewExpense,
    setNewIncome,
    setNewBase,
    setNewClosure,
    setNewCustomerTransaction,
    setNewCreditPayment,
  };
}

export default useUpdateWallet;
