import React, { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions, currentAmountSelector } from '@modules/expenses/store';
import styles from './input-amount.module.sass';
import UiInputMoney from '@ui/ui-input-money';
import useInputFieldValidation from '@hooks/use-input-field-validation';
import { AMOUNT_TOUR_STEP } from '@modules/expenses/constants';

interface InputAmountProps { }

const InputAmount: FC<InputAmountProps> = () => {
  const dispatch = useDispatch();
  const currentAmount = useSelector(currentAmountSelector);
  const { t } = useTranslation();
  const { hasError, validateInputMoney } = useInputFieldValidation();

  useEffect(() => {
    dispatch(actions.setFooter({ isDisabled: !currentAmount || hasError }));
  }, [currentAmount, dispatch, hasError]);

  useEffect(() => {
    dispatch(actions.setTourStep(AMOUNT_TOUR_STEP));
    return () => {
      dispatch(actions.setTourStep(0))
    }
  }, [dispatch]);

  const handleInputChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>): void => {
    validateInputMoney(+target.value, t('expenses.transaction_name'));
    dispatch(actions.setCurrentAmount(target.value));
  }, [dispatch, t, validateInputMoney]);

  return (
    <div className={styles.wrapper}>
      <UiInputMoney
        name='amount'
        hasAutoFocus
        value={currentAmount}
        onChange={handleInputChange}
        hasError={hasError}
      />
    </div>
  )
}

export default InputAmount;
