import React, { FC } from 'react';
import styles from './ui-loader-spinner.module.sass';

interface UiLoaderSpinnerProps { 
  isSecondary?: boolean;  
}

const UiLoaderSpinner: FC<UiLoaderSpinnerProps> = ({ isSecondary = false }) => {
  return <div className={`${styles.spinner} ${isSecondary ? styles.secondType : styles.normal}`} />
}

export default UiLoaderSpinner;