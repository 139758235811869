import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState, OnboardingDataState, UserState } from './global.state';
import {
  Balances, ContactPhone, GetTransactionsResponse, Header, Notification,
  PaymentMethod, TransactionsByDate, TransactionsPagination
} from "@entities";

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsReadyToUse: (state, action: PayloadAction<boolean>) => {
      state.ui.isReadyToUse = action.payload;
    },
    notification: (state, action: PayloadAction<Partial<Notification>>) => {
      state.ui.notification = { ...state.ui.notification, ...action.payload };
    },
    header: (state, action: PayloadAction<Partial<Header>>) => {
      state.ui.header = { ...state.ui.header, ...action.payload };
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.ui.isLoading = action.payload;
    },
    setHasGlobalError: (state, action: PayloadAction<boolean>) => {
      state.ui.hasGlobalError = action.payload;
    },
    setUser: (state, action: PayloadAction<UserState>) => {
      state.data.user = action.payload;
    },
    setPaymentMethods: (state, action: PayloadAction<PaymentMethod[]>) => {
      state.data.paymentMethods = action.payload;
    },
    setBalance: (state, action: PayloadAction<Balances>) => {
      state.data.balances = action.payload;
    },
    setTransactionsResponse: (state, action: PayloadAction<GetTransactionsResponse>) => {
      state.data.transactions = action.payload;
    },
    setTransactions: (state, action: PayloadAction<TransactionsByDate[]>) => {
      state.data.transactions.data = action.payload;
    },
    setTransactionsPagination: (state, action: PayloadAction<TransactionsPagination>) => {
      state.data.transactions = { ...state.data.transactions, ...action.payload }
    },
    setContacts: (state, action: PayloadAction<ContactPhone[]>) => {
      state.data.contacts = action.payload;
    },
    setOnboarding: (state, action: PayloadAction<Partial<OnboardingDataState>>) => {
      state.data.onboarding = { ...state.data.onboarding, ...action.payload };
    },
    setContactsPermissionGranted: (state, action: PayloadAction<boolean>) => {
      state.ui.contactsPermissionGranted = action.payload;
    },
  },
});

export const actions = globalSlice.actions;
export default globalSlice.reducer;
