import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useFormatCurrency = () => {

  const { t } = useTranslation();
  const formatMoney = useCallback((value: number = 0, withSign?: boolean, decimals?: number) => {
    const decimalsToUse = (decimals !== undefined && decimals !== null) ? decimals : +t('currency.decimals');
    let [integer, decimal] = value.toFixed(decimalsToUse).split('.');
    const decimalSeparator = `${t('currency.decimal_separator')}${decimal || '00'}`;
    integer = integer.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), t('currency.thousand_separator'));
    return `${withSign ? t('currency.sign') : ''}${integer + (decimalsToUse > 0 ? decimalSeparator : '')}`;
  }, [t]);

  return {
    format: formatMoney,
    sign: t('currency.sign'),
    decimals: +t('currency.decimals')
  };
}

export default useFormatCurrency;
