import { customersUiSelector } from "@modules/customers/store";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useCustomersFetch from "../use-customers-fetch";

const useGetCustomers = () => {
  const { getCustomers } = useCustomersFetch();
  const { hasRequestedCustomers } = useSelector(customersUiSelector);
  const isRequested = useRef(false);

  useEffect(() => {
    if (!hasRequestedCustomers && !isRequested.current) {
      getCustomers();
      isRequested.current = true
    }
  }, [getCustomers, hasRequestedCustomers])

}

export default useGetCustomers;
