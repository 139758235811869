import React, { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Clevertap, { OB_START } from '@config/clevertap';
import { EXPENSES_ONBOARDING_ID } from '@constants';
import { expensesDataSelector, expensesUiSelector } from '@modules/expenses/store';
import { EGRESS_TYPE_TOUR_STEP, PAYMENT_METHOD_TOUR_STEP, STEPS_MAP } from '@modules/expenses/constants';
import useOnboarding from '@hooks/use-onboarding';
import UiTourTooltip from '@ui/ui-tour-tooltip';

interface ExpensesTourTooltipProps { }

const ExpensesTourTooltip: FC<ExpensesTourTooltipProps> = () => {
  const { tourStep } = useSelector(expensesUiSelector);
  const data = useSelector(expensesDataSelector);
  const { isOnboardingDone } = useOnboarding();

  useEffect(() => {
    if (!isOnboardingDone(EXPENSES_ONBOARDING_ID)) {
      Clevertap.pushEvent(OB_START, { obType: EXPENSES_ONBOARDING_ID });
    }
  }, [isOnboardingDone]);

  const currentStep = useMemo(() => {
    switch (tourStep) {
      case (EGRESS_TYPE_TOUR_STEP): return !data.currentMacroEgressTypeId ? STEPS_MAP[1][1] : STEPS_MAP[1][2];
      case (PAYMENT_METHOD_TOUR_STEP): return !data.currentPaymentMethodId ? STEPS_MAP[2][1] : STEPS_MAP[2][2];
      default: return !data.currentAmount ? STEPS_MAP[3][1] : STEPS_MAP[3][2];
    }
  }, [tourStep, data]);

  if (tourStep === 0) return <></>

  return (
    <UiTourTooltip 
      currentStep={currentStep}
      onboardingId={EXPENSES_ONBOARDING_ID}
    />
  )
}

export default ExpensesTourTooltip; 
