import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TransactionType } from '@constants';
import Clevertap, { CUSTOMER_SEARCH_START } from '@config/clevertap';
import { globalBalancesSelector, globalContactsSelector } from "@config/store";
import { CUSTOMERS_PATH } from '@modules/customers/constants';
import { actions } from "@modules/customers/store";
import styles from "./customers-home-info-search-bar.module.sass";
import UiMoneyValue from '@ui/ui-money-value';

interface CustomersHomeInfoSearchBarProps { }

const CustomersHomeInfoSearchBar: FC<CustomersHomeInfoSearchBarProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contacts = useSelector(globalContactsSelector);
  const totalDebt = useSelector(globalBalancesSelector).loan;

  useEffect(() => {
    dispatch(actions.setSearchValue(''));
    dispatch(actions.setBackDetailRoute(CUSTOMERS_PATH));
  }, [dispatch]);

  const handleSearchBarClick = () => {
    dispatch(actions.setisSearchBarActive(true));
    Clevertap.pushEvent(CUSTOMER_SEARCH_START, { hadContactsAccess: !!contacts.length });
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchBar} onClick={handleSearchBarClick}>
        <span className={`icon-search ${styles.searchIcon}`} />
        {t("Bolsillo.misClientes.searchBar.buscarCliente")}
      </div>

      <div className={styles.info}>
        {t("Bolsillo.misClientes.deudaTotalComponente.total")}
        <UiMoneyValue value={totalDebt} size="large" type={TransactionType.loan} />
      </div>

    </div>
  );
}

export default CustomersHomeInfoSearchBar;
