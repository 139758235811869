import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@config/store";
import { CustomersDataState, CustomersUiState } from "./customers.state";

export const customersUiSelector = createSelector(
  (state: RootState) => state.customers.ui,
  (state: CustomersUiState) => state
);

export const customersUiSearchBarSelector = createSelector(
  (state: RootState) => state.customers.ui.isSearchBarActive,
  (state: boolean) => state
);

export const customersUiBackDetailSelector = createSelector(
  (state: RootState) => state.customers.ui.backDetailRoute,
  (state: string) => state
);

export const customersDataSelector = createSelector(
  (state: RootState) => state.customers.data,
  (state: CustomersDataState) => state.customers
);

export const customerDetailSelector = createSelector(
  (state: RootState) => state.customers.data,
  (state: CustomersDataState) => state.detail
);

export const customersSearchSelector = createSelector(
  (state: RootState) => state.customers.data.search.value,
  (state: string) => state
);
