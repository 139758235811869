import { ExpenseType } from '../entities';
import { ExpensesDataState } from '../store';
import { StepSection } from '@entities';

export const EXPENSES_HEADER_NAME = 'Bolsillo.gasto.topbar.title';

export enum Expenses {
  empty = '',
  provider = 'provider',
  public_service = 'public_service',
  personal = 'personal',
  rent = 'rent',
  others = 'others',
}

export const TABS = {
  home: Expenses.empty,
  provider: Expenses.provider,
  publicService: Expenses.public_service,
  personal: Expenses.personal,
  rent: Expenses.rent,
  others: Expenses.others,
};

export const DEFAULT_TOTAL_STEPS = 4;
export const PROVIDER_EXPENSE_ID = 1;
export const CUSTOM_PROVIDER_EXPENSE_ID = 2;
export const PERSONAL_EXPENSE_ID = 3;
export const OTHERS_EXPENSE_ID = 4;
export const CUSTOM_OTHERS_EXPENSE_ID = 11;
export const RENT_EXPENSE_ID = 5;
export const PUBLIC_SERVICE_EXPENSE_ID = 6;

export const EXPENSES_TYPES: ExpenseType[] = [
  {
    icon: 'icon-truck',
    name: 'Bolsillo.gasto.list1.subtitle2',
    path: Expenses.provider,
    id: PROVIDER_EXPENSE_ID,
  },
  {
    icon: 'icon-light',
    name: 'expenses.type.public_service',
    path: Expenses.public_service,
    id: PUBLIC_SERVICE_EXPENSE_ID,
  },
  {
    icon: 'icon-user',
    name: 'Bolsillo.gasto.list1.subtitle3',
    path: Expenses.personal,
    id: PERSONAL_EXPENSE_ID,
  },
  {
    icon: 'icon-store',
    name: 'Bolsillo.gasto.list1.subtitle4',
    path: Expenses.rent,
    id: RENT_EXPENSE_ID,
  },
  {
    icon: 'icon-strategy',
    name: 'Bolsillo.gasto.list1.subtitle5',
    path: Expenses.others,
    id: OTHERS_EXPENSE_ID,
  },
];

export enum TITLES {
  expenseType = 'Bolsillo.gasto.subtitle1',
  paymenentMethod = 'Bolsillo.gasto.subtitle2',
  providerName = 'Bolsillo.gasto.subtitle3',
  amount = 'Bolsillo.gasto.subtitle4',
  serviceType = 'Bolsillo.gasto.list1.subtitle3',
  reason = 'expenses.reason_title',
  others = 'Bolsillo.gasto.list1.subtitle5'
}

export const HOME_STEP: StepSection = {
  totalSteps: DEFAULT_TOTAL_STEPS,
  currentStep: 1,
  stepName: TITLES.expenseType
};

export const EMPTY_STATE: ExpensesDataState = {
  currentExpense: Expenses.empty,
  currentMacroEgressTypeId: 0,
  currentMicroEgressTypeId: 0,
  currentMicroEgressName: '',
  currentPaymentMethodId: '',
  isCustomProviderExpense: false,
  searchProvidersResults: [],
  mostUsedProviders: [],
  mostRecentProviders: [],
  currentAmount: '',
  searchOthersTypeSuggestionsResults: [],
  othersTypeLastCreated: { id: 0, name: '', macroEgressTypeId: 0 },
  othersTypeSuggestionsList: [],
};

export const PROVIDER_IMAGE = "https://i.ibb.co/VJ8mgZt/proveedor.png";
