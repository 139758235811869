import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions } from '@modules/expenses/store';
import { actions as globalActions } from '@config/store';
import { Expenses, PERSONAL_MICRO_EGRESS, PERSONAL_PAYMENT_METHOD_STEP, PERSONAL_TABS } from '@modules/expenses/constants';
import PaymentMethods from '../../components/payment-methods';
import { ROUTES } from '@constants';

interface PersonalExpensesPaymentMethodProps { }

const PersonalExpensesPaymentMethod: FC<PersonalExpensesPaymentMethodProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.setCurrentMicroEgressTypeId(PERSONAL_MICRO_EGRESS.id));
    dispatch(actions.setCurrentMicroEgressName(t(PERSONAL_MICRO_EGRESS.name)));
    dispatch(actions.setStep(PERSONAL_PAYMENT_METHOD_STEP));
    dispatch(actions.setFooter({
      routeToNext: `${Expenses.personal}/${PERSONAL_TABS.amount}`,
      routeToBack: ROUTES.expenses,
    }));
    dispatch(globalActions.header({ hasRightClick: true }));
  }, [dispatch, t]);

  return (
    <PaymentMethods />
  )
}

export default PersonalExpensesPaymentMethod;
