import React, { FC, useEffect, useState } from 'react';

import styles from './ui-radio.module.sass';

interface UiRadioProps {
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: (value: boolean) => void;
  isSquare?: boolean;
}
 
const UiRadio: FC<UiRadioProps> = ({ isChecked, isDisabled, onChange, isSquare }) => {
  const [active, setActive] = useState(false);

  const handleChange = (): void => {
    if (!isDisabled) {
      setActive(a => !a);
      onChange(!active);
    }
  }

  useEffect(() => {
    setActive(isChecked);
  }, [isChecked]);

  return (
    <div 
      className={`
        ${styles.wrapper} 
        ${active || isChecked ? styles.active : ''} 
        ${isDisabled ? styles.disabled : ''}
        ${isSquare && styles.square}
      `}
      onClick={handleChange}
      data-testid='ui-radio'
    >
      <span className={`icon-check ${styles.icon}`} />
    </div>
  );
}
 
export default UiRadio;