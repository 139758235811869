import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TransactionType } from '@constants';
import { boxClosureFormSelector } from '@modules/box-closure/store';
import styles from './box-closure-home-resumen-wrapper.module.sass';
import BoxClosureHomeResume from '../box-closure-home-resume/box-closure-home-resume';
import UiDivider from '@ui/ui-divider';
import UiMoneyValue from '@ui/ui-money-value';

interface BoxClosureHomeResumenWrapperProps { }

const BoxClosureHomeResumenWrapper: FC<BoxClosureHomeResumenWrapperProps> = () => {
  const { t } = useTranslation();
  const form = useSelector(boxClosureFormSelector);

  return (
    <div className={styles.wrapper}>

      <p className={styles.title}>
        {t('Bolsillo.caja.subtitle1')}
      </p>

      <BoxClosureHomeResume />

      <UiDivider isSmall isGray25 />

      <div className={styles.total}>
        {t('box_closure.title.total')}
        <UiMoneyValue value={form.incomes - form.expenses}
          size='large'
          type={TransactionType.balance}
        />
      </div>
    </div>
  );
}

export default BoxClosureHomeResumenWrapper;
