import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { showEcommerceTabs } from "@utils/webview";
import { incomeDataSelector } from "@modules/income/store";
import { actions as globalActions, globalBalancesSelector, globalPaymentMethodsSelector, globalUserSelector } from "@config/store";
import { fetchCreateNewIncome } from "@modules/income/services";
import useFormatCurrency from "@hooks/use-format-currency";
import { INCOME_ONBOARDING_ID, INCOME_TRANSACTION_TYPE_ID, ROUTES } from "@constants";
import { Income } from "@modules/income/entities";
import useUpdateWallet from "@hooks/use-update-wallet";
import useNotification from "@hooks/use-notification";
import useOnboarding from "@hooks/use-onboarding";
import Clevertap, { MOVEMENT_COMPLETE } from "@config/clevertap";

const useIncomeFetch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(incomeDataSelector);
  const { format } = useFormatCurrency();
  const paymentMethods = useSelector(globalPaymentMethodsSelector);
  const { t } = useTranslation();
  const { setNewIncome } = useUpdateWallet();
  const user = useSelector(globalUserSelector);
  const balances = useSelector(globalBalancesSelector);
  const showNotification = useNotification();
  const { setIncomeOnboardingDone, isOnboardingDone } = useOnboarding();

  const createNewIncome = useCallback((): void => {
    const body: Income = {
      transactionTypeId: INCOME_TRANSACTION_TYPE_ID,
      paymentMethodId: +data.currentPaymentMethodId,
      total: +data.currentAmount,
      userId: user.storeId,
      storeId: user.storeId,
      cashDeckControlId: balances.cashId
    };

    dispatch(globalActions.setIsLoading(true));
    fetchCreateNewIncome(body)
      .then((id) => {
        showEcommerceTabs();
        setNewIncome(id, body.total, body.paymentMethodId);
        const message = t("income.notification_message", {
          amount: format(+data.currentAmount, true),
          method: t(`${paymentMethods.find(e => e.id === data.currentPaymentMethodId)?.name}`)
        });
        showNotification(message, true);

        if (!isOnboardingDone(INCOME_ONBOARDING_ID)) setIncomeOnboardingDone(body.total);

        Clevertap.pushEvent(MOVEMENT_COMPLETE, {
          type: INCOME_TRANSACTION_TYPE_ID,
          value: body.total,
          paymentMethod: body.paymentMethodId,
        });
        navigate(ROUTES.home)
      })
      .catch(() => showNotification(t('income.notification_error_message'), false))
      .finally(() => dispatch(globalActions.setIsLoading(false)));
  }, [data, user, balances, dispatch, setNewIncome, t, format, paymentMethods, showNotification, isOnboardingDone, setIncomeOnboardingDone, navigate]);

  return { createNewIncome };
};

export default useIncomeFetch;
