import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from './others-type-suggestions-list.module.sass';
import useProviderExpense from '@modules/expenses/hooks/use-provider-expense';
import {
  expensesUiSelector,
  otherTypeLastSuggestionCreatedSelector,
  expensesMicroEgressNameSelector,
  othersTypeSuggestionsListSelector,
  searchOthersTypeSuggestionsResultsSelector,
} from '@modules/expenses/store';
import { useTranslation } from 'react-i18next';
import { TypeSuggestion } from '@modules/expenses/entities';
import { CUSTOM_OTHERS_EXPENSE_ID } from '@modules/expenses/constants';

interface OthersTypeSuggestionsListProps { }

const OthersTypeSuggestionsList: FC<OthersTypeSuggestionsListProps> = () => {
  const lastCreated = useSelector(otherTypeLastSuggestionCreatedSelector);
  const suggestionsList = useSelector(othersTypeSuggestionsListSelector);
  const currentMicroEgressName = useSelector(expensesMicroEgressNameSelector);
  const suggestionsResults = useSelector(searchOthersTypeSuggestionsResultsSelector);
  const { t } = useTranslation();
  const { selectionSuggestion } = useSelector(expensesUiSelector);
  const { setOthersTypeSelection, deleteLastOthersTypeSuggestion } = useProviderExpense();

  const showCreateButton = useMemo(() => (
    !suggestionsResults.find(item => item.name.toLowerCase() === currentMicroEgressName.toLowerCase())
  ), [suggestionsResults, currentMicroEgressName]);

  const getSuggestionsList = (): TypeSuggestion[] => (
    selectionSuggestion.hasSearching ? suggestionsResults : suggestionsList
  );

  const handleOthersTypeCreationClick = useCallback((type: TypeSuggestion): void => {
    setOthersTypeSelection(type, true);
  }, [setOthersTypeSelection]);

  const handleOthersTypeSelectionClick = useCallback((type: TypeSuggestion): void => {
    setOthersTypeSelection(type, false);
  }, [setOthersTypeSelection]);

  const handleOthersTypeDeletionClick= useCallback((suggestionId: number): void => {
    deleteLastOthersTypeSuggestion(suggestionId);
  }, [deleteLastOthersTypeSuggestion]);

  if (!suggestionsList.length) return <div />

  return (
    <div className={styles.wrapper}>
      {(!selectionSuggestion.hasSearching && !!lastCreated) && (
        <div className={styles.background}>
          {lastCreated.id && (
            <div className={styles.lastUsed}>
              <div>
                <span className={`icon-credit-clock ${styles.clock}`} />
                <span className={styles.name} onClick={() => handleOthersTypeSelectionClick(lastCreated)} >
                  {lastCreated.name}
                </span>
              </div>
              <div>
                <span className={`icon-close ${styles.close}`} onClick={() => handleOthersTypeDeletionClick(lastCreated.id)} data-testid='icon-close' />
                <span className={`icon-arrow-1 ${styles.iconArrow}`} onClick={() => handleOthersTypeSelectionClick(lastCreated)} data-testid='icon-arrow' />
              </div>
            </div>
          )}
        </div>
      )}

      <div className={styles.list}>
        {getSuggestionsList().map((item, i) => (
          <div
            className={styles.item}
            onClick={() => handleOthersTypeSelectionClick(item)}
            key={`auto-complete-item-${item.id}-${item.name}-${i}`}
          >
            <span className={styles.result}>
              {item.name.slice(0, currentMicroEgressName.length)}
              <span className={`${!!selectionSuggestion.hasSearching && styles.bold}`}>{item.name.slice(currentMicroEgressName.length)}</span>
            </span>
            <span className={`icon-arrow-1 ${styles.icon}`} />
          </div>
        ))}

        {(selectionSuggestion.hasSearching && !!showCreateButton) && (
          <div className={styles.createProvider} onClick={() => handleOthersTypeCreationClick({ id: 0, name: currentMicroEgressName, macroEgressTypeId: CUSTOM_OTHERS_EXPENSE_ID })}>
            <span className={`icon-plus ${styles.icon}`} />
            <span className={styles.text}>{t('expenses.provider_creation_button_text')}</span>
            <span className={styles.createProviderName}>{currentMicroEgressName}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default OthersTypeSuggestionsList;
