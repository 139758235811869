import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format as formatDate } from "date-fns";
import Clevertap, { CUSTOMER_TRANSACTION } from "@config/clevertap";
import {
  CONTACT_LOAN_ONBOARDING_ID,
  FIRST_LOAN_ONBOARDING_ID,
  FIRST_PAYMENT_ONBOARDING_ID,
  LOAN_TRANSACTION_TYPE_ID,
  PAYMENT_TRANSACTION_TYPE_ID,
  TIME_TO_HIDE_NOTIFICATION
} from "@constants";
import { sendWhatsApp } from "@utils/webview";
import { actions as globalActions, globalBalancesSelector, globalUserSelector } from "@config/store";
import { CustomerDetailTransactions, CustomerSimpleForm, CustomerTransactionBody } from "@modules/customers/entities";
import { fetchCreateNewTransaction, } from "@modules/customers/services";
import { CUSTOMERS_DEFAULT_IMAGE, CUSTOMERS_PATH, CUSTOMERS_PAYMENT_METHOD_ID, TABS } from "@modules/customers/constants";
import { actions, customerDetailSelector, customersDataSelector } from "@modules/customers/store";
import useNotification from "@hooks/use-notification";
import useUpdateWallet from "@hooks/use-update-wallet";
import useFormatCurrency from "@hooks/use-format-currency";
import useCustomerFormSubmit from "../use-customer-form-submit";
import useOnboarding from "@hooks/use-onboarding";
import { getPhoneWithAreaCode } from "@modules/customers/utils";

const useCustomerTransaction = () => {
  const { t } = useTranslation();
  const balances = useSelector(globalBalancesSelector);
  const { format } = useFormatCurrency();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showNotification = useNotification();
  const { setNewCustomerTransaction } = useUpdateWallet();
  const { createContactWithDebt } = useCustomerFormSubmit();
  const {
    isOnboardingDone,
    setContactLoanOnboardingDone,
    setFirstLoanOnboardingDone,
    setFirstPaymentOnboardingDone
  } = useOnboarding();
  const storeId = useSelector(globalUserSelector).storeId;
  const customers = useSelector(customersDataSelector);
  const customerDetail = useSelector(customerDetailSelector);

  const generateBody = (
    total: number,
    transactionTypeId: number,
  ): CustomerTransactionBody => ({
    total,
    storeId,
    userId: storeId,
    transactionTypeId,
    description: customerDetail.name,
    cashDeckControlId: balances.cashId,
    customerId: +customerDetail.customerId,
    imageUrl: CUSTOMERS_DEFAULT_IMAGE,
    paymentMethodId: CUSTOMERS_PAYMENT_METHOD_ID,
  });

  const getNewTransactions = (): CustomerDetailTransactions[] => {
    const date = formatDate(new Date(), 'dd MMM yyyy');
    const newTransactions = customerDetail.transactions.filter(e => (
      formatDate(new Date(e.date), 'dd MMM yyyy') !== date
    ));
    if (newTransactions.length === customerDetail.transactions.length) {
      return [{ date, transactionsByDate: [] }, ...newTransactions]
    }
    return [...customerDetail.transactions];
  }

  const updateLocalCustomer = (id: string, transactionTypeId: number, debt: number, total: number): void => {
    const currentTransactions = getNewTransactions();
    const todayTransactions = currentTransactions.splice(-currentTransactions.length, 1)[0];

    dispatch(actions.setCustomerDetail({
      ...customerDetail, debt, transactions: [{
        date: formatDate(new Date(), 'dd MMM yyyy'),
        transactionsByDate: [{
          transactionId: +id,
          date: new Date().toString(),
          total: total,
          type: transactionTypeId,
        }, ...todayTransactions.transactionsByDate]
      }, ...currentTransactions]
    }));

    dispatch(actions.setCustomers(customers.map(c =>
      +c.customerId === +customerDetail.customerId
        ? { ...c, lastPaidLoan: formatDate(new Date(), 'dd/MM/yyyy'), totalDebt: debt }
        : c
    )))
  }

  const createNewTransaction = (total: string, transactionTypeId: number) => {
    const debt = customerDetail.debt + (transactionTypeId === LOAN_TRANSACTION_TYPE_ID ? +total : -total);

    dispatch(globalActions.setIsLoading(true));
    return fetchCreateNewTransaction(generateBody(+total, transactionTypeId))
      .then((transactionId) => {
        setNewCustomerTransaction(transactionId, customerDetail.name, +total, transactionTypeId, customerDetail.customerId);
        updateLocalCustomer(transactionId, transactionTypeId, debt, +total);

        dispatch(actions.setBackDetailRoute(CUSTOMERS_PATH));

        Clevertap.pushEvent(CUSTOMER_TRANSACTION, {
          clientId: customerDetail.customerId,
          type: transactionTypeId,
          value: +total
        });

        navigate(`${CUSTOMERS_PATH}/${TABS.detail}/${customerDetail.customerId}`);
      })
      .catch((e) => {
        showNotification(t("customers.notification.error"), false);
        return Promise.reject();
      })
      .finally(() => dispatch(globalActions.setIsLoading(false)));
  };

  const getLoanMessage = (total: number) => (
    t('customers.whatsapp.new_loan', {
      name: customerDetail.name,
      amount: format(total, true),
      totalDebt: format((+customerDetail.debt || 0) + +total, true),
    })
  );

  const handleWp = (mustSendWp: boolean, total: string, phone?: string) => {
    if (mustSendWp) {
      sendWhatsApp(getPhoneWithAreaCode(phone || customerDetail.phone), getLoanMessage(+total))
    }
  }

  const showSuccesNotification = (isDelayed?: boolean) => (
    setTimeout(() => {
      showNotification(t('customers.notification.transaction'), true)
    }, isDelayed ? TIME_TO_HIDE_NOTIFICATION + 500 : 0)

  );

  const createNewLoan = (total: string, mustSendWp: boolean): void => {
    createNewTransaction(total, LOAN_TRANSACTION_TYPE_ID)
      .then(() => handleWp(mustSendWp, total))
      .then(() => {
        if (!isOnboardingDone(FIRST_LOAN_ONBOARDING_ID))
          setFirstLoanOnboardingDone(+total);
      })
      .then(() => showSuccesNotification())
      .catch(() => false)
  }

  const createNewLoanBeforeCreation = (total: string, mustSendWp: boolean): void => {
    createNewTransaction(total, LOAN_TRANSACTION_TYPE_ID)
      .then(() => handleWp(mustSendWp, total))
      .then(() => {
        showSuccesNotification()
        dispatch(actions.setIsCreationOb(false));
      })
      .catch(() => false)
  }

  const createNewLoanToContact = (contact: CustomerSimpleForm, total: string, mustSendWp: boolean): void => {
    createContactWithDebt(contact, balances.cashId, total)
      .then((id) => navigate(`${CUSTOMERS_PATH}/${TABS.detail}/${id}`))
      .then(() => dispatch(globalActions.setBalance({ ...balances, loan: balances.loan + +total })))
      .then(() => handleWp(mustSendWp, total, contact.phone))
      .then(() => showNotification(t('customers.notification.creation'), true))
      .then(() => showSuccesNotification(true))
      .then(() => {
        if (!isOnboardingDone(CONTACT_LOAN_ONBOARDING_ID))
          setContactLoanOnboardingDone(+total)
      })
      .catch(() => false)
  }

  const createNewPayment = (total: string, mustSendWp: boolean): void => {
    createNewTransaction(total, PAYMENT_TRANSACTION_TYPE_ID)
      .then(() => {
        if (mustSendWp)
          sendWhatsApp(
            getPhoneWithAreaCode(customerDetail.phone),
            t('customers.whatsapp.new_payment', { name: customerDetail.name })
          )
      })
      .then(() => {
        if (!isOnboardingDone(FIRST_PAYMENT_ONBOARDING_ID))
          setFirstPaymentOnboardingDone(+total)
      })
      .then(() => showSuccesNotification())
      .catch(() => false)

  }

  return {
    createNewLoan,
    createNewPayment,
    createNewLoanToContact,
    createNewLoanBeforeCreation
  };
}

export default useCustomerTransaction;
