import React, { FC, memo, ReactElement, useEffect, useRef } from 'react';
import styles from './ui-delayed-component.module.sass';

interface UiDelayedComponentProps {
  timeToDelay: number;
  children: ReactElement;
}

const UiDelayedComponent: FC<UiDelayedComponentProps> = ({ children, timeToDelay }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const delayFunction = setTimeout(() => {
      if (ref.current) ref.current.style.display = 'flex'
    }, timeToDelay);
    return () => { clearTimeout(delayFunction) }
  }, [timeToDelay]);

  return (
    <div ref={ref} className={styles.wrapper}>
      {children}
    </div>
  )
}

export default memo(UiDelayedComponent);
