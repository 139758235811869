import React, { ChangeEvent, FC, memo, useEffect, useRef, useState } from 'react';
import useFormatCurrency from '@hooks/use-format-currency';
import { TransactionType } from '@constants';
import styles from "./ui-money-value.module.sass";
import useMoneyField from '@hooks/use-money-field';

interface UiMoneyValueProps {
  value: number;
  type: TransactionType | 'regular' | 'gray';
  size?: 'normal' | 'large';
  isEditable?: boolean;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  hasAutofocus?: boolean;
}

const UiMoneyValue: FC<UiMoneyValueProps> = ({
  value,
  type,
  size = 'normal',
  onChange,
  isEditable,
  hasAutofocus
}) => {
  const { sign, format } = useFormatCurrency();
  const [isChanged, setIsChanged] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isChanged) setIsChanged(true);
    if (onChange) onChange(event);
  }

  useEffect(() => setIsChanged(!isEditable), [isEditable]);

  const { moneyValue, handleChange } = useMoneyField(handleInputChange, String(value));

  useEffect(() => {
    if (isEditable && divRef.current && inputRef.current) {
      divRef.current.innerHTML = format(value)
      inputRef.current.style.width = `${divRef.current.clientWidth}px`
    }
  }, [format, isEditable, value])

  return (
    <div className={`
      ${styles.wrapper} 
      ${styles[type]} 
      ${styles[size]} 
      ${value < 0 && styles.negative}
      ${value === 0 && styles.zero}
    `}>
      <div className={`${styles.sign} ${(!isChanged && isFocused) && styles.noChanged}`}>
        {<span className={styles.minus}>-</span>}
        {sign}
      </div>

      {!isEditable ? (
        format(Math.abs(value))
      ) : (
        <>
          <input
            ref={inputRef}
            className={`
              ${styles.input} 
              ${(!isChanged && isFocused) && styles.noChanged}
              ${styles[type]}
              ${value === 0 && styles.zero}
            `}
            type='tel'
            value={moneyValue}
            autoFocus={hasAutofocus}
            onChange={handleChange}
            autoComplete='off'
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <div ref={divRef} className={styles.invisibleDiv} />
        </>
      )}
    </div>
  );
}

export default memo(UiMoneyValue);
