import React, { FC, useEffect } from 'react';
import { setUXCamScreen } from '@utils/webview';
import useRemoveFilters from './hooks/use-remove-filters';
import HomeRouter from './routers/home-router.router';
import { UXCAM_SCREENS } from '@constants';

interface HomeProps { }

const Home: FC<HomeProps> = () => {
  useRemoveFilters();

  useEffect(() => {
    setUXCamScreen(UXCAM_SCREENS.home)
  }, []);

  return (
    <HomeRouter />
  );
}

export default Home;
