import React, { FC, useEffect } from 'react';
import { actions as globalActions } from '@config/store';
import { useDispatch } from 'react-redux';
import { ROUTES } from '@constants';
import { actions } from '@modules/home/store';
import { useTranslation } from 'react-i18next';
import styles from './home-filters.module.sass';
import useFilters from '@modules/home/hooks/use-filters/use-filters';
import FiltersContent from '@modules/home/components/home-filters-content';
import FiltersDatePickerFields from '@modules/home/components/home-filters-date-picker-fields';

interface HomeFiltersProps { }

const HomeFilters: FC<HomeFiltersProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    return () => { dispatch(actions.setMustApplyFilters(false)) }
  }, [dispatch]);

  useEffect(() => {
    dispatch(globalActions.header({
      title: t('Bolsillo.dashboard.filters.topbar.title'),
      hasRightClick: true,
      routeOnRightClick: ROUTES.home
    }))
  }, [dispatch, t]);

  return (
    <div className={styles.container}>
      <FiltersDatePickerFields />

      <FiltersContent />

      <FiltersManager />
    </div>
  );
}

export default HomeFilters;

const FiltersManager = () => {
  useFilters();
  return <div />
}
