import { configureStore } from '@reduxjs/toolkit';
import globalReducer from './global.slice';
import homeReducer from '@modules/home/store/home.slice';
import expensesReducer from '@modules/expenses/store/expenses.slice';
import incomeReducer from '@modules/income/store/income.slice';
import customersReducer from '@modules/customers/store/customers.slice';
import baseReducer from '@modules/base/store/base.slice';
import boxClosureReducer from '@modules/box-closure/store/box-closure.slice';
import creditsReducer from '@modules/credits/store/credits.slice';

export const store = configureStore({
  reducer: {
    global: globalReducer,
    home: homeReducer,
    expenses: expensesReducer,
    income: incomeReducer,
    customers: customersReducer,
    base: baseReducer,
    boxClosure: boxClosureReducer,
    credits: creditsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
