import { StepSection } from "@entities";
import { TITLES } from "./expenses.constants";

export const PROVIDER_TOTAL_STEPS = 4;

export const PROVIDER_PAYMENT_METHOD_STEP: StepSection = {
  totalSteps: PROVIDER_TOTAL_STEPS,
  currentStep: 2,
  stepName: TITLES.paymenentMethod
};

export const PROVIDER_NAME_STEP: StepSection = {
  totalSteps: PROVIDER_TOTAL_STEPS,
  currentStep: 3,
  stepName: TITLES.providerName
};

export const PROVIDER_AMOUNT_STEP: StepSection = {
  totalSteps: PROVIDER_TOTAL_STEPS,
  currentStep: 4,
  stepName: TITLES.amount
};

export const PROVIDER_TABS = {
  paymentMethod: 'payment_method',
  name: 'name',
  amount: 'amount'
};
