import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CLOSURE_ONBOARDING_ID, ROUTES } from "@constants";
import Clevertap, { CLOSE_CASH_COMPLETE } from "@config/clevertap";
import { actions as globalActions, globalBalancesSelector, globalUserSelector } from "@config/store";
import { fetchCreateBoxClosure } from "@modules/box-closure/services";
import { boxClosureFormSelector } from "@modules/box-closure/store";
import { BoxClosure } from "@modules/box-closure/entities";
import { showEcommerceTabs } from "@utils/webview";
import useNotification from "@hooks/use-notification";
import useUpdateWallet from "@hooks/use-update-wallet";
import useOnboarding from "@hooks/use-onboarding";

const useBoxClosureFetch = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const balances = useSelector(globalBalancesSelector);
  const form = useSelector(boxClosureFormSelector);
  const user = useSelector(globalUserSelector)
  const navigate = useNavigate();
  const showNotification = useNotification();
  const { setNewClosure } = useUpdateWallet();
  const { setClosureOnboardingDone, isOnboardingDone } = useOnboarding();

  const createNewBoxClosure = useCallback((): void => {
    const body: BoxClosure = {
      storeId: user.storeId,
      userId: user.storeId,
      cashDeckControlId: balances.cashId,
      hasBase: balances.hasBase,
      loan: balances.loan,
      debt: balances.debt,
      expectedIncome: balances.income,
      expectedEgress: balances.egress,
      expectedBase: balances.base,
      expectedBalance: balances.balance,
      realIncome: form.incomes,
      realEgress: form.expenses,
      realBase: form.base,
      realBalance: form.incomes - form.expenses
    };

    dispatch(globalActions.setIsLoading(true));
    fetchCreateBoxClosure(body)
      .then((newCashId) => {
        if (!isOnboardingDone(CLOSURE_ONBOARDING_ID)) setClosureOnboardingDone()
        showEcommerceTabs();

        Clevertap.pushEvent(CLOSE_CASH_COMPLETE, {
          balance: body.realBalance,
          ingress: body.realIncome,
          egress: body.realEgress,
          base: body.realBase,
          isBaseMaintained: body.realBase === body.expectedBase
        });

        setNewClosure(body.realBalance, body.realBase, newCashId, body.cashDeckControlId);
        showNotification(t("box_closure.notification.successful"), true);
        navigate(ROUTES.home);
      })
      .catch(() => showNotification(t("box_closure.notification.message_error"), false))
      .finally(() => dispatch(globalActions.setIsLoading(false)));
  }, [user, balances, form, dispatch, setNewClosure, showNotification, isOnboardingDone, t, navigate, setClosureOnboardingDone]);


  return {
    createNewBoxClosure,
  }
};

export default useBoxClosureFetch;
