import React, { FC, memo, useState } from 'react';
import i18next from 'i18next';
import { format, getDaysInMonth, isAfter, isBefore, isSameDay, isToday, isWithinInterval, setDate, startOfMonth } from 'date-fns';
import { es, ptBR } from 'date-fns/locale';
import styles from './ui-date-picker.module.sass';

const WEEK_DAYS: string[] = ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'];

const locales = {
  co: es,
  mx: es,
  br: ptBR
}
interface UiDatePickerProps {
  month: number;
  year: number;
  initDate?: Date;
  endDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  onClick: (date: Date) => void;
}

const UiDatePicker: FC<UiDatePickerProps> = ({ month, year, initDate, endDate, minDate, maxDate, onClick }) => {
  const [pickerDate] = useState<Date>(new Date(year, month, 0));

  const isIntoRange = (day: number) => (
    !!initDate && !!endDate && isWithinInterval(setDate(pickerDate, day), { start: initDate, end: endDate })
  );

  const isInitDate = (day: number) => !!initDate && isSameDay(initDate, setDate(pickerDate, day));

  const isEndDate = (day: number) => !!endDate && isSameDay(endDate, setDate(pickerDate, day));

  const isSelectedDate = (day: number) => isInitDate(day) || isEndDate(day);

  const isDisabledDay = (day: number) => {
    const currentDate = setDate(pickerDate, day);
    return (!!maxDate && isAfter(currentDate, maxDate)) || (!!minDate && (isBefore(currentDate, minDate)));
  }

  const getDaysBeforeCurrentMonthElements = () => {
    const firstDayIndex = format(startOfMonth(pickerDate), 'c', { locale: es });
    return new Array(+firstDayIndex - 1).fill('').map((_, i) => (
      <div key={`day-number-before-${i}`} className={styles.empty} />
    ))
  }

  const handleDateClick = (day: number) => {
    if (!isDisabledDay(day)) onClick(setDate(pickerDate, day));
  }

  const getDaysOfCurrentMonthElements = () => {
    const daysNumber = getDaysInMonth(pickerDate);
    return new Array(daysNumber).fill(daysNumber).map((_, i) => (
      <div
        key={`day-number-current-${i}`}
        className={`${styles.cell} 
          ${isToday(setDate(pickerDate, i + 1)) && styles.today}
          ${isSelectedDate(i + 1) && styles.selected}
          ${isEndDate(i + 1) && styles.endRange}
          ${!!endDate && isInitDate(i + 1) && styles.initRange}
          ${isIntoRange(i + 1) && styles.range}
          ${isEndDate(i + 1) && isInitDate(i + 1) && styles.notRange}
          ${isDisabledDay(i + 1) && styles.isDisabled}
          ${i === 0 && styles.first}
        `}
      >
        <div className={styles.day} onClick={() => handleDateClick(i + 1)}>{i + 1}</div>
      </div>
    ));
  }

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>
        <span className={styles.month}>
          {format(pickerDate, 'LLLL', { locale: locales[i18next.language as keyof object] })} 
        </span>
        <span className={styles.year}> {format(pickerDate, 'yyyy', { locale: es })}</span>
      </p>

      <div className={styles.calendar}>
        {WEEK_DAYS.map(d => (
          <div key={`cell-day-${d}`} className={styles.dayName}>
            {d}
          </div>
        ))}

        {getDaysBeforeCurrentMonthElements()}

        {getDaysOfCurrentMonthElements()}
      </div>
    </div>
  );
}

export default memo(UiDatePicker);
