export const servicesMenu = {
  spanish: {
    "title": 'Selecciona un movimiento',
    "income": 'Ingreso',
    "expense": 'Gasto',
    "loan": 'Fiar',
    "box_closure": 'Caja',
  },
  portuguese: {
    "title": 'Selecione um tipo de transação',
    "income": 'Entrada',
    "expense": 'Saída',
    "loan": 'Fiado',
    "box_closure": 'Caixa',
  }
};
