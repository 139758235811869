import { ProviderCreditDetail, ProvidersWithCredit } from "../entities";

export interface CreditsState {
  ui: CreditsUiState;
  data: CreditsDataState;
};

export interface CreditsUiState {
  isLoading: boolean;
};

export interface PaymentCreditDataState {
  total: string;
};

export interface ProviderSearchedValueState {
  searchedValue: string;
}

export interface CreditsDataState {
  providersWithCredit: ProvidersWithCredit[];
  provider: ProviderCreditDetail;
  payment: PaymentCreditDataState;
  providerSearchedValue: ProviderSearchedValueState;
};

export const initialState: CreditsState = {
  ui: {
    isLoading: false,
  },
  data: {
    providersWithCredit: [],
    provider: {} as ProviderCreditDetail,
    payment: { total: '' },
    providerSearchedValue: { searchedValue: '' },
  }
};
