import { memo, ChangeEventHandler, FC, ChangeEvent, useState, useEffect, useCallback } from 'react';
import styles from "./ui-search-bar.module.sass";

interface UiSearchBarProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  defaultValue?: string;
  hasError?: boolean;
  isClosing?: boolean;
  isLarge?: boolean;
}

const UiSearchBar: FC<UiSearchBarProps> = ({
  onChange,
  placeholder,
  defaultValue = '',
  hasError,
  isClosing,
  isLarge,
}) => {
  const [value, setValue] = useState<string>(defaultValue);

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
    onChange(event);
  }, [onChange]);

  const onClose = useCallback(() => {
    handleInputChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
  }, [handleInputChange]);

  useEffect(() => {
    if (isClosing) onClose();
  }, [isClosing, onClose]);

  return (
    <div className={`${styles.wrapper} ${hasError && styles.error} ${(!!value) && styles.active} ${isLarge && styles.large}`}>
      <span className={`icon-search ${styles.searchIcon}`} />
      <input
        placeholder={placeholder}
        onChange={handleInputChange}
        className={styles.input}
        id='ui-search-bar'
        autoComplete='off'
        value={value}
      />
      {(!!value || hasError) && (
          <span className={`icon-close ${styles.icon}`} onClick={onClose} id='ui-search-bar-icon' data-testid="close-icon" />
      )}
    </div>
  );
}

export default memo(UiSearchBar);
