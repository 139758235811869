import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CUSTOMER_DETAIL_INITIAL_STATE } from "../constants";
import { Customer, CustomerDetail } from "../entities";
import { initialState } from "./customers.state";

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoading = payload;
    },
    setHasRequestedCustomers: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasRequestedCustomers = payload;
    },
    setBackDetailRoute: (state, { payload }: PayloadAction<string>) => {
      state.ui.backDetailRoute = payload;
    },
    setisSearchBarActive: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isSearchBarActive = payload;
    },
    setCustomers: (state, { payload }: PayloadAction<Customer[]>) => {
      state.data.customers = payload;
    },
    setSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.data.search.value = payload;
    },
    setCustomerDetail: (state, { payload }: PayloadAction<CustomerDetail>) => {
      state.data.detail = payload;
    },
    restartDetailState: (state) => {
      state.data.detail = CUSTOMER_DETAIL_INITIAL_STATE;
    },
    setIsCreationOb: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isCreationOb = payload;
    }
  },
});

export const actions = customersSlice.actions;
export default customersSlice.reducer;
