import { FooterSection, StepSection } from "@entities";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EMPTY_STATE, Expenses } from "../constants";
import { MacroEgress, MicroEgress, SearchedProvider, SelectionSuggestionPayload, TypeSuggestion } from "../entities";

import { initialState, SearchbarStatus } from './expenses.state';

export const expensesSlice = createSlice({
  name: 'expenses',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoading = payload;
    },
    setStep: (state, { payload }: PayloadAction<StepSection>) => {
      state.ui.step = { ...state.ui.step, ...payload };
    },
    setFooter: (state, { payload }: PayloadAction<FooterSection>) => {
      state.ui.footer = { ...state.ui.footer, ...payload };
    },
    setSelectionSuggestion: (state, { payload }: PayloadAction<SelectionSuggestionPayload>) => {
      state.ui.selectionSuggestion = { ...state.ui.selectionSuggestion, ...payload };
    },
    setProviderSearchbarStatus: (state, { payload }: PayloadAction<SearchbarStatus>) => {
      state.ui.providerSearchbarStatus = payload;
    },
    setHasSearchedProviders: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.hasSearchedProviders = payload;
    },
    setMacroEgress: (state, { payload }: PayloadAction<MacroEgress[]>) => {
      state.types.macroEgress = payload;
    },
    setMicroEgress: (state, { payload }: PayloadAction<MicroEgress[]>) => {
      state.types.microEgess = payload;
    },
    setCurrentMacroEgress: (state, { payload }: PayloadAction<number>) => {
      state.data.currentMacroEgressTypeId = payload;
    },
    setCurrentMicroEgressTypeId: (state, { payload }: PayloadAction<number>) => {
      state.data.currentMicroEgressTypeId = payload;
    },
    setCurrentMicroEgressName: (state, { payload }: PayloadAction<string>) => {
      state.data.currentMicroEgressName = payload;
    },
    setCurrentExpense: (state, { payload }: PayloadAction<Expenses>) => {
      state.data.currentExpense = payload;
    },
    setCurrentPaymentMethod: (state, { payload }: PayloadAction<string>) => {
      state.data.currentPaymentMethodId = payload;
    },
    setIsCustomProviderExpense: (state, { payload }: PayloadAction<boolean>) => {
      state.data.isCustomProviderExpense = payload;
    },
    setSearchProvidersResults: (state, { payload }: PayloadAction<SearchedProvider[]>) => {
      state.data.searchProvidersResults = payload;
    },
    setMostUsedProviders: (state, { payload }: PayloadAction<SearchedProvider[]>) => {
      state.data.mostUsedProviders = payload;
    },
    setMostRecentProviders: (state, { payload }: PayloadAction<SearchedProvider[]>) => {
      state.data.mostRecentProviders = payload;
    },
    setCurrentAmount: (state, { payload }: PayloadAction<string>) => {
      state.data.currentAmount = payload;
    },
    setOthersTypeSuggestionsResults: (state, { payload }: PayloadAction<TypeSuggestion[]>) => {
      state.data.searchOthersTypeSuggestionsResults = payload;
    },
    setOtherTypeLastCreated: (state, { payload }: PayloadAction<TypeSuggestion>) => {
      state.data.othersTypeLastCreated = payload;
    },
    setOthersTypeSuggestionsList: (state, { payload }: PayloadAction<TypeSuggestion[]>) => {
      state.data.othersTypeSuggestionsList = payload;
    },
    restartState: (state) => {
      state.data = EMPTY_STATE;
      state.ui.selectionSuggestion = { isSelected: false, showList: false, hasSearching: false }
    },
    setTourStep: (state, { payload }: PayloadAction<number>) => {
      state.ui.tourStep = payload;
    }
  },
});

export const actions = expensesSlice.actions;
export default expensesSlice.reducer;
