import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseInfo from '@modules/base/components/base-info';
import styles from './base-home.module.sass';
import TransactionList from '@modules/base/components/transaction-list';
import useGetBaseTransactions from '@modules/base/hooks/use-get-base-transactions';
import { actions as globalActions } from '@config/store';
import { actions, baseUiSelector } from '@modules/base/store';
import { ROUTES } from '@constants';
import UiDivider from '@ui/ui-divider';
import UiBaseSkeleton from '../../components/base-skeleton';
import { useTranslation } from 'react-i18next';
import { BASE_HEADER_TITLE } from '@modules/base/constants';

interface BaseHomeProps { }

const BaseHome: FC<BaseHomeProps> = () => {
  useGetBaseTransactions();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(baseUiSelector);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(globalActions.header({
      title: t(BASE_HEADER_TITLE),
      hasBackClick: true,
      routeOnBackClick: ROUTES.home,
      hasRightClick: false
    }));
    dispatch(actions.setEscapeRoute(ROUTES.base));
    return () => { dispatch(globalActions.header({ hasBackClick: false })) }
  }, [dispatch, t]);

  if (isLoading) return <UiBaseSkeleton />

  return (
    <div className={styles.wrapper}>

      <BaseInfo />

      <UiDivider />

      <TransactionList />

    </div>
  )
}

export default BaseHome;
