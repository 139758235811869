export const box_closure = {
  spanish: {
    "title": {
      "header": "Caja",
      "balance": "Balance de cierre",
      "edit": "Ajustar caja",
      "base": "Base",
      "income": "Ingresos",
      "expenses": "Gastos",
      "loan": "Me deben",
      "debts": "Mis deudas",
      "total": "Total",
      "transactions": "Movimientos"
    },
    "notification": {
      "successful": "Cierre de caja existoso",
      "balances_fix": "Registramos tus compras en Chiper, el cierre de caja fue actualizado",
      "message_error": "Lo sentimos. Ha ocurrido un error y no pudimos realizar el cierre de caja",
      "validation_error": "El monto no debe ser mayor a {{max}}",
      "info_error": "Lo sentimos. No pudimos obtener el detalle de su cierre de caja",
      "page_error": "Lo sentimos. No pudimos obetener las transacciones de su caja",
      "update_values_success": "Ajuste de caja exitoso",
    },
    "edition_button": {
      "exit": "Salir",
      "update": "Actualizar",
    },
    "resume": "Resumen de métodos de pago",
    "no_resume": "No logramos obtener su resumen",
    "tour": {
      "step-1": "Cambia los valores",
      "step-2": "Presiona cerrar caja",
      "step-3": "Cambia el valor de la opción que necesites",
      "step-4": "Guarda los nuevos registros",
    }
  },
  portuguese: {
    "title": {
      "header": "Caixa",
      "balance": "Saldo final",
      "edit": "Ajustar caixa",
      "base": "Troco",
      "income": "Entrada",
      "expenses": "Saída",
      "loan": "Me devem",
      "debts": "Minhas dívidas",
      "total": "Total",
      "transactions": "Movimentos"
    },
    "notification": {
      "successful": "Caixa fechado com sucesso",
      "balances_fix": "Registramos suas compras em Chiper, o fechamento do caixa foi atualizado",
      "message_error": "Desculpe. Ocorreu um erro e não pudemos realizar o fechamento do caixa",
      "validation_error": "O valor deve ser maior a {{max}}",
      "info_error": "Desculpe. Não pudemos obter a informação",
      "page_error": "Desculpe. Não pudemos obter suas transações de caixa",
      "update_values_success": "Ajuste de caixa realizado com sucesso",
    },
    "edition_button": {
      "exit": "Sair",
      "update": "Atualizar",
    },
    "resume": "Resumo de métodos de pagamento",
    "no_resume": "Não pudemos obter seu resumo",
    "tour": {
      "step-1": "Altere os valores",
      "step-2": "Pressione fechar caixa",
      "step-3": "Altere o valor da opção que necessite",
      "step-4": "Salve os novos registros",
    }
  }
}
