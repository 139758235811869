import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ui-paginator.module.sass';
import UiButton from '@ui/ui-button';

interface UiPaginatorProps {
  onClick: () => void;
  isLoading: boolean;
  hideButton: boolean;
  delayToShow?: number;
}

const UiPaginator: FC<UiPaginatorProps> = ({ onClick, isLoading, hideButton, delayToShow = 4000 }) => {
  const { t } = useTranslation();

  if (hideButton) return <div />

  return (
    <div className={styles.wrapper} style={{ animationDelay: `${delayToShow}ms` }}>
      <div className={styles.button}>
        <UiButton
          text={t('dashboard.paginator_button_text')}
          onClick={onClick}
          isSecondary
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default memo(UiPaginator);
