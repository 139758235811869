import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@constants';
import Clevertap, { CLOSE_CASH_START } from '@config/clevertap';
import { actions as globalActions } from '@config/store';
import styles from './box-closure-home.module.sass';
import BoxClosureHomeFooter from '@modules/box-closure/components/box-closure-home-footer';
import BoxClosureHomeResumenWrapper from '@modules/box-closure/components/box-closure-home-resumen-wrapper';
import useFormEdition from '@modules/box-closure/hooks/use-form-edition';
import BoxClosureTourTooltip from '@modules/box-closure/components/box-closure-tour-tooltip';

export interface BoxClosureHomeProps { }

const BoxClosureHome: FC<BoxClosureHomeProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { restartForm, closeEditon } = useFormEdition();

  useEffect(() => {
    Clevertap.pushEvent(CLOSE_CASH_START);
    restartForm();
  }, [restartForm]);

  useEffect(() => {
    return () => { closeEditon(); }
  }, [closeEditon]);

  useEffect(() => {
    dispatch(globalActions.header({
      title: t('Bolsillo.caja.topbar.title'),
      hasBackClick: false,
      routeOnRightClick: ROUTES.home,
      hasRightClick: true
    }));
  }, [dispatch, t]);

  return (
    <div className={styles.wrapper}>
      <BoxClosureHomeResumenWrapper />

      <BoxClosureHomeFooter />
      
      <BoxClosureTourTooltip />
    </div>
  );
}

export default BoxClosureHome;
