import { FooterSection, StepSection } from "@entities";
export interface IncomeState {
  ui: IncomeUiState;
  data: IncomeDataState;
};

export interface IncomeUiState {
  isLoading: boolean;
  step: StepSection;
  footer: FooterSection;
};

export interface IncomeDataState {
  currentPaymentMethodId: string;
  currentAmount: string;
};

export const initialState: IncomeState = {
  ui: {
    isLoading: false,
    step: {
      currentStep: 0,
      totalSteps: 0,
      stepName: ''
    },
    footer: {
      isDisabled: true,
      routeToBack: '/',
      routeToNext: '/',
      hasSubmitFunction: false,
    },
  },
  data: {
    currentPaymentMethodId: '',
    currentAmount: '',
  }
};
