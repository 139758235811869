import axios from "axios";
import { POCKET_API, TRANSACTIONS_API } from "@constants";
import { Tokens, PaymentMethod, GetTransactionsResponse, GetTransactionsBody, Balances, NewCashIdRequest } from "@entities";

export const fetchGetPaymentMethods = (): Promise<PaymentMethod[]> =>
  axios.get<PaymentMethod[]>(`${POCKET_API}/payment-method/get-payment-method`)
    .then(resp => resp.data);

export const fetchUserLogin = (storeId: number, uid?: string,): Promise<Tokens> =>
  axios.post(`${POCKET_API}/auth/login`, { storeId, password: process.env.REACT_APP_AUTH_TOKEN, uid })
    .then(resp => resp.data);

export const fetchGetTransactions = (storeId: number, query: GetTransactionsBody): Promise<GetTransactionsResponse> => {
  return axios.post<GetTransactionsResponse>(
    `${TRANSACTIONS_API}/transaction/get-transactions`, { ...query, storeId }
  ).then(resp => ({ ...resp.data }));
};

export const fetchGetBalances = (storeId: number): Promise<Balances> =>
  axios.get<Balances>(`${TRANSACTIONS_API}/cash-deck-control/get-balance/${storeId}/${storeId}`)
    .then(resp => (resp.data));

export const fetchCreateNewCashId = (body: NewCashIdRequest): Promise<number> =>
  axios.post(`${POCKET_API}/cash-deck-control/create-cash`, body)
    .then(resp => resp.data.id);
