import { ROUTES } from "@constants";
import { FooterSection, StepSection, TransactionsByDate } from "@entities";
import { PaginatorSection } from "../entities";
export interface BaseState {
  ui: BaseUiState;
  data: BaseDataState;
}

export interface BaseUiState {
  isLoading: boolean;
  step: StepSection;
  footer: FooterSection;
  paginator: PaginatorSection;
  escapeRoute: string;
}

export interface BaseDataState {
  currentAmount: string;
  currentReason: string;
  baseTransactions: TransactionsByDate[]
}

export const initialState: BaseState = {
  ui: {
    isLoading: false,
    escapeRoute: ROUTES.home,
    step: {
      currentStep: 0,
      totalSteps: 0,
      stepName: ''
    },
    footer: {
      isDisabled: true,
      routeToBack: '/',
      routeToNext: '/',
      hasSubmitFunction: false,
    },
    paginator: {
      currentPage: 0,
      totalPages: 0
    },
  },
  data: {
    currentAmount: '',
    currentReason: '',
    baseTransactions: []
  }
};
