import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { globalUserSelector } from "@config/store";
import { fetchGetTransactions } from "@services";
import { DEFAULT_TRANSACTIONS_PER_PAGE } from "@constants";
import {
  actions, homeFilterMovementsSelector, homeFiltersDateSelector,
  homeUiApplyFiltersSelector, homeUiFiltersSelector
} from "@modules/home/store";

const useFilters = () => {
  const dispatch = useDispatch();
  const user = useSelector(globalUserSelector);
  const homeFilter = useSelector(homeFiltersDateSelector);
  const transactionTypes = useSelector(homeFilterMovementsSelector);
  const mustApplyFilters = useSelector(homeUiApplyFiltersSelector);
  const isFilterActive = useSelector(homeUiFiltersSelector);

  useEffect(() => {
    if (!isFilterActive) dispatch(actions.resetFilters());
  }, [dispatch, isFilterActive]);

  useEffect(() => {
    if (mustApplyFilters) {
      const body = {
        search: '',
        limit: DEFAULT_TRANSACTIONS_PER_PAGE,
        skip: 0,
        startDate: !!homeFilter.initDate ? format(new Date(homeFilter.initDate), "yyyy/MM/dd") : undefined,
        endDate: !!homeFilter.endDate ? format(new Date(homeFilter.endDate), "yyyy/MM/dd") : undefined,
        transactionTypes
      };

      fetchGetTransactions(user.storeId, body)
        .then(data => dispatch(actions.setFiltersResults(data)))
        .catch(() => false)
    }
  }, [dispatch, homeFilter.endDate, homeFilter.initDate, mustApplyFilters, user.storeId, transactionTypes]);

}

export default useFilters;
