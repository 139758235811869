import { StepSection } from "@entities";
import { TITLES } from ".";

export const OTHERS_TOTAL_STEPS = 4;

export const OTHERS_REASON_STEP: StepSection = {
  totalSteps: OTHERS_TOTAL_STEPS,
  currentStep: 2,
  stepName: TITLES.others
};

export const OTHERS_PAYMENT_METHOD_STEP: StepSection = {
  totalSteps: OTHERS_TOTAL_STEPS,
  currentStep: 3,
  stepName: TITLES.paymenentMethod
};

export const OTHERS_AMOUNT_STEP: StepSection = {
  totalSteps: OTHERS_TOTAL_STEPS,
  currentStep: 4,
  stepName: TITLES.amount
};

export const OTHERS_TABS = {
  amount: 'amount',
  paymentMethod: 'payment_method',
  reason: 'reason',
  type: 'type',
};

export const REASONS_LIST = [
  { text: 'expenses.reason.unexpected' },
  { text: 'expenses.reason.financial_services' },
  { text: 'expenses.reason.public_services' },
  { text: 'expenses.reason.general_services' },
  { text: 'expenses.reason.payroll' },
  { text: 'expenses.reason.buy_technology' },
  { text: 'expenses.reason.taxes' },
  { text: 'expenses.reason.furniture' },
  { text: 'expenses.reason.arrangements' },
  { text: 'expenses.reason.debts' },
  { text: 'expenses.reason.fuels' },
];
