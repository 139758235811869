import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BoxClosureDetail, BoxClosureDetailTransactionsResponse, EditionTypeId } from "../entities";
import { BoxClosureFormState, initialState } from "./box-closure.state";

export const boxClosureSlice = createSlice({
  name: 'boxClosure',
  initialState,
  reducers: {
    setIsEditionActiveId: (state, { payload }: PayloadAction<EditionTypeId>) => {
      state.ui.editionActiveId = payload;
    },
    setForm: (state, { payload }: PayloadAction<Partial<BoxClosureFormState>>) => {
      state.data.form = { ...state.data.form, ...payload };
    },
    setInitialForm: (state, { payload }: PayloadAction<BoxClosureFormState>) => {
      state.data.initialForm = payload;
    },
    setTransactionsToDetail: (state, { payload }: PayloadAction<BoxClosureDetailTransactionsResponse>) => {
      state.data.detail.transactions = payload;
    },
    setBoxClosureDetail: (state, { payload }: PayloadAction<BoxClosureDetail>) => {
      state.data.detail.data = payload;
    },
  },
});

export const actions = boxClosureSlice.actions;
export default boxClosureSlice.reducer;
