import React, { FC } from 'react';
import styles from './home-loader-skeleton.module.sass';

interface HomeLoaderSkeletonProps { }

const HomeLoaderSkeleton: FC<HomeLoaderSkeletonProps> = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.date} />
        <div className={styles.list}>
          {Array(9).fill('').map((_, i) => (
            <div className={styles.animation} key={`skeleton-transaction-${i}`}>
              <div className={styles.transaction}>
                <div className={styles.icon} />
                <div className={styles.info}>
                  <div className={styles.title} />
                  <div className={styles.time} />
                </div>
              </div>
              <div className={styles.money} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HomeLoaderSkeleton;
