import { memo, FC, ChangeEventHandler, useRef } from 'react';
import { t } from 'i18next';
import styles from "./ui-input-money.module.sass";
import useMoneyField from '@hooks/use-money-field';

interface UiInputMoneyProps {
  label?: string;
  name: string;
  value?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  error?: string;
  hasAutoFocus?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  errorMessage?: string;
}

const UiInputMoney: FC<UiInputMoneyProps> = ({
  label = '',
  name,
  value,
  isDisabled,
  hasError,
  error,
  hasAutoFocus,
  onChange,
  onBlur,
  errorMessage = '',
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { moneyValue, handleChange } = useMoneyField(onChange, value);

  return (
    <>
      <label className={`${styles.wrapper} ${hasError && styles.error} ${isDisabled && styles.disabled}`}>
        <div className={styles.left}>
          <span className={styles.label}>{label}</span>
          <div className={`${styles.inputWrapper} ${moneyValue && styles.show} ${hasError && styles.error}`}>
            <span className={styles.sign}>{t("currency.sign")}</span>
            <input
              ref={inputRef}
              type='tel'
              autoFocus={hasAutoFocus}
              name={name}
              placeholder={t("currency.sign")}
              value={moneyValue}
              className={styles.input}
              onChange={handleChange}
              onBlur={onBlur}
              disabled={isDisabled}
              autoComplete='off'
              data-testid='ui-input-money'
            />
          </div>
        </div>
      </label>
      {(hasError && !!errorMessage) && <span className={styles.errorMessage}>{errorMessage}</span>}
    </>
  );
}

export default memo(UiInputMoney);
