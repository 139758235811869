import { ROUTES } from "@constants";
import { Customer, CustomerDetail } from "../entities";
import { CUSTOMER_DETAIL_INITIAL_STATE } from "../constants";

export interface CustomersState {
  ui: CustomersUiState;
  data: CustomersDataState;
}

export interface CustomersUiState {
  isLoading: boolean;
  hasRequestedCustomers: boolean;
  backDetailRoute: string;
  isCreationOb: boolean;
  isSearchBarActive: boolean;
}

export interface CustomersSearchState {
  value: string;
}

export interface CustomersDataState {
  customers: Customer[];
  search: CustomersSearchState,
  detail: CustomerDetail;
}

export const initialState: CustomersState = {
  ui: {
    isLoading: false,
    hasRequestedCustomers: false,
    isSearchBarActive: false,
    backDetailRoute: ROUTES.home,
    isCreationOb: false
  },
  data: {
    customers: [],
    search: { value: '' },
    detail: CUSTOMER_DETAIL_INITIAL_STATE,
  },
};
