import React, { FC } from 'react';
import styles from './base-info.module.sass';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TABS } from '@modules/base/constants';
import UiMoneyValue from '@ui/ui-money-value';
import { useTranslation } from 'react-i18next';
import { globalBalancesSelector } from '@config/store';

interface BaseInfoProps { }

const BaseInfo: FC<BaseInfoProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const balances = useSelector(globalBalancesSelector);

  const handleButtonClick = () => {
    navigate(TABS.amount)
  };

  return (
    <div className={styles.wrapper}>

      <div className={styles.left}>
        <span className={styles.title}>{t('base_module.home.actual')}</span>
        <UiMoneyValue value={balances.base} type='regular' size='large' />
      </div>

      <div className={styles.right} onClick={handleButtonClick}>
        {t('base_module.home.update')}
      </div>

    </div>
  )
}

export default BaseInfo;
