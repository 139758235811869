export const dashboard = {
  spanish: {
    "title": "Bolsillo",
    "transactions_title": 'Movimientos',
    "owe_me": 'Me deben',
    "expenses": 'Gastos',
    "incomes": 'Ingresos',
    "my_money": 'Mi dinero',
    "total_title": "Total",
    "base": 'Base',
    "debts_title": "Deudas",
    "no_transactions_yet": 'Aún no tienes movimientos registrados',
    "searchbar_placeholder": 'Buscar movimiento',
    "searching_footer_text": "Buscar",
    "searching_no_results_text": "No existen resultados para tu búsqueda",
    "add_transaction": 'Agregar movimiento',
    "today_date": "Hoy",
    "yesterday_title": "Ayer",
    "my_doubts": "Mis deudas",
    "error_message": "En este momento estamos sincronizando tus movimientos, intenta ingresar más tarde.",
    "paginator_button_text": "Ver más",
    "services_menu_new": 'Nuevo'
  },
  portuguese: {
    "title": "Carteira",
    "transactions_title": 'Movimentos',
    "owe_me": 'Me devem',
    "expenses": 'Saídas',
    "incomes": 'Entradas',
    "my_money": 'Meu dinheiro',
    "total_title": "Total",
    "base": 'Troco',
    "debts_title": "Dívidas",
    "no_transactions_yet": 'Ainda não possui movimentos registrados',
    "searchbar_placeholder": 'Buscar transações',
    "searching_footer_text": "Buscar",
    "searching_no_results_text": "Não há resultados para a sua pesquisa",
    "add_transaction": 'Nova transação',
    "today_date": "Hoje",
    "yesterday_title": "Ontem",
    "my_doubts": "Minhas dívidas",
    "error_message": "No momento estamos sincronizando seus movimentos, tente novamente mais tarde.",
    "paginator_button_text": "Ver mais",
    "services_menu_new": 'Novo'
  }
};
