import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { actions as globalActions, globalUiLoaderSelector } from "@config/store";
import styles from "./income.module.sass";
import { INCOME_HEADER_TITLE } from "@modules/income/constants";
import {
  actions,
  incomeUiSelector,
} from "@modules/income/store";
import { INCOME_TRANSACTION_TYPE_ID, ROUTES, UXCAM_SCREENS } from "@constants";
import { setUXCamScreen } from "@utils/webview";
import Clevertap, { MOVEMENT_START } from '@config/clevertap';
import IncomeRouter from "@modules/income/routers";
import UiProgressBar from "@ui/ui-progress-bar";
import UiFooter from "@ui/ui-footer";
import useIncomeFetch from "@modules/income/hooks/use-income-fetch/use-income-fetch";
import UiSteps from "@ui/ui-steps";
import IncomeTourTooltip from "./components/income-tour-tooltip";

interface IncomeProps { }

const Income: FC<IncomeProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(globalUiLoaderSelector);
  const { step, footer } = useSelector(incomeUiSelector);
  const { t } = useTranslation();
  const { createNewIncome } = useIncomeFetch();

  useEffect(() => {
    setUXCamScreen(UXCAM_SCREENS.income)
  }, []);

  useEffect(() => {
    Clevertap.pushEvent(MOVEMENT_START, { type: INCOME_TRANSACTION_TYPE_ID });
  }, []);

  useEffect(() => {
    dispatch(actions.restartState());
  }, [dispatch]);

  useEffect(() => {
    dispatch(globalActions.header({
      title: t(INCOME_HEADER_TITLE),
      routeOnRightClick: ROUTES.home
    }));
  }, [dispatch, t]);

  useEffect(() => {
    return () => { dispatch(actions.setStep({ currentStep: 1 })) }
  }, [dispatch]);

  const handleNextButtonClick = useCallback((): void => {
    if (footer.hasSubmitFunction) createNewIncome();
    else navigate(String(footer.routeToNext));
  }, [createNewIncome, footer, navigate]);

  const handleBackButtonClick = useCallback((): void => {
    navigate(String(footer.routeToBack));
  }, [footer.routeToBack, navigate]);

  return (
    <div className={styles.container}>
      {step.totalSteps && <UiProgressBar currentStep={step.currentStep || 0} totalSteps={step.totalSteps} />}

      <div className={styles.steps}>
        <UiSteps
          step={t("general.step", { current: step.currentStep, total: step.totalSteps })}
          stepName={t(`${step.stepName}`)}
        />
      </div>

      <IncomeRouter />

      <IncomeTourTooltip />

      <UiFooter
        isDisabled={footer.isDisabled}
        onLeftClick={handleBackButtonClick}
        onRightClick={handleNextButtonClick}
        isLoading={isLoading}
        currentStep={step.currentStep}
        totalSteps={step.totalSteps}
        nextButtonText="Bolsillo.ingreso.footerbutton1.title1"
        finalButtonText="Bolsillo.ingreso.footerbutton2.title1"
      />
    </div>
  );
};

export default Income;
