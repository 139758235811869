import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as globalActions, globalUserSelector } from "@config/store";
import { fetchGetTransactions } from "@services";
import { actions, homeUiFiltersSelector } from "@modules/home/store";
import { DEFAULT_TRANSACTIONS_PER_PAGE } from "@constants";

const useRemoveFilters = () => {
  const dispatch = useDispatch();
  const user = useSelector(globalUserSelector);
  const isFilterActive = useSelector(homeUiFiltersSelector);

  useEffect(() => {
    return () => {
      if (isFilterActive) {
        fetchGetTransactions(user.storeId, { search: '', limit: DEFAULT_TRANSACTIONS_PER_PAGE, skip: 0 })
          .then((data) => {
            dispatch(globalActions.setTransactionsResponse(data));
            dispatch(actions.resetFilters());
          })
          .catch(() => false)
      }
    }
  }, [dispatch, isFilterActive, user.storeId]);

}

export default useRemoveFilters;
