import nequiImage from '@img/nequi.png';
import daviplataImage from '@img/daviplata.png';
import bancolombiaImage from '@img/bancolombia.png';

import { ROUTES } from '@constants'
import { StepSection } from '@entities';
import { IncomeDataState } from '../store';

export const INCOME_HEADER_TITLE = 'Bolsillo.ingreso.topbar.title';
export const AMOUNT_NAME = 'Bolsillo.ingreso.subtitle1';
export const PAYMENT_METHOD_TITLE = 'Bolsillo.ingreso.subtitle2';

export const DEFAULT_TOTAL_STEPS = 2;

export const INCOME_AMOUNT_STEP: StepSection = {
  totalSteps: DEFAULT_TOTAL_STEPS,
  currentStep: 1,
  stepName: AMOUNT_NAME
};

export const INCOME_PAYMENT_METHOD_STEP: StepSection = {
  totalSteps: DEFAULT_TOTAL_STEPS,
  currentStep: 2,
  stepName: PAYMENT_METHOD_TITLE
};

export const TABS = {
  home: ROUTES.empty,
  paymentMethod: 'payment_method',
};

export const PAYMENT_METHODS = [
  { id: '1', name: 'income.payment_method.money_cash', icon: 'icon-money-cash' },
  { id: '2', name: 'Nequi', image: nequiImage },
  { id: '3', name: 'Daviplata', image: daviplataImage },
  { id: '4', name: 'Bancolombia QR', image: bancolombiaImage },
  { id: '5', name: 'income.payment_method.card', icon: 'icon-credit-card' },
];

export const EMPTY_STATE: IncomeDataState = {
  currentPaymentMethodId: ROUTES.empty,
  currentAmount: '',
};


