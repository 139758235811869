import { ChangeEvent, FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { actions as globalActions, globalBalancesSelector } from '@config/store';
import { actions, baseNavigationSelector, currentAmountSelector } from "@modules/base/store";
import { TABS, BASE_AMOUNT_TWO_STEPS, BASE_AMOUNT_ONE_STEPS, BASE_HEADER_TITLE } from "@modules/base/constants";
import useInputFieldValidation from '@hooks/use-input-field-validation';
import styles from "./base-amount.module.sass";
import UiInputMoney from "@ui/ui-input-money";

interface BaseAmountProps { }

const BaseAmount: FC<BaseAmountProps> = () => {
  const dispatch = useDispatch();
  const currentAmount = useSelector(currentAmountSelector);
  const { t } = useTranslation();
  const balances = useSelector(globalBalancesSelector);
  const { hasError, validateInputMoney } = useInputFieldValidation();
  const escapeRoute = useSelector(baseNavigationSelector);

  useEffect(() => {
    dispatch(globalActions.header({
      title: t(BASE_HEADER_TITLE),
      hasRightClick: true,
      routeOnRightClick: escapeRoute,
      hasBackClick: false,
      routeOnBackClick: escapeRoute
    }));
  }, [dispatch, escapeRoute, t])

  useEffect(() => {
    dispatch(actions.setStep(balances.hasBase ? BASE_AMOUNT_TWO_STEPS : BASE_AMOUNT_ONE_STEPS));
  }, [balances.hasBase, dispatch]);

  useEffect(() => {
    dispatch(
      actions.setFooter({
        routeToNext: `${balances.hasBase && TABS.reason}`,
        routeToBack: escapeRoute,
        isDisabled: !currentAmount || hasError,
        hasSubmitFunction: !balances.hasBase,
      }));
  }, [balances.hasBase, currentAmount, dispatch, escapeRoute, hasError]);

  const handleInputChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>): void => {
    validateInputMoney(+target.value, t('base_module.transaction_name'));
    dispatch(actions.setCurrentAmount(target.value));
  }, [dispatch, t, validateInputMoney]);

  return (
    <div className={styles.wrapper}>

      <UiInputMoney
        name="amount"
        hasAutoFocus
        value={currentAmount}
        onChange={handleInputChange}
        hasError={hasError}
      />

    </div>
  );
};

export default BaseAmount;
