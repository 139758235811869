import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TransactionType } from '@constants';
import { UiMoneyValueProps } from '@entities';
import { boxClosureEditionUiSelector, boxClosureFormSelector } from '@modules/box-closure/store';
import styles from './box-closure-home-resume.module.sass';
import UiMoneyValue from '@ui/ui-money-value';
import useFormEdition from '@modules/box-closure/hooks/use-form-edition';
import { BASE_EDITION_ID, EXPENSE_EDITION_ID, INCOME_EDITION_ID, NONE_EDITION_ID } from '@modules/box-closure/constants';
import { EditionTypeId } from '@modules/box-closure/entities';

interface ListItem {
  text: string;
  moneyValueProps: UiMoneyValueProps;
  transactionId?: number;
  propName?: string;
  editionId: EditionTypeId;
}

interface BoxClosureHomeResumeProps { }

const BoxClosureHomeResume: FC<BoxClosureHomeResumeProps> = () => {
  const { t } = useTranslation();
  const form = useSelector(boxClosureFormSelector);
  const activeEditionId = useSelector(boxClosureEditionUiSelector);
  const { handleValueChange, openEdition, isEditionActive } = useFormEdition();

  const balances: ListItem[] = [
    {
      text: 'Bolsillo.caja.list.subtitle1',
      moneyValueProps: { value: form.incomes, type: TransactionType.income },
      propName: Object.keys(form)[0],
      editionId: INCOME_EDITION_ID
    },
    {
      text: 'Bolsillo.caja.list.subtitle2',
      moneyValueProps: { value: form.expenses, type: TransactionType.expense },
      propName: Object.keys(form)[1],
      editionId: EXPENSE_EDITION_ID
    },
    {
      text: 'Bolsillo.caja.list.subtitle3',
      moneyValueProps: { value: form.base, type: TransactionType.base },
      propName: Object.keys(form)[2],
      editionId: BASE_EDITION_ID
    },
  ];

  const fixedBalances: ListItem[] = [
    {
      text: 'Bolsillo.caja.list.subtitle4',
      moneyValueProps: { value: form.loans, type: TransactionType.loan },
      editionId: NONE_EDITION_ID
    },
    // { text: 'debts', moneyValueProps: { value: form.debts, type: TransactionType.expense } },
  ];

  const isEditing = (id: EditionTypeId) => activeEditionId === id;

  const handleEditingActivation = (id: EditionTypeId) => {
    if (!isEditionActive) openEdition(id);
  }

  return (
    <div className={styles.wrapper}>
      {balances.map((item, i) => (
        <div key={`balance-item-${item.text}`} className={styles.item}>

          <div className={styles.left}>
            <span className={styles.text}>
              {t(item.text)}
            </span>

            <UiMoneyValue
              {...item.moneyValueProps}
              isEditable={isEditing(item.editionId)}
              hasAutofocus={isEditing(item.editionId)}
              onChange={(e) => handleValueChange(e, item.propName)}
            />
          </div>

          <span
            className={`${styles.edit} ${isEditing(item.editionId) ? styles.active : ""}`}
            onClick={() => handleEditingActivation(item.editionId)}
          >
            {t("Editar")}
          </span>
        </div>
      ))}

      {fixedBalances.map((item) => (
        <div key={`balance-item-${item.text}`} className={styles.item}>

          <div className={styles.left}>
            <span className={styles.text}>
              {t(item.text)}
            </span>

            <UiMoneyValue {...item.moneyValueProps} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default BoxClosureHomeResume;
