import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { boxClosureDetailSelector } from '@modules/box-closure/store';
import styles from './box-closure-detail-resumen.module.sass';
import BoxClosureDetailResumeList from '../box-closure-detail-resume-list';
import UiDivider from '@ui/ui-divider';

interface BoxClosureDetailResumenProps { }

const BoxClosureDetailResumen: FC<BoxClosureDetailResumenProps> = () => {
  const { t } = useTranslation();
  const boxClosureDetail = useSelector(boxClosureDetailSelector);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t('box_closure.resume')}</div>

      <BoxClosureDetailResumeList resume={boxClosureDetail.resume.income} title='income' />

      <UiDivider isSmall />

      <BoxClosureDetailResumeList resume={boxClosureDetail.resume.egress} title='expenses' />
    </div>
  )
}

export default BoxClosureDetailResumen;
