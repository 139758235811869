import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions, expensesUiSelector } from './store';
import { Expenses as EXPENSES } from './constants';
import { setUXCamScreen } from '@utils/webview';
import Clevertap, { MOVEMENT_START } from '@config/clevertap';
import { EXPENSE_TRANSACTION_TYPE_ID, UXCAM_SCREENS } from '@constants';
import { globalUiLoaderSelector } from '@config/store';
import ExpensesRouter from './routers';
import styles from './expenses.module.sass';
import UiProgressBar from '@ui/ui-progress-bar';
import UiFooter from '@ui/ui-footer';
import useExpensesFetch from './hooks/use-expenses-fetch';
import UiSteps from "@ui/ui-steps";
import ExpenseTourTooltip from './components/expenses-tour-tooltip';

interface ExpensesProps { }

const Expenses: FC<ExpensesProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(globalUiLoaderSelector);
  const { step, footer } = useSelector(expensesUiSelector);
  const { createNewExpense } = useExpensesFetch();
  const { t } = useTranslation();

  useEffect(() => {
    setUXCamScreen(UXCAM_SCREENS.expense)
  }, []);

  useEffect(() => {
    Clevertap.pushEvent(MOVEMENT_START, { type: EXPENSE_TRANSACTION_TYPE_ID });
  }, []);

  useEffect(() => {
    dispatch(actions.setCurrentExpense(EXPENSES.empty));
  }, [dispatch]);

  useEffect(() => {
    return () => { dispatch(actions.setStep({ currentStep: 1 })) }
  }, [dispatch]);

  const handleNextButtonClick = useCallback((): void => {
    if (footer.hasSubmitFunction) createNewExpense();
    else navigate(String(footer.routeToNext));
  }, [createNewExpense, footer, navigate]);

  const handleBackButtonClick = useCallback((): void => {
    navigate(String(footer.routeToBack));
  }, [footer.routeToBack, navigate]);

  return (
    <div className={styles.container}>
      {step.totalSteps && <UiProgressBar currentStep={step.currentStep || 0} totalSteps={(step.totalSteps)} />}

      <div className={styles.steps}>
        <UiSteps
          step={t('general.step', { current: step.currentStep, total: step.totalSteps })}
          stepName={t(`${step.stepName}`)}
        />
      </div>

      <ExpensesRouter />

      <ExpenseTourTooltip />

      <UiFooter
        isDisabled={footer.isDisabled}
        onLeftClick={handleBackButtonClick}
        onRightClick={handleNextButtonClick}
        isLoading={isLoading}
        currentStep={step.currentStep}
        totalSteps={step.totalSteps}
        nextButtonText='Bolsillo.ingreso.footerbutton1.title1'
        finalButtonText='Bolsillo.ingreso.footerbutton4.title1'
      />
    </div>
  );
}

export default Expenses;
