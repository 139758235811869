import React, { FC, memo } from 'react';
import UiLoaderSpinner from '@ui/ui-loader-spinner';

import styles from './ui-button.module.sass';

interface UiButtonProps {
  text: string;
  isLoading?: boolean;
  type?: 'submit' | 'reset' | 'button';
  leftIcon?: string;
  size?: 'small' | 'medium' | 'large';
  isSecondary?: boolean;
  isDisabled?: boolean;
  hasBoldIcon?: boolean;
  onClick?: () => void;
}

const UiButton: FC<UiButtonProps> = ({
  text,
  type = 'button',
  leftIcon,
  size = 'medium',
  isSecondary,
  isDisabled,
  onClick,
  isLoading,
  hasBoldIcon
}) => {
  return (
    <button
      className={`
        ${styles.wrapper}
        ${size ? styles[size] : styles.large}
        ${isSecondary && styles.outline}
      `}
      disabled={isDisabled}
      type={type}
      onClick={onClick}
      data-testid='ui-button'
    >
      {leftIcon &&
        <span className={`${leftIcon} ${styles.icon} ${styles.leftIcon} ${hasBoldIcon && styles.bold}`} />
      }

      {isLoading
        ? <UiLoaderSpinner isSecondary={isSecondary} />
        : <div className={styles.text}>{text}</div>}

    </button>
  );
}

export default memo(UiButton);
