import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Transaction } from '@entities';
import { BOX_CLOSURE_TRANSACTION_TYPE_ID } from '@constants';
import getTransactionProperties from '@utils/get-transaction-properties';
import styles from './ui-transaction-card.module.sass';
import UiMoneyValue from '@ui/ui-money-value';

interface UiTransactionCardProps {
  transaction: Transaction;
  hasIcons?: boolean;
  isAnimated?: boolean;
}

const UiTransactionCard: FC<UiTransactionCardProps> = ({ transaction, hasIcons = true, isAnimated = true }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = (): void => { if (routeTo) navigate(routeTo) };

  const { icon, type, routeTo } = useMemo(() => getTransactionProperties(transaction), [transaction]);

  const subTitle = useMemo(() => (
    transaction.transactionTypeId === BOX_CLOSURE_TRANSACTION_TYPE_ID && !!transaction.createdAt
      ? transaction.createdAt.slice(-8).toUpperCase()
      : transaction.subTitle || ''
  ), [transaction.createdAt, transaction.subTitle, transaction.transactionTypeId]);

  return (
    <div
      onClick={handleClick}
      className={`
        ${styles.wrapper}
        ${styles[type]} 
        ${!hasIcons && styles.withoutIcons}
        ${!isAnimated && styles.withoutAnimation}
      `}
    >
      <span className={`icon-${icon} ${styles.icon}`} />

      <div className={styles.description}>
        <div className={styles.title}>
          {t(transaction.description)}
        </div>
        {!!subTitle && (<span className={styles.subtitle}>{subTitle}</span>)}
      </div>

      <UiMoneyValue value={transaction.total} type={type} />

      <span className={`icon-back ${styles.arrow} ${!routeTo && styles.hidden}`} />
    </div>
  );
}

export default memo(UiTransactionCard);
