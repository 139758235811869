import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Clevertap, { OB_START } from '@config/clevertap';
import { CLOSURE_ONBOARDING_ID } from '@constants';
import { globalBalancesSelector } from '@config/store';
import { boxClosureEditionUiSelector, boxClosureFormSelector } from '@modules/box-closure/store';
import styles from './box-closure-tour-tooltip.module.sass';
import useOnboarding from '@hooks/use-onboarding';
import UiTooltip from '@ui/ui-tooltip';
import { UiTooltipPositionXType } from '@ui/ui-tooltip/ui-tooltip';

interface BoxClosureTourTooltipProps { }

const position: { [key: number]: UiTooltipPositionXType } = {
  1: "right",
  2: "center",
  3: "left",
  4: "center"
}

const BoxClosureTourTooltip: FC<BoxClosureTourTooltipProps> = () => {
  const { isOnboardingDone } = useOnboarding();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeEditionId = useSelector(boxClosureEditionUiSelector);
  const [isCompletedTimeOut, setIsCompletedTimeOut] = useState(false);
  const form = useSelector(boxClosureFormSelector);
  const balances = useSelector(globalBalancesSelector);

  useEffect(() => {
    if (!isOnboardingDone(CLOSURE_ONBOARDING_ID)) {
      Clevertap.pushEvent(OB_START, { obType: CLOSURE_ONBOARDING_ID });
    }
  }, [isOnboardingDone]);

  useEffect(() => {
    const timeFn = setTimeout(() => {
      setIsCompletedTimeOut(true);
    }, 2000);
    return () => { clearTimeout(timeFn) }
  }, [dispatch]);

  const hasValuesChanged = useCallback(() => (
    form.base !== balances.base || form.incomes !== balances.income || form.expenses !== balances.egress
  ), [form, balances]);

  const currentStep = useMemo(() => {
    if (activeEditionId) return hasValuesChanged() ? 4 : 3;
    if (isCompletedTimeOut) return 2;
    return 1;
  }, [hasValuesChanged, isCompletedTimeOut, activeEditionId]);

  if (isOnboardingDone(CLOSURE_ONBOARDING_ID)) return <></>

  return (
    <div className={`${styles.wrapper} ${styles['step-' + currentStep]}`}>
      <UiTooltip
        positionY='bottom'
        positionXFixed={position[currentStep]}
      >
        <span>
          {t(`box_closure.tour.step-${currentStep}`)}
        </span>
      </UiTooltip>
    </div>
  )
}

export default BoxClosureTourTooltip;
