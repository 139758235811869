import React, { FC, useCallback } from 'react';
import { UiSelectionListItem } from '@entities';
import { useTranslation } from 'react-i18next';
import UiRadio from '@ui/ui-radio';
import styles from './ui-selection-list.module.sass';

interface UiSelectionListProps {
  list: UiSelectionListItem[],
  selected: string | number,
  onItemClick: (item: UiSelectionListItem) => void,
}

const UiSelectionList: FC<UiSelectionListProps> = ({
  list,
  selected,
  onItemClick,
}) => {

  const { t } = useTranslation();

  const isChecked = useCallback((id): boolean => {
    return selected === id
  }, [selected]);

  const handleRadioClick = useCallback((item): void => {
    onItemClick(item);
  }, [onItemClick]);

  return (
    <div className={styles.wrapper}>
      {list.map(item => (
        <div 
          className={styles.item} 
          key={`selection-list-${item.name}`} 
          onClick={() => handleRadioClick(item)}
        >
          {item.imageUrl 
            ?  <img src={item.imageUrl} alt={item.name} className={styles.image} />  
            :  <span className={`${item.icon} ${styles.icon}`} />
          }
          <span className={styles.name}>{t(item.name)}</span>
          <UiRadio
            isChecked={isChecked(item.id)}
            onChange={() => handleRadioClick(item)}
          />
        </div>
      ))}
    </div>
  )
}

export default UiSelectionList;