import React, { FC } from 'react';
import UiSquareButtonIcon from '@ui/ui-square-button-icon';
import styles from './ui-onboarding-tooltip.module.sass';

interface UiOnboardingTooltipProps {
  title: string;
  text: string;
  onCloseClick: () => void;
  onNextClick: () => void;
  onBackClick: () => void;
  totalSteps?: number;
  currentStep?: number;
  positionY: 'top' | 'bottom';
  positionX: 'left' | 'right' | 'none';
  hasFirstStepValidation?: boolean;
}

const UiOnboardingTooltip: FC<UiOnboardingTooltipProps> = ({
  title,
  text,
  onCloseClick,
  onNextClick,
  onBackClick,
  totalSteps = 1,
  currentStep = 1,
  positionX,
  positionY,
  hasFirstStepValidation
}) => {
  const hideBackButton = () => currentStep === 1 && hasFirstStepValidation;

  const getTotalSteps = () => totalSteps === 1 ? 0 : totalSteps;

  const isLastStep = () => totalSteps === currentStep;

  return (
    <div className={styles.wrapper} data-testid='onboarding-tooltip'>

      <div className={styles.top}>
        <span className={styles.title}>{title}</span>
        <span
          className={`icon-close ${styles.close}`}
          onClick={onCloseClick}
          data-testid='close-onboarding-tooltip'
        />
      </div>

      <div className={styles.text}>{text}</div>

      <div className={styles.bottom}>
        <div className={styles.steps}>
          {Array(getTotalSteps()).fill(1).map((_, index) => (
            <span className={`${styles.step} ${(index + 1) === currentStep && styles.active}`} key={`ob-step-${index}`} />
          ))}
        </div>

        {!hideBackButton() &&
          <UiSquareButtonIcon icon='icon-arrow-1' onClick={onBackClick} isSecundary />
        }

        <div className={`${styles.button} ${!isLastStep() && styles.rotate}`}>
          <UiSquareButtonIcon icon={`icon-${isLastStep() ? 'check' : 'arrow-1'}`} onClick={onNextClick} />
        </div>
      </div>

      <span className={`${styles.triangle} ${styles[positionX]} ${styles[positionY]}`} />
    </div>
  )
}

export default UiOnboardingTooltip;
