import React, { FC, memo, useEffect, useRef } from 'react';
import { es, ptBR } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { format, isToday, isYesterday } from 'date-fns';
import { TransactionsByDate } from '@entities';
import { TRANSACTIONS_PER_PAGE } from '@constants';
import styles from './ui-transactions-list.module.sass';
import UiTransactionCard from '@ui/ui-transaction-card';
import UiDelayedComponent from '@ui/ui-delayed-component';

interface UiTransactionsListProps {
  transactions: TransactionsByDate[];
  isSpecific?: boolean;
  withoutAnimation?: boolean;
}

const UiTransactionsList: FC<UiTransactionsListProps> = ({ transactions, isSpecific = false, withoutAnimation }) => {
  const { t, i18n } = useTranslation();
  const displayIndex = useRef(1);
  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const getDate = (date: string): string => {
    const transactionDate = new Date(date);
    if (isToday(transactionDate)) return t('dashboard.today_date');
    return isYesterday(transactionDate)
      ? t('dashboard.yesterday_title')
      : format(transactionDate, 'dd MMM yyyy', { locale: i18n.language === 'br' ? ptBR : es })
  }

  const getDelay = (groupIndex: number, transactionIndex: number): number => {
    if (withoutAnimation) return 0;
    if (isFirstRender.current) {
      let lastGroupPosition = 0;
      for (let i = 0; i < groupIndex; i++) { lastGroupPosition += transactions[i].transactionsByDate.length }
      return (transactionIndex + lastGroupPosition) * 100;
    }
    const delay = displayIndex.current;
    displayIndex.current = (displayIndex.current === TRANSACTIONS_PER_PAGE) ? 1 : displayIndex.current + 1
    return delay * 100;
  }

  const getDateDelay = (index: number): number => {
    return isFirstRender.current ? getDelay(index, 0) : 0;
  }

  return (
    <div>
      {transactions.map((transactionsGroup, i) => (
        <div className={styles.transactions} key={`date-item-${transactionsGroup.date}`}>

          <div className={styles.date} style={{ animationDelay: `${(getDateDelay(i))}ms` }}>
            {getDate(transactionsGroup.date)}
          </div>

          {transactionsGroup.transactionsByDate.map((transaction, j) => (
            <UiDelayedComponent
              timeToDelay={getDelay(i, j + 1)}
              key={`transaction-item-${transaction.id}-${j}${i}`}
            >
              <UiTransactionCard transaction={transaction} hasIcons={!isSpecific} isAnimated={!withoutAnimation} />
            </UiDelayedComponent>
          ))}
        </div>
      ))}
    </div>
  );
}

export default memo(UiTransactionsList);
