import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@modules/expenses/store';
import { Expenses, PROVIDER_AMOUNT_STEP, PROVIDER_TABS } from '@modules/expenses/constants';
import InputAmount from '@modules/expenses/components/input-amount';
import { ROUTES } from '@constants';

interface ProviderExpensesAmountProps { }

const ProviderExpensesAmount: FC<ProviderExpensesAmountProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setStep(PROVIDER_AMOUNT_STEP));
    dispatch(actions.setFooter({
      routeToNext: ROUTES.home,
      routeToBack: `${Expenses.provider}/${PROVIDER_TABS.name}`,
      hasSubmitFunction: true,
    }));
    return () => { dispatch(actions.setFooter({ hasSubmitFunction: false })); }
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.setSelectionSuggestion({ hasSearching: false, showList: false, isSelected: false }));
  }, [dispatch]);

  return (
    <InputAmount />
  )
}

export default ProviderExpensesAmount;
