import { memo, FC } from 'react';
import styles from "./ui-square-button-icon.module.sass";

interface UiSquareButtonIconProps {
  icon: string;
  isDisabled?: boolean;
  onClick?: () => void;
  isSecundary?: boolean;
}

const UiSquareButtonIcon: FC<UiSquareButtonIconProps> = ({
  icon,
  onClick,
  isDisabled,
  isSecundary
}) => {
  return (
    <div>
      <button
        className={`${styles.button} ${isDisabled && styles.disabled} ${isSecundary && styles.secundary}`}
        onClick={onClick}
        data-testid='ui-square-button'
        disabled={isDisabled}
      >
        <span className={`${icon} ${styles.icon}`} />
      </button>
    </div>
  );
}

export default memo(UiSquareButtonIcon);
