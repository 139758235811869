import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TABS } from '@modules/home/constants';
import { homeFilterResultsSelector, homeUiFiltersSelector } from '@modules/home/store';
import styles from './home-dashboard-movements.module.sass';
import TransactionsListWrapper from '../home-transactions-list-wrapper';

interface HomeDashboardMovementsProps { }

const HomeDashboardMovements: FC<HomeDashboardMovementsProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isFilterActive = useSelector(homeUiFiltersSelector);
  const homeFilter = useSelector(homeFilterResultsSelector);

  const handleSearchClick = () => navigate(TABS.search);

  const handleFiltersClick = () => navigate(TABS.filters);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span className={styles.text}>
          {t('Bolsillo.dashboard.title2')}
        </span>

        <span className={`icon-search ${styles.icon}`} onClick={handleSearchClick} data-testid='icon-search' />

        <span className={`icon-filters ${styles.icon}`} onClick={handleFiltersClick} data-testid='icon-filters'>
          {isFilterActive && <div className={styles.point} />}
        </span>

      </div>

      {isFilterActive && (
        <span className={styles.total}>
          {t('home.filters.total_results', { total: homeFilter.totalTransactions })}
        </span>
      )}

      <TransactionsListWrapper />
    </div>
  );
}

export default HomeDashboardMovements;
