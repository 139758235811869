import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LOAN_TRANSACTION_TYPE_ID } from '@constants';
import { Customer } from '@modules/customers/entities';
import UiTransactionCard from '@ui/ui-transaction-card';
import styles from './customers-sub-list.module.sass';

interface CustomersSubListProps {
  customers: Customer[];
}

const CustomersSubList: FC<CustomersSubListProps> = ({ customers }) => {
  const { t } = useTranslation();

  return (
    <>
      {customers.map(customer =>
        <div className={styles.wrapper} key={`customer-${customer.customerId}`}>
          <UiTransactionCard
            transaction={{
              id: customer.customerId,
              total: customer.totalDebt,
              description: customer.name,
              subTitle: customer.lastPaidLoan || t('customers.without_transactions_card_subtitle'),
              customerId: customer.customerId,
              imageUrl: '',
              paymentMethodId: 0,
              transactionTypeId: LOAN_TRANSACTION_TYPE_ID,
            }}
            isAnimated={false}
          />
        </div>
      )}
    </>
  )
}

export default CustomersSubList;
