import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { STEPS_MAP_V2 } from '@modules/customers/constants';
import { customersUiSelector } from '@modules/customers/store';
import useOnboarding from '@hooks/use-onboarding';
import { CONTACT_LOAN_ONBOARDING_ID, CUSTOMER_CREATION_ONBOARDING_ID, FIRST_LOAN_ONBOARDING_ID } from '@constants';
import Clevertap, { OB_START } from '@config/clevertap';
import styles from './customers-tour-tooltip.module.sass';
import UiTooltip from '@ui/ui-tooltip';

interface CustomersTourTooltipProps {
  obId: number;
  name?: string;
  phone?: string;
  amount?: string;
}

const CustomersTourTooltip: FC<CustomersTourTooltipProps> = ({ obId, name = '', phone = '', amount = '' }) => {
  const { isOnboardingDone } = useOnboarding();
  const { isCreationOb } = useSelector(customersUiSelector);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isOnboardingDone(obId)) {
      Clevertap.pushEvent(OB_START, { obType: obId });
    }
  }, [isOnboardingDone, obId]);

  const getCreationObStep = useCallback(() => {
    if (name.length < 4) return STEPS_MAP_V2[1][1]
    return (phone.length < +t('country.phone_length')) ? STEPS_MAP_V2[1][2] : STEPS_MAP_V2[1][3];
  }, [name, phone, t]);

  const currentStep = useMemo(() => {
    switch (obId) {
      case CUSTOMER_CREATION_ONBOARDING_ID: return getCreationObStep()
      case CONTACT_LOAN_ONBOARDING_ID:
      case FIRST_LOAN_ONBOARDING_ID: return !amount ? STEPS_MAP_V2[2][1] : STEPS_MAP_V2[2][2];
      default: return !amount ? STEPS_MAP_V2[3][1] : STEPS_MAP_V2[3][2];
    }
  }, [obId, getCreationObStep, amount]);

  if (!isCreationOb && isOnboardingDone(obId)) return <> </>;

  return (
    <div className={`${styles.wrapper} ${styles[currentStep!.distance]}`}>
      <UiTooltip
        positionY={currentStep!.tootltip.y}
        positionXFixed={currentStep!.tootltip.x}
      >
        <span className={styles.text}>{t(currentStep!.tootltip.title)}</span>
      </UiTooltip>
    </div>
  )
}

export default memo(CustomersTourTooltip);
