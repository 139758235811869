import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@modules/expenses/store';
import { ROUTES } from '@constants';
import { actions as globalActions } from '@config/store';
import { Expenses, PROVIDER_PAYMENT_METHOD_STEP, PROVIDER_TABS } from '@modules/expenses/constants';
import PaymentMethods from '../../components/payment-methods';
import useProviderExpense from '@modules/expenses/hooks/use-provider-expense';

interface ProviderExpensesPaymentMethodProps { }

const ProviderExpensesPaymentMethod: FC<ProviderExpensesPaymentMethodProps> = () => {
  const dispatch = useDispatch();
  const { getMostUseAndRecentProvider } = useProviderExpense();

  useEffect(() => {
    getMostUseAndRecentProvider();
  }, [getMostUseAndRecentProvider])

  useEffect(() => {
    dispatch(actions.setStep(PROVIDER_PAYMENT_METHOD_STEP));
    dispatch(actions.setFooter({
      routeToNext: `${Expenses.provider}/${PROVIDER_TABS.name}`,
      routeToBack: ROUTES.expenses,
    }));
    dispatch(globalActions.header({ hasRightClick: true }));
  }, [dispatch]);

  return (
    <PaymentMethods />
  )
}

export default ProviderExpensesPaymentMethod;
