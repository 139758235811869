import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@constants';
import CustomersForm from '@modules/customers/components/customers-form';
import useCustomerFormSubmit from '@modules/customers/hooks/use-customer-form-submit';

interface CustomersCreationProps { }

const CustomersCreation: FC<CustomersCreationProps> = () => {
  const { t } = useTranslation();
  const { createNewCustomer } = useCustomerFormSubmit();

  return (
    <CustomersForm
      header={{
        title: t('Bolsillo.crearCliente.topBar.titulo1'),
        hasRightClick: true,
        hasBackClick: false,
        routeOnRightClick: ROUTES.customers
      }}
      onSubmit={createNewCustomer}
    />
  );
}

export default CustomersCreation;
