import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { customersSearchSelector } from "@modules/customers/store";
import { Customer } from "@modules/customers/entities";
import styles from "./customers-home-list.module.sass";
import CustomersEmptyState from "../customers-empty-state";
import CustomersSubList from "../customers-sub-list";
import UiDivider from "@ui/ui-divider";

interface CustomersHomeListProps {
  customersList: Customer[];
}

const CustomersHomeList: FC<CustomersHomeListProps> = ({ customersList }) => {
  const { t } = useTranslation();
  const searchValue = useSelector(customersSearchSelector);

  const [customersWithDebt, customersWithoutDebt] = useMemo(() => {
    const withDebt = [];
    const withoutDebt = [];

    for (const item of customersList) {
      if (!!item.totalDebt) withDebt.push(item);
      else withoutDebt.push(item);
    }

    return [withDebt, withoutDebt];
  }, [customersList]);

  const hasNoResults = () => (
    !!searchValue && !customersWithDebt.length && !customersWithoutDebt.length
  );

  if (hasNoResults()) return <div />

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {t("Bolsillo.misClientes.listaMisClientes.titulo1")}
      </div>

      {!customersList.length ? (
        <CustomersEmptyState
          title={t("Bolsillo.misClientes.listaMisclientes.subTitulo1")}
          text={t("Bolsillo.misClientes.listaMisclientes.cuerpo1")}
        />
      ) : (
        <>
          <CustomersSubList customers={customersWithDebt} />

          {(!!customersWithDebt.length && !!customersWithoutDebt.length) && (
            <div className={styles.divider}>
              <UiDivider isSmall isDarker />
            </div>
          )}

          <CustomersSubList customers={customersWithoutDebt} />
        </>
      )}
    </div>
  )
}

export default CustomersHomeList;
