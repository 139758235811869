import React, { FC, useCallback, useEffect } from 'react';
import styles from './ui-notification.module.sass';
import { TIME_TO_HIDE_NOTIFICATION } from '@constants';
import { actions, globalUiNotificationSelector } from '@config/store';
import { useDispatch, useSelector } from 'react-redux';

interface UiNotificationProps { }

const UiNotification: FC<UiNotificationProps> = () => {
  const dispatch = useDispatch();
  const { show, message, state } = useSelector(globalUiNotificationSelector);
  const successIconStyle = `${styles.success} icon-check-circle`;
  const alertIconStyle = `${styles.alert} icon-alert-circle`;

  const hideNotification = useCallback(() => dispatch(actions.notification({ show: false })), [dispatch]);

  useEffect(() => {
    let timeoutFunction: NodeJS.Timeout;
    if (show) timeoutFunction = setTimeout(() => hideNotification(), TIME_TO_HIDE_NOTIFICATION);
    return () => { if (timeoutFunction) clearTimeout(timeoutFunction) }
  }, [hideNotification, show]);

  return (
    <div
      className={`${styles.wrapper} ${show && styles.show} ${styles[state]}`}
      onClick={hideNotification}
      data-testid='hide-notification'
    >
      <span
        className={`
          ${styles.icon}
          ${state === 'success' && successIconStyle}
          ${state === 'alert' && alertIconStyle}
        `}
      />
      <span className={styles.message}>
        {message}
      </span>
    </div>
  );
}

export default UiNotification;
