export const REG_EXP_ONLY_LETTERS = RegExp(/^[^\d\s][a-z|A-ZA-ÿ\s]*$/);
export const REG_EXP_ONLY_NUMBERS = RegExp(/^[0-9]+$/);
export const REG_EXP_NO_ONLY_SPACES = RegExp(/^\S/);

export const PHONE_LENGTH = 10;
export const BRAZIL_PHONE_LENGTH = 11;
export const IDENTIFICATION_LENGTH = 18;
export const PASSWORD_LENGTH = 10;
export const USER_NUMBER_LENGTH = 10;
export const NAME_LENGTH = 25;
export const EXPENSE_NAME_LENGTH = 35;
export const BASE_REASON_LENGTH = 20;
export const CUSTOMER_NAME_LENGTH = NAME_LENGTH;

export const MAX_AMOUNT = 100000000;
export const MIN_AMOUNT = 0.01;
