import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import UiHeader from '@ui/ui-header';

interface HeaderOutletProps { }

const HeaderOutlet: FC<HeaderOutletProps> = () => {

  return (
    <>
      <UiHeader />
      <Outlet />
    </>
  );
}

export default HeaderOutlet;
