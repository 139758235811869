import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, mostRecentProvidersSelector, expensesUiSelector } from "@modules/expenses/store";
import { fetchSearchProvider, fetchProviderSuggestions, fetchDeleteMostRecentProvider, fetchOthersType, fetchSearchOthersType, fetchDeleteLastOthersTypeSuggestion } from "@modules/expenses/services";
import { CUSTOM_OTHERS_EXPENSE_ID, CUSTOM_PROVIDER_EXPENSE_ID, PROVIDER_EXPENSE_ID } from "@modules/expenses/constants";
import { SearchedProvider, TypeSuggestion } from "@modules/expenses/entities";
import { globalUserSelector } from "@config/store";

const useProviderExpense = () => {
  const dispatch = useDispatch();
  const user = useSelector(globalUserSelector);
  const mostRecentProviders = useSelector(mostRecentProvidersSelector);
  const { selectionSuggestion } = useSelector(expensesUiSelector);

  const getSearchProviderList = useCallback((storeId: number, inputValue: string): void => {
    fetchSearchProvider(storeId, inputValue)
      .then((data) => {
        dispatch(actions.setSearchProvidersResults(data));
        dispatch(actions.setHasSearchedProviders(true));
      });
  }, [dispatch]);

  const getMostUseAndRecentProvider = useCallback((): void => {
    fetchProviderSuggestions(user.storeId)
      .then(({ mostRecent, mostUsed }) => {
        dispatch(actions.setMostUsedProviders(mostUsed))
        dispatch(actions.setMostRecentProviders(mostRecent))
      });
  }, [dispatch, user]);

  const setProviderSelection = useCallback((provider: SearchedProvider, isCustomProvider: boolean): void => {
    if (isCustomProvider) {
      dispatch(actions.setIsCustomProviderExpense(true));
      dispatch(actions.setCurrentMacroEgress(CUSTOM_PROVIDER_EXPENSE_ID));
    } else {
      dispatch(actions.setIsCustomProviderExpense(false));
      dispatch(actions.setCurrentMacroEgress(PROVIDER_EXPENSE_ID));
      dispatch(actions.setCurrentMicroEgressTypeId(provider.id || 0));
    }
    dispatch(actions.setCurrentMicroEgressName(provider.name));
    dispatch(actions.setSelectionSuggestion({
      isSelected: true,
      showList: false,
      hasSearching: false,
    }));
    dispatch(actions.setProviderSearchbarStatus(selectionSuggestion.hasSearching ? 'toBottom' : 'init'));
  }, [dispatch, selectionSuggestion.hasSearching]);

  const deleteMostRecentProvider = useCallback((providerId: number): void => {
    const providers = mostRecentProviders.filter((item) => item.id !== providerId);
    dispatch(actions.setMostRecentProviders(providers));

    fetchDeleteMostRecentProvider(providerId, user.storeId)
      .then((data) => {
        dispatch(actions.setMostRecentProviders(data));
      });
  }, [dispatch, user, mostRecentProviders]);

  const getOthersTypeSuggestions = useCallback((): void => {
    fetchOthersType(user.storeId)
      .then(({ lastUsed, suggestions }) => {
        dispatch(actions.setOtherTypeLastCreated(lastUsed));
        dispatch(actions.setOthersTypeSuggestionsList(suggestions));
      });
  }, [dispatch, user]);

  const getSearchOthersTypeList = useCallback((storeId: number, inputValue: string): void => {
    fetchSearchOthersType(storeId, inputValue)
      .then((data) => {
        dispatch(actions.setOthersTypeSuggestionsResults(data));
      });
  }, [dispatch]);

  const setOthersTypeSelection = useCallback((type: TypeSuggestion, isCustomOthersType: boolean): void => {
    if (isCustomOthersType) {
      dispatch(actions.setIsCustomProviderExpense(true));
      dispatch(actions.setCurrentMacroEgress(CUSTOM_OTHERS_EXPENSE_ID));
    } else {
      dispatch(actions.setIsCustomProviderExpense(false));
      dispatch(actions.setCurrentMacroEgress(type.macroEgressTypeId));
      dispatch(actions.setCurrentMicroEgressTypeId(type.id || 0));
    }
    dispatch(actions.setCurrentMicroEgressName(type.name));
    dispatch(actions.setSelectionSuggestion({
      isSelected: true,
      showList: false,
      hasSearching: false,
    }));
  }, [dispatch]);

  const deleteLastOthersTypeSuggestion = useCallback((suggestionId: number): void => {
    dispatch(actions.setOtherTypeLastCreated({} as TypeSuggestion));

    fetchDeleteLastOthersTypeSuggestion(suggestionId, user.storeId)
      .then((data) => {
        dispatch(actions.setOtherTypeLastCreated(data));
      });
  }, [dispatch, user]);

  return {
    getSearchProviderList,
    getMostUseAndRecentProvider,
    setProviderSelection,
    setOthersTypeSelection,
    deleteMostRecentProvider,
    getOthersTypeSuggestions,
    getSearchOthersTypeList,
    deleteLastOthersTypeSuggestion,
  }
};

export default useProviderExpense;
