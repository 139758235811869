import React, { ChangeEvent, FC, memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Header } from '@entities';
import flag_co from '@img/flag-co.png';
import flag_mx from '@img/flag-mx.png';
import flag_br from '@img/flag-br.png';
import { actions as globalActions, globalUiLoaderSelector } from '@config/store';
import { customersFormSchema } from '@modules/customers/schemas';
import { CustomerSimpleForm } from '@modules/customers/entities';
import { CUSTOMER_CREATION_ONBOARDING_ID } from '@constants';
import { t } from 'i18next';
import styles from './customers-form.module.sass';
import UiInputField from '@ui/ui-input-field';
import UiButton from '@ui/ui-button';
import CustomersTourTooltip from '../customers-tour-tooltip';

const flags = {
  co: flag_co,
  mx: flag_mx,
  br: flag_br
}

interface CustomersFormProps {
  header: Partial<Header>;
  onSubmit: (form: CustomerSimpleForm) => void;
  initialValues?: CustomerSimpleForm;
}

const CustomersForm: FC<CustomersFormProps> = ({
  header, onSubmit, initialValues = { name: '', phone: '', areaCode: t('country.area_code') }
}) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const isLoading = useSelector(globalUiLoaderSelector);
  const debounceFunction = useRef<NodeJS.Timeout>({} as NodeJS.Timeout)

  useEffect(() => {
    dispatch(globalActions.header(header))
  }, [dispatch, header]);

  const form = useFormik({
    initialValues,
    onSubmit,
    validationSchema: customersFormSchema(+t('country.phone_length'))
  });

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (debounceFunction.current) clearTimeout(debounceFunction.current);
    debounceFunction.current = setTimeout(() => {
      if (event.target.value.length < +t('country.phone_length') && !isNaN(+event.target.value)) {
        form.setFieldError('phone', t('Bolsillo.crearCliente.textfieldError.texto1', { value: t('country.phone_length') }))
      }
    }, 800)
    form.handleChange(event)
  }

  const isDisabledSubmit = () => (
    !form.isValid || !form.dirty
    || !form.values.name || form.values.phone.length < +t('country.phone_length')
  );

  return (
    <>
      <form className={styles.wrapper} onSubmit={form.handleSubmit}>
        <UiInputField
          label={t('Bolsillo.crearCliente.textfield.texto1')}
          name='name'
          placeholder={t('customers.form_field_name_placeholder')}
          hasAutoFocus
          value={form.values.name}
          onChange={form.handleChange}
          hasError={!!form.errors.name}
          errorMessage={form.errors.name}
        />

        <div className={`${styles.phoneField} ${!!form.errors.phone && styles.hasError}`}>
          <div className={styles.flagWrapper}>
            <img src={flags[i18n.language as keyof object]} alt="flag" className={styles.img} />
            {t('country.area_code')}
          </div>

          <UiInputField
            label={t('Bolsillo.crearCliente.textfield.texto2')}
            name='phone'
            type='tel'
            withoutBorder
            placeholder={t('customers.form_field_phone_placeholder')}
            value={form.values.phone}
            onChange={handlePhoneChange}
          />
        </div>

        {!!form.errors.phone && (
          <p className={styles.error} data-testid="error">{form.errors.phone}</p>
        )}

        <div className={styles.button}>
          <UiButton
            type='submit'
            text={t('Bolsillo.crearCliente.footerButton.confirmar')}
            size='large'
            isDisabled={isDisabledSubmit()}
            isLoading={isLoading}
          />
        </div>
      </form>

      <CustomersTourTooltip
        obId={CUSTOMER_CREATION_ONBOARDING_ID}
        name={form.values.name}
        phone={form.values.phone}
      />
    </>
  );
}

export default memo(CustomersForm);
