import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Expenses, OTHERS_TABS, OTHERS_AMOUNT_STEP } from '@modules/expenses/constants';
import { actions } from '@modules/expenses/store';
import InputAmount from '@modules/expenses/components/input-amount';

interface OthersExpensesAmountProps { }

const OthersExpensesAmount: FC<OthersExpensesAmountProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setStep(OTHERS_AMOUNT_STEP));
    dispatch(actions.setFooter({
      routeToBack: `${Expenses.others}/${OTHERS_TABS.paymentMethod}`,
      hasSubmitFunction: true,
    }));
    return () => { dispatch(actions.setFooter({ hasSubmitFunction: false })); }
  }, [dispatch]);

  return (
    <InputAmount />
  )
}

export default OthersExpensesAmount;
