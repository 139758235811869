export const income = {
  spanish: {
    "incomes": 'Ingreso',
    "type": {
      "amount": 'Monto',
    },
    "payment_method": {
      "title": 'Método de pago',
      "money_cash": 'Efectivo',
      "card": 'Tarjeta',
      "loan": 'Fiar'
    },
    "notification_message": 'Ingreso por {{amount}} en {{method}} registrado exitosamente',
    "notification_error_message": 'Lo sentimos. Ha ocurrido un error y no pudimos registrar su ingreso',
    "transaction_name": "ingreso",
    "next_step": 'Continuar',
    "tour_tooltip": {
      "amount": 'Registra el valor del ingreso',
      "next_step": 'Presiona continuar',
      "payment_method": 'Selecciona un metodo de pago',
      "submit": 'Presiona confirmar'
    }
  },
  portuguese: {
    "incomes": 'Entrada',
    "type": {
      "amount": 'Valor',
    },
    "payment_method": {
      "title": 'Método de pagamento',
      "money_cash": 'Dinheiro',
      "card": 'Cartão',
      "loan": 'Fiado'
    },
    "notification_message": 'Entrada de {{amount}} em {{method}} registrado com sucesso',
    "notification_error_message": 'Desculpe. Ocorreu um erro e não pudemos registrar seu faturamento.',
    "transaction_name": "entrada",
    "next_step": 'Prosseguir',
    "tour_tooltip": {
      "amount": 'Registre o valor da entrada',
      "next_step": 'Pressione continuar',
      "payment_method": 'Selecione um método de pagamento',
      "submit": 'Pressione confirmar'
    }
  },
};
