import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from './top-used-providers.module.sass';
import useProviderExpense from '@modules/expenses/hooks/use-provider-expense';
import { SearchedProvider } from '@modules/expenses/entities';
import { mostUsedProvidersSelector } from '@modules/expenses/store';

interface TopUsedProvidersProps { }

const TopUsedProviders: FC<TopUsedProvidersProps> = () => {
  const { t } = useTranslation();
  const { setProviderSelection } = useProviderExpense();
  const mostUsedProviders = useSelector(mostUsedProvidersSelector);

  const handleItemClick = (item: SearchedProvider): void => {
    setProviderSelection(item, false);
  };

  const getFirstLetters = (providerName: string) => {
    const name = providerName.trim().replace(/\s\s+/g, ' ');
    return name.split(' ').slice(0, 2).map(word => word[0].toUpperCase()).join('');
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        {t('Bolsillo.gasto.content1')}
      </span>
      <div className={styles.items}>
      {mostUsedProviders?.length ? (mostUsedProviders.map((item, i) => (
        <div className={styles.item} onClick={() => handleItemClick(item)} key={`auto-complete-item-${item.id}-${i}`}>
          <div className={styles.wrapperIcon}>
            <span className={styles.icon}>{getFirstLetters(item.name)}</span>
          </div>
          <span className={styles.text}>{item.name}</span>
        </div>
      ))): (
        <span className={styles.emptyState}>{t('expenses.provider_most_used_empty_state_text')}</span>
      )}
      </div>
    </div>
  )
}

export default TopUsedProviders;
