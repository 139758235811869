import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as globalActions, globalUserSelector } from "@config/store";
import { actions } from "@modules/base/store";
import { fetchGetTransactions } from "@services";
import { BASE_TRANSACTION_TYPE_ID } from "@constants";

const useGetBaseTransactions = () => {
  const dispatch = useDispatch();
  const user = useSelector(globalUserSelector);

  useEffect(() => {
    dispatch(actions.setIsLoading(true));
    fetchGetTransactions(user.storeId, { search: '', limit: 8, skip: 0, transactionTypes: [BASE_TRANSACTION_TYPE_ID] })
      .then(({ data, currentPage, totalPages }) => {
        dispatch(actions.setPagination({ currentPage, totalPages }));
        dispatch(actions.setBaseTransactions(data));
      })
      .catch(() => dispatch(globalActions.setHasGlobalError(true)))
      .finally(() => dispatch(actions.setIsLoading(false)))
  }, [dispatch, user.storeId]);

}

export default useGetBaseTransactions;
