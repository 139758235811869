import React, { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { PATHS, ROUTES } from "@constants";
import usePaymentMethods from "@hooks/use-payment-methods";
import Home from "@modules/home";
import Expenses from "@modules/expenses";
import Income from "@modules/income";
import UiNotification from "@ui/ui-notification";
import Customers from "@modules/customers";
import Base from "@modules/base";
import BoxClosure from "@modules/box-closure/box-closure";
import Credits from "@modules/credits";
import UiGlobalLoader from "@ui/ui-global-loader";
import HeaderOutlet from "./header-outlet";

interface MainRouterProps { }

const MainRouter: FC<MainRouterProps> = () => {
  usePaymentMethods();

  return (
    <>
      <UiNotification />
      <Routes>
        <Route path={`${PATHS.home}/*`} element={<Home />} />
        <Route path='/*' element={<HeaderOutlet />}>
          <Route path={`${PATHS.expenses}/*`} element={<Expenses />} />
          <Route path={`${PATHS.income}/*`} element={<Income />} />
          <Route path={`${PATHS.customers}/*`} element={<Customers />} />
          <Route path={`${PATHS.base}/*`} element={<Base />} />
          <Route path={`${PATHS.boxClosure}/*`} element={<BoxClosure />} />
          <Route path={`${PATHS.credits}/*`} element={<Credits />} />
          <Route path='*' element={<Navigate replace to={ROUTES.home} />} />
        </Route>
      </Routes>
      <UiGlobalLoader />
    </>
  );
};

export default MainRouter;
