import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, homeFilterMovementsSelector } from '@modules/home/store';
import styles from './home-filters-switches-wrapper.module.sass';
import UiSwitch from '@ui/ui-switch';

interface HomeFiltersSwitchesWrapperProps { }

const HomeFiltersSwitchesWrapper: FC<HomeFiltersSwitchesWrapperProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const movements = useSelector(homeFilterMovementsSelector);

  const filters = [
    { title: 'Bolsillo.dashboard.filters.subtitle1', id: 1 },
    { title: 'Bolsillo.dashboard.filters.subtitle2', id: 2 },
    { title: 'Bolsillo.dashboard.filters.subtitle3', id: 4 },
    { title: 'Bolsillo.dashboard.filters.subtitle4', id: 5 },
  ];

  const isActive = useCallback((id: number) => movements.includes(id), [movements]);

  const handleToggle = useCallback((id: number) => {
    const newArray = isActive(id) ? movements.filter(e => e !== id) : [...movements, id];
    dispatch(actions.setFiltersMovements(newArray));
    dispatch(actions.setMustApplyFilters(true));
  }, [dispatch, isActive, movements]);

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{t('Bolsillo.dashboard.filters.title2')}</p>

      {filters.map(e => (
        <div className={styles.filter} key={`filter-elemente-${e.title}`}>
         {t(e.title)}
          <UiSwitch isActive={isActive(e.id)} onChange={() => handleToggle(e.id)} />
        </div>
      ))}
    </div>
  );
}

export default HomeFiltersSwitchesWrapper;
