import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import useOnboarding from '@hooks/use-onboarding';
import { OnboardingMapItem } from '@entities';
import UiTooltip from '@ui/ui-tooltip';
import styles from './ui-tour-tooltip.module.sass';

interface UiTourTooltipProps {
  currentStep: OnboardingMapItem;
  onboardingId: number;
}

const UiTourTooltip: FC<UiTourTooltipProps> = ({ currentStep, onboardingId }) => {
  const { t } = useTranslation();
  const { isOnboardingDone } = useOnboarding();

  if (isOnboardingDone(onboardingId)) return <></>

  return (
    <div className={styles[currentStep.y]}>
      <UiTooltip
        positionY={currentStep.y}
        positionXFixed={currentStep.x}
      >
        <span className={styles.text}>{t(currentStep.title)}</span>
      </UiTooltip>
    </div>
  )
}

export default memo(UiTourTooltip);
