import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { actions as globalActions } from "@config/store";
import { SHARED_PAYMENT_METHODS } from "@constants";
import { fetchGetPaymentMethods } from "../services";
import useNotification from "./use-notification";

const usePaymentMethods = () => {
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const { t } = useTranslation();
  const isRequested = useRef<boolean>(false);

  const getPaymentMethods = useCallback(() => {
    if (!isRequested.current) {
      fetchGetPaymentMethods()
        .then(data => {
          dispatch(globalActions.setPaymentMethods(data));
          isRequested.current = true
        })
        .catch(() => {
          dispatch(globalActions.setPaymentMethods(SHARED_PAYMENT_METHODS));
          showNotification(t('general.notification_payment_methods_failed'), false);
        })
    }
  }, [dispatch, showNotification, t]);

  useEffect(() => {
    getPaymentMethods();
  }, [getPaymentMethods]);
}

export default usePaymentMethods;
