export const home = {
  spanish: {
    "filters": {
      "header_title": "Filtros",
      "date_picker_title": "Rango de fechas",
      "init_picker_label": "Desde",
      "end_picker_label": "Hasta",
      "picker_submit_text": "Seleccionar",
      "switches_title": "Filtrar por tipo de movimiento",
      "switch_title": {
        "income": "Ingresos",
        "expense": "Gastos",
        "base": "Cambios de base",
        "closure": "Cierres de caja"
      },
      "clear_button_text": "Limpiar",
      "submit_button_text": "Mostrar {{total}} resultados",
      "range_error_text": "El rango no debe superar {{max}} días",
      "total_results": "{{total}} Resultados"
    },
    "search": {
      "placeholder_input": "Buscar movimiento",
      "no_data_message": "No se encontraron resultados",
    }
  },
  portuguese: {
    "filters": {
      "header_title": "Filtros",
      "date_picker_title": "Intervalo de datas",
      "init_picker_label": "Desde",
      "end_picker_label": "Até",
      "picker_submit_text": "Selecionar",
      "switches_title": "Filtrar por tipo de transação",
      "switch_title": {
        "income": "Entradas",
        "expense": "Saídas",
        "base": "Alteração de troco",
        "closure": "Fechamentos de caixa"
      },
      "clear_button_text": "Limpar",
      "submit_button_text": "Mostrar {{total}} resultados",
      "range_error_text": "O intervalo não deve superar {{max}} días",
      "total_results": "{{total}} Resultados"
    },
    "search": {
      "placeholder_input": "Exemplo: Fechar",
      "no_data_message": "Nenhum resultado foi encontrado",
    }
  }
};
