import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { TABS } from '../constants';
import BoxClosureHome from '../pages/box-closure-home';
import BoxClosureDetail from '../pages/box-closure-detail';

interface BoxClosureRouterProps { }

const BoxClosureRouter: FC<BoxClosureRouterProps> = () => {

  return (
    <Routes>
      <Route index element={<BoxClosureHome />} />
      <Route path={`${TABS.detail}/:cashId`} element={<BoxClosureDetail />} />
    </Routes>
  )
}

export default BoxClosureRouter;
