import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { To, useNavigate } from 'react-router-dom';
import { globalUiHeaderSelector } from '@config/store';
import styles from './ui-header.module.sass'

interface UiHeaderProps { }

const UiHeader: FC<UiHeaderProps> = () => {
  const navigate = useNavigate();
  const header = useSelector(globalUiHeaderSelector);

  return (
    <div className={`${styles.wrapper} ${header.ishidden && styles.hidden}`}>
      <div className={`${styles.fixed} ${header.isinvisible && styles.hidden} ${header.isUnderUi && styles.under}`}>
        {!!header.hasBackClick && (
          <span
            className={`icon-back ${styles.iconLeft}`}
            onClick={() => navigate(header.routeOnBackClick as To)}
            data-testid='header-left-click'
          />
        )}
        <h1 className={styles.title}>{header.title}</h1>
        {!!header.hasRightClick && (
          <span
            className={`icon-${header.rightIcon} ${styles.icon}`}
            onClick={() => navigate(header.routeOnRightClick as To)}
            data-testid='header-right-click'
          />
        )}
      </div>
    </div>
  )
}

export default memo(UiHeader);
