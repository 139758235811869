import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateOnboardingBody } from '@entities';
import { fetchCreateOnboarding } from '../services';
import Clevertap, { OB_COMPLETE, OB_SKIPPED } from '@config/clevertap';
import { actions as globalActions, globalOnboardingSelector, globalUserSelector } from "@config/store";
import {
  BASE_ONBOARDING_ID, CLOSURE_ONBOARDING_ID, COMPLETE_STATUS_ONBOARDING_ID,
  CONTACT_LOAN_ONBOARDING_ID,
  CUSTOMER_CREATION_ONBOARDING_ID,
  EXPENSES_ONBOARDING_ID,
  FIRST_LOAN_ONBOARDING_ID,
  FIRST_PAYMENT_ONBOARDING_ID,
  INCOME_ONBOARDING_ID,
  SKIPPED_STATUS_ONBOARDING_ID
} from '@constants';

const useOnboarding = () => {
  const dispatch = useDispatch();
  const user = useSelector(globalUserSelector);
  const onboarding = useSelector(globalOnboardingSelector);

  const createNewOnboarding = useCallback((onboardingId: number, statusId: number, total: number): void => {
    const body: CreateOnboardingBody = {
      storeId: user.storeId,
      userId: user.storeId,
      onboardingId,
      onboardingStatusId: statusId
    };

    fetchCreateOnboarding(body);
    dispatch(globalActions.setOnboarding({ done: [...onboarding.done, onboardingId] }));

    if (statusId === COMPLETE_STATUS_ONBOARDING_ID) {
      Clevertap.pushEvent(OB_COMPLETE, { obType: onboardingId, value: total });
    } else Clevertap.pushEvent(OB_SKIPPED, { obType: onboardingId });

  }, [dispatch, onboarding.done, user.storeId]);

  const setIncomeOnboardingDone = useCallback((total: number): void => {
    createNewOnboarding(INCOME_ONBOARDING_ID, COMPLETE_STATUS_ONBOARDING_ID, total);
  }, [createNewOnboarding]);

  const setExpensesOnboardingDone = useCallback((total: number): void => {
    createNewOnboarding(EXPENSES_ONBOARDING_ID, COMPLETE_STATUS_ONBOARDING_ID, total);
  }, [createNewOnboarding]);

  const setBaseOnboardingDone = useCallback((total: number): void => {
    createNewOnboarding(BASE_ONBOARDING_ID, COMPLETE_STATUS_ONBOARDING_ID, total);
  }, [createNewOnboarding]);

  const setCustomersOnboardingSkipped = useCallback((id: number): void => {
    createNewOnboarding(id, SKIPPED_STATUS_ONBOARDING_ID, 0);
  }, [createNewOnboarding]);

  const setClosureOnboardingDone = useCallback((): void => {
    createNewOnboarding(CLOSURE_ONBOARDING_ID, COMPLETE_STATUS_ONBOARDING_ID, 0);
  }, [createNewOnboarding]);

  const setClosureOnboardingSkipped = useCallback((): void => {
    createNewOnboarding(CLOSURE_ONBOARDING_ID, SKIPPED_STATUS_ONBOARDING_ID, 0);
  }, [createNewOnboarding]);

  const setCustomerCreationOnboardingDone = useCallback((): void => {
    createNewOnboarding(CUSTOMER_CREATION_ONBOARDING_ID, COMPLETE_STATUS_ONBOARDING_ID, 0);
  }, [createNewOnboarding]);

  const setContactLoanOnboardingDone = useCallback((total: number): void => {
    createNewOnboarding(CONTACT_LOAN_ONBOARDING_ID, COMPLETE_STATUS_ONBOARDING_ID, total)
  }, [createNewOnboarding]);

  const setFirstLoanOnboardingDone = useCallback((total: number): void => {
    createNewOnboarding(FIRST_LOAN_ONBOARDING_ID, COMPLETE_STATUS_ONBOARDING_ID, total)
  }, [createNewOnboarding]);

  const setFirstPaymentOnboardingDone = useCallback((total: number): void => {
    createNewOnboarding(FIRST_PAYMENT_ONBOARDING_ID, COMPLETE_STATUS_ONBOARDING_ID, total)
  }, [createNewOnboarding]);

  const isOnboardingDone = useCallback((onboardingId: number, extraValidation: boolean = true) => (
    onboarding.done.includes(onboardingId) && extraValidation
  ), [onboarding.done]);

  return {
    setIncomeOnboardingDone,
    setExpensesOnboardingDone,
    setBaseOnboardingDone,
    setClosureOnboardingDone,
    setClosureOnboardingSkipped,
    setCustomersOnboardingSkipped,
    setCustomerCreationOnboardingDone,
    setContactLoanOnboardingDone,
    setFirstLoanOnboardingDone,
    setFirstPaymentOnboardingDone,
    isOnboardingDone
  };
}

export default useOnboarding;
