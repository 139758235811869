import React, { FC, memo, ReactNode } from 'react';
import styles from './ui-tooltip.module.sass';

export type UiTooltipPositionYType = 'top' | 'bottom' | 'top2' | 'top3' | 'top4';
export type UiTooltipPositionXType = 'left' | 'center' | 'right' | 'none';

interface UiTooltipProps {
  children: ReactNode;
  positionX?: number;
  positionY: UiTooltipPositionYType;
  positionXFixed?: UiTooltipPositionXType;
}

const UiTooltip: FC<UiTooltipProps> = ({
  children,
  positionX,
  positionY,
  positionXFixed = 'none',
}) => {
  return (
    <div className={styles.wrapper}>
      {children}
      <span 
        style={{ left: positionX }} 
        className={`${styles.arrow} ${styles[positionY]} ${styles[positionXFixed]}`} 
      />
    </div>
  )
}

export default memo(UiTooltip);
