import { LanguageUrl } from "@entities";

export const MIN_WIDTH_DESKTOP = 768;
export const TIME_TO_HIDE_NOTIFICATION = 2500;
export const STORAGE_TOKEN = 'WALLET_CHIPER';
export const INVALID_TOKEN_STATUS = 403;
export const TIME_TO_DEBOUNCE = 700;

export const COLOMBIA_ISO_CODE = 'CO';
export const MEXICO_ISO_CODE = 'MX';
export const COLOMBIA_AREA_CODE = '+57';
export const MEXICO_AREA_CODE = '+52';
export const BRASIL_COUNTRY_CODE = 'br'

export const TRANSACTIONS_API = 'pos-transaction-api';
export const POCKET_API = 'pos-pocket-api';

export const languageUrl: LanguageUrl = {
  co: process.env.REACT_APP_PHRASE_CO_TRANSLATIONS_URL,
  mx: process.env.REACT_APP_PHRASE_MX_TRANSLATIONS_URL,
  br: process.env.REACT_APP_PHRASE_BR_TRANSLATIONS_URL,
}

export const INCOME_TRANSACTION_TYPE_ID = 1;
export const EXPENSE_TRANSACTION_TYPE_ID = 2;

export const LOAN_TRANSACTION_TYPE_ID = 3;
export const PAYMENT_TRANSACTION_TYPE_ID = 6;
export const DELETION_TRANSACTION_TYPE_ID = 7;

export const BASE_TRANSACTION_TYPE_ID = 4;
export const BOX_CLOSURE_TRANSACTION_TYPE_ID = 5;
export const DEBT_TRANSACTION_TYPE_ID = 14;

export const CREDIT_TRANSACTION_TYPE_ID = 8;
export const CREDIT_PAYMENT_TRANSACTION_TYPE_ID = 9;

export const CREDIT_PAYMENT_METHOD_ID = '6';

export const DEFAULT_TRANSACTIONS_PER_PAGE = 32;
export const TRANSACTIONS_PER_PAGE = 8;
