import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { creditsDetailSelector } from '@modules/credits/store';
import styles from './credit-detail-history.module.sass';
import UiMoneyText from '@ui/ui-money-text';
import { CREDIT_TRANSACTION_TYPE_ID } from '@constants';

interface CreditDetailHistoryProps { }

const CreditDetailHistory: FC<CreditDetailHistoryProps> = () => {
  const { transactions } = useSelector(creditsDetailSelector);
  const { t } = useTranslation();

  const isCredit = (type: number): boolean => type === CREDIT_TRANSACTION_TYPE_ID;

  const getDescription = (type: number): string =>
    t(isCredit(type) ? 'credits.detail.credit_transaction_name' : 'credits.detail.payment_transaction_name')

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{t('credits.detail.history_title')}</p>

      {transactions.map((item, i) => (
        <div className={styles.item} key={`history-list-item-${item.total}-${i}`}>
          <span className={`icon-money-cash ${styles.icon}`} />
          <div className={styles.description}>
            {getDescription(item.transactionTypeId)}
            <span className={styles.date}>{item.createdAt}</span>
          </div>
          <UiMoneyText isSmall value={item.total} negative={isCredit(item.transactionTypeId)} />
        </div>
      ))}
    </div>
  );
}

export default CreditDetailHistory;
