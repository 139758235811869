import { ExpenseStepMap } from "../entities";

export const EGRESS_TYPE_TOUR_STEP = 1;
export const PAYMENT_METHOD_TOUR_STEP = 2;
export const AMOUNT_TOUR_STEP = 3

export const STEPS_MAP: ExpenseStepMap = {
  [EGRESS_TYPE_TOUR_STEP]: {
    1: {
      title: 'expenses.tour_tooltip.macroegress_type',
      x: 'center',
      y: 'top'
    },
    2: {
      title: 'expenses.tour_tooltip.next_step',
      x: 'right',
      y: 'bottom'
    }
  },
  [PAYMENT_METHOD_TOUR_STEP]: {
    1: {
      title: 'expenses.tour_tooltip.payment_method',
      x: 'center',
      y: 'top'
    },
    2: {
      title: 'expenses.tour_tooltip.next_step',
      x: 'right',
      y: 'bottom'
    }
  },
  [AMOUNT_TOUR_STEP]: {
    1: {
      title: 'expenses.tour_tooltip.amount',
      x: 'center',
      y: 'top'
    },
    2: {
      title: 'expenses.tour_tooltip.submit',
      x: 'right',
      y: 'bottom'
    }
  }
}