import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from './search-provider-results.module.sass';
import useInputFieldValidation from '@hooks/use-input-field-validation';
import useProviderExpense from '@modules/expenses/hooks/use-provider-expense';
import { SearchedProvider } from '@modules/expenses/entities';
import {
  actions,
  expensesMicroEgressNameSelector,
  expensesUiSelector,
  searchProvidersResultsSelector
} from '@modules/expenses/store';

interface SearchProviderResultsProps { }

const SearchProviderResults: FC<SearchProviderResultsProps> = () => {
  const { setProviderSelection } = useProviderExpense();
  const dispatch = useDispatch();
  const currentMicroEgressName = useSelector(expensesMicroEgressNameSelector);
  const searchProvidersResults = useSelector(searchProvidersResultsSelector);
  const { selectionSuggestion, hasSearchedProviders } = useSelector(expensesUiSelector);
  const { t } = useTranslation();
  const { hasError } = useInputFieldValidation();

  useEffect(() => {
    dispatch(actions.setFooter({ isDisabled: !currentMicroEgressName || hasError }));
  }, [currentMicroEgressName, dispatch, hasError]);

  const handleProviderSelection = useCallback((provider: SearchedProvider): void => {
    setProviderSelection(provider, false);
  }, [setProviderSelection]);

  const handleProviderCreate = useCallback((provider: SearchedProvider): void => {
    setProviderSelection(provider, true);
  }, [setProviderSelection]);

  const getResultsList = (): SearchedProvider[] => {
    return searchProvidersResults.map(e => ({ id: e.id, name: e.name, imageUrl: e.imageUrl }))
  };

  const showCreateButton = useMemo(() => (
    !searchProvidersResults.find(item => item.name.toLowerCase() === currentMicroEgressName.toLowerCase())
  ), [searchProvidersResults, currentMicroEgressName]);

  return (
    <div className={styles.wrapper}>
      {selectionSuggestion?.showList && (
        <div className={styles.list}>
          <span className={styles.listTitle}>{t('expenses.provider_options_title')}</span>
          {getResultsList().map((item, i) => (
            <div
              className={styles.item}
              onClick={() => handleProviderSelection(item)}
              key={`auto-complete-item-${item.id}-${item.name}-${i}`}
            >
              <span className={styles.result}>
                {item.name.slice(0, currentMicroEgressName.length)}
                <span className={styles.bold}>{item.name.slice(currentMicroEgressName.length)}</span>
              </span>

              <span className={`icon-arrow-1 ${styles.icon}`} />
            </div>
          ))}

          {(hasSearchedProviders && showCreateButton) && (
            <div className={styles.createProvider} onClick={() => handleProviderCreate({ name: currentMicroEgressName })}>
              <span className={`icon-plus ${styles.icon}`} />
              <span className={styles.text}>{t('Bolsillo.gasto.searchbar.button1', { Value: currentMicroEgressName })}</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default SearchProviderResults;
