import React, { FC, memo } from 'react';
import styles from './ui-progress-bar.module.sass';

interface UiProgressBarProps {
  currentStep?: number;
  totalSteps?: number;
}

const UiProgressBar: FC<UiProgressBarProps> = ({ totalSteps = 1, currentStep = 0 }) => {
  const calculateWidth = (): string => {
    if (currentStep >= totalSteps) return '100%';
    if (currentStep <= 0) return '0%';
    return `${(currentStep * 100) / totalSteps}%`
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.progress} style={{ width: calculateWidth() }} />
    </div>
  );
}

export default memo(UiProgressBar);
