import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions } from '@modules/expenses/store';
import { actions as globalActions } from '@config/store';
import { Expenses, RENT_MICRO_EGRESS, RENT_PAYMENT_METHOD_STEP, RENT_TABS } from '@modules/expenses/constants';
import { ROUTES } from '@constants';
import PaymentMethods from '@modules/expenses/components/payment-methods';

interface RentPaymentMethodProps { }

const RentPaymentMethod: FC<RentPaymentMethodProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(actions.setCurrentMicroEgressTypeId(RENT_MICRO_EGRESS.id));
    dispatch(actions.setCurrentMicroEgressName(t(RENT_MICRO_EGRESS.name)));
    dispatch(actions.setStep(RENT_PAYMENT_METHOD_STEP));
    dispatch(actions.setFooter({
      routeToNext: `${Expenses.rent}/${RENT_TABS.amount}`,
      routeToBack: ROUTES.expenses,
    }));
    dispatch(globalActions.header({ hasRightClick: true }));
  }, [dispatch, t])

  return (
    <PaymentMethods />
  );
}

export default RentPaymentMethod;