import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@config/store";
import { BoxClosureUiState, BoxClosureFormState } from "./box-closure.state";
import { BoxClosureDetail, BoxClosureDetailTransactionsResponse, EditionTypeId } from "../entities";

export const boxClosureUiSelector = createSelector(
  (state: RootState) => state.boxClosure.ui,
  (state: BoxClosureUiState) => state,
);

export const boxClosureEditionUiSelector = createSelector(
  (state: RootState) => state.boxClosure.ui.editionActiveId,
  (state: EditionTypeId) => state,
);

export const boxClosureFormSelector = createSelector(
  (state: RootState) => state.boxClosure.data.form,
  (state: BoxClosureFormState) => state,
);

export const boxClosureInitialFormSelector = createSelector(
  (state: RootState) => state.boxClosure.data.initialForm,
  (state: BoxClosureFormState) => state,
);

export const boxClosureDetailSelector = createSelector(
  (state: RootState) => state.boxClosure.data.detail.data,
  (state: BoxClosureDetail) => state
);

export const boxClosureDetailTransactionsSelector = createSelector(
  (state: RootState) => state.boxClosure.data.detail.transactions,
  (state: BoxClosureDetailTransactionsResponse) => state,
);
