import {
  BASE_ONBOARDING_ID, CLOSURE_ONBOARDING_ID, EXPENSES_ONBOARDING_ID,
  INCOME_ONBOARDING_ID, LOAN_ONBOARDING_ID, ROUTES
} from "@constants";
import { TABS as BASE_TABS } from "@modules/base/constants";

export const SERVICES_MENU_ITEMS = [
  {
    name: 'Bolsillo.dashboard.principalbuttons.button1',
    icon: 'icon-coins',
    routeTo: `${ROUTES.base}/${BASE_TABS.amount}`,
    onboardingId: BASE_ONBOARDING_ID
  },
  {
    name: 'Bolsillo.dashboard.principalbuttons.button2',
    icon: 'icon-income',
    routeTo: ROUTES.income,
    onboardingId: INCOME_ONBOARDING_ID
  },
  {
    name: 'Bolsillo.dashboard.principalbuttons.button3',
    icon: 'icon-egress',
    routeTo: ROUTES.expenses,
    onboardingId: EXPENSES_ONBOARDING_ID
  },
  {
    name: 'Bolsillo.dashboard.principalbuttons.button4',
    icon: 'icon-handshake',
    routeTo: ROUTES.customers,
    onboardingId: LOAN_ONBOARDING_ID
  },
  {
    name: 'Bolsillo.dashboard.principalbuttons.button5',
    icon: 'icon-calculator',
    routeTo: ROUTES.boxClosure,
    onboardingId: CLOSURE_ONBOARDING_ID
  }
];

export const DEFAULT_STEP = 1;
export const DEFAULT_TOTAL_STEPS = 10;

export const CREDIT_ID = '6';
