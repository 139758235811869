import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { globalUserSelector } from "@config/store";
import { actions } from "@modules/customers/store";
import { ROUTES } from "@constants";
import { useNavigate } from "react-router-dom";
import { fetchGetCustomerDetail, fetchGetCustomers } from "@modules/customers/services";
import useNotification from "@hooks/use-notification";

const useCustomersFetch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showNotification = useNotification();
  const user = useSelector(globalUserSelector);

  const getCustomers = useCallback((): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetCustomers(user.storeId)
      .then((data) => {
        dispatch(actions.setCustomers(data));
        dispatch(actions.setHasRequestedCustomers(true));
      })
      .catch(() => {
        showNotification(t("customers.error.get_customers"), false);
        navigate(ROUTES.home);
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, navigate, showNotification, t, user.storeId]);

  const getCustomerDetail = useCallback((customerId: number): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetCustomerDetail(customerId)
      .then((data) => {
        dispatch(actions.setCustomerDetail(data));
        dispatch(actions.setIsLoading(false));
      })
      .catch(() =>{ 
        showNotification(t('customers.error.get_detail'), false);
        navigate(ROUTES.customers)
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }, [dispatch, navigate, showNotification, t]);

  return {
    getCustomers,
    getCustomerDetail,
  };
};

export default useCustomersFetch;
