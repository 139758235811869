import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { hideEcommerceTabs, showEcommerceTabs } from '@utils/webview';
import Clevertap, { ON_DASH, OUT_DASH } from '@config/clevertap';
import ServicesMenu from '@modules/home/components/home-services-menu';
import DashboardBalances from '@modules/home/components/home-dashboard-balances';
import DashboardMovements from '@modules/home/components/home-dashboard-movements';
import styles from './home-dashboard.module.sass';

interface HomeDashboardProps { }

const HomeDashboard: FC<HomeDashboardProps> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    showEcommerceTabs();
    Clevertap.pushEvent(ON_DASH);

    return () => {
      hideEcommerceTabs();
      Clevertap.pushEvent(OUT_DASH);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{t('Bolsillo.dashboard.title1')}</p>

      <ServicesMenu />

      <DashboardBalances />

      <DashboardMovements />
    </div>
  );
}

export default HomeDashboard;
