import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@config/store';
import { ExpensesUiState } from '.';
import { ExpensesDataState } from './expenses.state';
import { TypeSuggestion } from '../entities';

export const expensesUiSelector = createSelector(
  (state: RootState) => state.expenses.ui,
  (state: ExpensesUiState) => state
);

export const expensesDataSelector = createSelector(
  (state: RootState) => state.expenses.data,
  (state: ExpensesDataState) => state
);

export const expensesMicroEgressSelector = createSelector(
  (state: RootState) => state.expenses.data,
  (state: ExpensesDataState) => state.currentMicroEgressTypeId
);

export const expensesMicroEgressNameSelector = createSelector(
  (state: RootState) => state.expenses.data,
  (state: ExpensesDataState) => state.currentMicroEgressName
);

export const currentPaymentMethodSelector = createSelector(
  (state: RootState) => state.expenses.data,
  (state: ExpensesDataState) => state.currentPaymentMethodId
);

export const currentExpenseSelector = createSelector(
  (state: RootState) => state.expenses.data,
  (state: ExpensesDataState) => state.currentExpense
);

export const customProviderExpenseSelector = createSelector(
  (state: RootState) => state.expenses.data,
  (state: ExpensesDataState) => state.isCustomProviderExpense
);

export const searchProvidersResultsSelector = createSelector(
  (state: RootState) => state.expenses.data,
  (state: ExpensesDataState) => state.searchProvidersResults
);

export const mostUsedProvidersSelector = createSelector(
  (state: RootState) => state.expenses.data,
  (state: ExpensesDataState) => state.mostUsedProviders
);

export const mostRecentProvidersSelector = createSelector(
  (state: RootState) => state.expenses.data,
  (state: ExpensesDataState) => state.mostRecentProviders
);


export const currentAmountSelector = createSelector(
  (state: RootState) => state.expenses.data,
  (state: ExpensesDataState) => state.currentAmount
);

export const searchOthersTypeSuggestionsResultsSelector = createSelector(
  (state: RootState) => state.expenses.data,
  (state: ExpensesDataState) => state.searchOthersTypeSuggestionsResults
);

export const otherTypeLastSuggestionCreatedSelector = createSelector(
  (state: RootState) => state.expenses.data.othersTypeLastCreated,
  (state: TypeSuggestion) => state
);

export const othersTypeSuggestionsListSelector = createSelector(
  (state: RootState) => state.expenses.data.othersTypeSuggestionsList,
  (state: TypeSuggestion[]) => state
);
