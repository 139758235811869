import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ContactPhone } from "@entities";
import { navigateToPermissionsPanel } from "@utils/webview";
import { globalContactsPermissionSelector } from "@config/store";
import { customersDataSelector, customersSearchSelector } from "@modules/customers/store";
import { CUSTOMERS_PATH, TABS } from "@modules/customers/constants";
import CustomersEmptyState from "../customers-empty-state";
import UiDivider from "@ui/ui-divider";
import styles from "./customers-home-contact-list.module.sass";

interface ContactGroupItem {
  letter: string;
  contacts: ContactPhone[];
}

interface CustomersHomeContactListProps {
  contactsList: ContactPhone[];
}

const CustomersHomeContactList: FC<CustomersHomeContactListProps> = ({ contactsList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const customers = useSelector(customersDataSelector);
  const searchValue = useSelector(customersSearchSelector);
  const contactsPermissionGranted = useSelector(globalContactsPermissionSelector);

  const contactsFinalList = useMemo(() => {
    const groupedContacts: ContactGroupItem[] = [];
    for (const contact of contactsList) {
      if (!customers.find(customer => (customer.phone === contact.phone))) {

        const currentItem = groupedContacts.find(e => (
          !!e?.letter && e.letter.toLowerCase() === contact.name[0].toLowerCase()
        ));

        if (!currentItem) {
          groupedContacts.push({ letter: contact.name[0].toUpperCase(), contacts: [contact] })
        }
        else currentItem.contacts = [...currentItem.contacts, contact]
      }
    }
    return groupedContacts;
  }, [contactsList, customers]);

  const handleContactClick = (contactId: number) => {
    navigate(`${CUSTOMERS_PATH}/${TABS.contact_transaction}/${contactId}`);
  }

  if (!!searchValue && !contactsFinalList.length) return <div />

  if (contactsPermissionGranted && !contactsList.length) return <div />

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          {t("Bolsillo.misClientes.listaMisContactos.titulo1")}
        </div>

        {contactsPermissionGranted ? (
          contactsFinalList.map((e) => (
            <div key={`contact-list-${e.letter}`}>
              <div className={styles.letter}>
                {e.letter}
              </div>

              {e.contacts.map((c) => (
                <div className={styles.card} key={`contact-${c.contactId}`}>
                  <span className={`icon-user ${styles.icon}`} />
                  <div className={styles.center}>
                    {c.name}
                    <p className={styles.phone}>{c.phone}</p>
                  </div>

                  <div className={styles.link} onClick={() => handleContactClick(c.contactId)}>
                    {t("Bolsillo.misClientes.listaMisclientes.cardExterna.botonFiar")}
                  </div>
                </div>
              ))}

              <div className={styles.divider}>
                <UiDivider isSmall isDarker />
              </div>
            </div>
          ))
        ) : (
          <CustomersEmptyState
            title={t("Bolsillo.misClientes.listaMisContactos.subTitulo1")}
            text={t("Bolsillo.misClientes.listaMisContactos.cuerpo1")}
            clickableText={t("Bolsillo.misClientes.listaMisContactos.botonConfiguracion")}
            onTextClick={navigateToPermissionsPanel}
          />
        )}
      </div>
    </>
  )
}

export default CustomersHomeContactList;
