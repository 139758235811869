import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { globalUserSelector } from "@config/store";
import { actions } from "@modules/box-closure/store";
import { TRANSACTIONS_PER_PAGE } from "@modules/box-closure/constants";
import { fetchGetBoxClosureTransactions } from "@modules/box-closure/services";
import useNotification from "@hooks/use-notification";

const useBoxClosureDetailPagination = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(globalUserSelector);
  const { cashId } = useParams();
  const showNotification = useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nextPage, setNextPage] = useState(2);

  const getNextPage = () => {
    setIsLoading(true);
    fetchGetBoxClosureTransactions(user.storeId, cashId, TRANSACTIONS_PER_PAGE * nextPage)
      .then((transactions) => {
        dispatch(actions.setTransactionsToDetail(transactions));
        setNextPage(prev => prev + 1);
      })
      .catch(() => showNotification(t('box_closure.notification.page_error'), false))
      .finally(() => setIsLoading(false));
  }

  return {
    isLoading,
    getNextPage
  };
}

export default useBoxClosureDetailPagination;
