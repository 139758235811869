import React, { FC, useMemo, memo } from 'react';
import { Transaction } from '@entities';
import getTransactionProperties from '@utils/get-transaction-properties';
import UiMoneyValue from '@ui/ui-money-value';
import styles from './box-closure-detail-transaction-item.module.sass';

interface BoxClosureDetailTransactionItemProps {
  transaction: Transaction;
}

const BoxClosureDetailTransactionItem: FC<BoxClosureDetailTransactionItemProps> = ({ transaction }) => {

  const { type } = useMemo(() => getTransactionProperties(transaction), [transaction]);

  return (
    <div className={styles.wrapper}>
      <span className={styles.left}>
        <img className={styles.img} src={transaction.imageUrl} alt="" />
        {transaction.description}
      </span>

      <UiMoneyValue value={transaction.total} type={type} />
    </div>
  )
}

export default memo(BoxClosureDetailTransactionItem);
