import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ui-dash-skeleton.module.sass';

interface UiDashSkeletonProps { }

const UiDashSkeleton: FC<UiDashSkeletonProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container} data-testid="ui-dash-skeleton">

      <div className={styles.title}>
        <span className={styles.text}>{t('general.pocket')}</span>
      </div>

      <div className={styles.buttons}>
        {Array(5).fill('').map((_, i) => (
          <div className={styles.circle} key={`skeleton-dash-${i}`} />
        ))}
      </div>

      <div className={styles.divider}>
        <div className={styles.line}/>
      </div>

      <div className={styles.wrapper}>
        {Array(3).fill('').map((_, i) => (
          <div className={styles.info} key={`skeleton-info-${i}`} >
            <div className={styles.text}/>
            <div className={styles.value}/>
          </div>
        ))}
      </div>

      <div className={styles.divider}>
        <div className={styles.line}/>
      </div>

      <div className={styles.wrapper}>
        {Array(2).fill('').map((_, i) => (
          <div className={styles.infoTwo} key={`skeleton-info-2-${i}`}>
            <div>
              <div className={styles.text}/>
            </div>
            <div className={styles.right}>
              <div className={styles.value}/>
              <div className={styles.arrow}/>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.divider}>
        <div className={styles.line}/>
      </div>

      <div className={styles.filters}>
        <span className={`icon-search ${styles.icon}`}/>
        <span className={`icon-filters ${styles.icon}`}/>
      </div>

      {Array(3).fill('').map((_, i) => 
        <div className={styles.transactionsWrapper} key={`skeleton-transactions-wrapper-${i}`}>
          {Array(4).fill('').map((__, j) => (
            <div className={styles.transaction} key={`skeleton-transaction-${j}`}>
              <div className={styles.left}>
                <div className={styles.icon}/>
                <div className={styles.text}/>
              </div>
              <div className={styles.right}>
                <div className={styles.value}/>
                <div className={styles.arrow}/>
              </div>
            </div>
          ))}
          <div className={styles.dividerBottom}/>
        </div>
      )}

      <div className={styles.button}>
        <div className={styles.seeMore}/>
      </div>
      
    </div>
  );
}

export default UiDashSkeleton;
