import { StepSection } from "@entities";
import { PublicServiceType } from "../entities/public-service.entities";
import { TITLES } from "./expenses.constants";

export const PUBLIC_SERVICE_TOTAL_STEPS = 4;

export const SERVICE_TYPE_STEP: StepSection = {
  totalSteps: PUBLIC_SERVICE_TOTAL_STEPS,
  currentStep: 2,
  stepName: TITLES.serviceType
};

export const SERVICE_PAYMENT_METHOD: StepSection = {
  totalSteps: PUBLIC_SERVICE_TOTAL_STEPS,
  currentStep: 3,
  stepName: TITLES.paymenentMethod
};

export const SERVICE_AMOUNT_STEP: StepSection = {
  totalSteps: PUBLIC_SERVICE_TOTAL_STEPS,
  currentStep: 4,
  stepName: TITLES.amount
};

export const PUBLIC_SERVICE_TABS = {
  serviceType: 'service_type',
  paymentMethod: 'payment_method',
  amount: 'amount'
};

export const PUBLIC_SERVICE_TYPES: PublicServiceType[] = [
  { id: 2, name: 'expenses.public_service.water', icon: 'icon-drop' },
  { id: 3, name: 'expenses.public_service.energy', icon: 'icon-light' },
  { id: 4, name: 'expenses.public_service.gas', icon: 'icon-wind' },
  { id: 5, name: 'expenses.public_service.internet', icon: 'icon-signal' },
  { id: 6, name: 'expenses.public_service.telephone', icon: 'icon-phone' },
];
