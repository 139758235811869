import { StepSection } from "@entities";
import { TITLES } from "./expenses.constants";

export const PERSONAL_TOTAL_STEPS = 3;

export const PERSONAL_PAYMENT_METHOD_STEP: StepSection = {
  totalSteps: PERSONAL_TOTAL_STEPS,
  currentStep: 2,
  stepName: TITLES.paymenentMethod
};

export const PERSONAL_AMOUNT_STEP: StepSection = {
  totalSteps: PERSONAL_TOTAL_STEPS,
  currentStep: 3,
  stepName: TITLES.amount
};

export const PERSONAL_TABS = {
  paymentMethod: 'payment_method',
  amount: 'amount'
};

export const PERSONAL_MICRO_EGRESS = {
  id: 14,
  name: 'expenses.type.personal',
};
