import React, { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { INCOME_ONBOARDING_ID } from '@constants';
import Clevertap, { OB_START } from '@config/clevertap';
import { incomeUiSelector, incomeDataSelector } from '@modules/income/store';
import { STEPS_MAP } from '@modules/income/constants';
import useOnboarding from '@hooks/use-onboarding';
import UiTourTooltip from '@ui/ui-tour-tooltip';

interface IncomeTourTooltipProps { }

const IncomeTourTooltip: FC<IncomeTourTooltipProps> = () => {
  const { step } = useSelector(incomeUiSelector);
  const data = useSelector(incomeDataSelector);
  const { isOnboardingDone } = useOnboarding();

  useEffect(() => {
    if (!isOnboardingDone(INCOME_ONBOARDING_ID)) {
      Clevertap.pushEvent(OB_START, { obType: INCOME_ONBOARDING_ID });
    }
  }, [isOnboardingDone]);

  const currentStep = useMemo(() => {
    if (step.currentStep === 1) {
      return !data.currentAmount ? STEPS_MAP[1][1] : STEPS_MAP[1][2]
    }
    return !data.currentPaymentMethodId ? STEPS_MAP[2][1] : STEPS_MAP[2][2]
  }, [data, step]);

  return (
    <UiTourTooltip 
      currentStep={currentStep}
      onboardingId={INCOME_ONBOARDING_ID}
    />
  )
}

export default IncomeTourTooltip;
