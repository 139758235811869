import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import BaseAmount from "@modules/base/pages/base-amount";
import BaseReason from "@modules/base/pages/base-reason";
import BaseHome from "@modules/base/pages/base-home";
import BaseEditionContainer from "../components/base-edition-container";
import { TABS } from "@modules/base/constants";
import { ROUTES } from "@constants";

interface BaseRouterProps { }

const BaseRouter: FC<BaseRouterProps> = () => {
  return (
    <Routes>
      <Route index element={<BaseHome />} />

      <Route path='/*' element={<BaseEditionContainer />}>
        <Route path={TABS.amount} element={<BaseAmount />} />
        <Route path={TABS.reason} element={<BaseReason />} />
      </Route>
  
      <Route path="*" element={<Navigate to={ROUTES.base} />} />
    </Routes>
  );
};

export default BaseRouter;
