import { FooterSection, StepSection } from "@entities";
import { Expenses } from "../constants";
import { MacroEgress, MicroEgress, SearchedProvider, SelectionSuggestion, TypeSuggestion } from "../entities";

export interface ExpensesState {
  ui: ExpensesUiState;
  types: ExpensesTypesState;
  data: ExpensesDataState;
};

export type SearchbarStatus = 'init' | 'toTop' | 'toBottom';
export interface ExpensesUiState {
  isLoading: boolean;
  step: StepSection;
  footer: FooterSection;
  selectionSuggestion: SelectionSuggestion;
  providerSearchbarStatus: SearchbarStatus;
  hasSearchedProviders: boolean;
  tourStep: number;
};

export interface ExpensesTypesState {
  macroEgress: MacroEgress[];
  microEgess: MicroEgress[];
};

export interface ExpensesDataState {
  currentExpense: Expenses;
  currentMacroEgressTypeId: number;
  currentMicroEgressTypeId: number;
  currentMicroEgressName: string;
  isCustomProviderExpense: boolean;
  searchProvidersResults: SearchedProvider[];
  mostUsedProviders: SearchedProvider[];
  mostRecentProviders: SearchedProvider[];
  currentPaymentMethodId: string;
  currentAmount: string;
  searchOthersTypeSuggestionsResults: TypeSuggestion[];
  othersTypeLastCreated: TypeSuggestion;
  othersTypeSuggestionsList: TypeSuggestion[];
};

export const initialState: ExpensesState = {
  ui: {
    isLoading: false,
    step: {
      currentStep: 0,
      totalSteps: 0,
      stepName: ''
    },
    footer: {
      isDisabled: true,
      routeToBack: '/',
      routeToNext: '/',
      hasSubmitFunction: false,
    },
    selectionSuggestion: {
      isSelected: false,
      showList: false,
      hasSearching: false,
    },
    providerSearchbarStatus: 'init',
    hasSearchedProviders: false,
    tourStep: 0
  },
  types: {
    macroEgress: [],
    microEgess: [],
  },
  data: {
    currentExpense: Expenses.empty,
    currentMacroEgressTypeId: 0,
    currentMicroEgressTypeId: 0,
    currentMicroEgressName: '',
    isCustomProviderExpense: false,
    searchProvidersResults: [],
    mostUsedProviders: [],
    mostRecentProviders: [],
    currentPaymentMethodId: '',
    currentAmount: '',
    searchOthersTypeSuggestionsResults: [],
    othersTypeLastCreated: {
      id: 0,
      name: '',
      macroEgressTypeId: 0,
    },
    othersTypeSuggestionsList: [],
  }
};
