export const expenses = {
  spanish: {
    "type_title": 'Tipo de gasto',
    "type": {
      "amount": 'Monto',
      "service_type": 'Selecciona el tipo de servicio',
      "provider": 'Proveedor',
      "public_service": 'Servicio público',
      "personal": 'Personal',
      "anothers": 'Otros',
      "rent": 'Arriendo'
    },
    "payment_method": {
      "title": 'Método de pago',
      "money_cash": 'Efectivo',
      "card": 'Tarjeta',
      "credit": "Crédito",
    },
    "notification_message": 'Gasto por {{amount}} en {{method}} registrado exitosamente',
    "notification_error_message": 'Lo sentimos. Ha ocurrido un error y no pudimos registrar su gasto.',
    "transaction_name": "gasto",
    "next_step": 'Continuar',
    "tour_tooltip": {
      "macroegress_type": 'Selecciona un tipo de gasto',
      "next_step": 'Presiona continuar',
      "amount": 'Registra el valor del gasto',
      "payment_method": 'Selecciona un método de pago',
      "submit": 'Presiona confirmar'
    }
  },
  portuguese: {
    "type_title": 'Tipo de saída',
    "type": {
      "amount": 'Valor',
      "service_type": 'Selecione o tipo de serviço',
      "provider": 'Fornecedor',
      "public_service": 'Serviço público',
      "personal": 'Pessoal',
      "anothers": 'Outros',
      "rent": 'Aluguel'
    },
    "payment_method": {
      "title": 'Método de pagamento',
      "money_cash": 'Dinheiro',
      "card": 'Cartão',
      "credit": "Crédito",
    },
    "notification_message": 'Saída de {{amount}} em {{method}} registrado com sucesso',
    "notification_error_message": 'Desculpe. Ocorreu um erro e não pudemos registrar a saída.',
    "transaction_name": "saída",
    "next_step": 'Prosseguir',
    "tour_tooltip": {
      "macroegress_type": 'Selecione um tipo de gasto',
      "next_step": 'Pressione continuar',
      "amount": 'Registre o valor do gasto',
      "payment_method": 'Selecione o método de pagamento',
      "submit": 'Pressione confirmar'
    }
  }
}