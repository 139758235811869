import axios from "axios";
import { POCKET_API, TRANSACTIONS_API } from "@constants";
import { BoxClosureDetail, BoxClosure, BoxClosureDetailTransactionsResponse } from "../entities";
import { TRANSACTIONS_PER_PAGE } from "../constants";

export const fetchCreateBoxClosure = (body: BoxClosure): Promise<number> =>
  axios.post(`${POCKET_API}/cash-deck-control`, body)
    .then(({ data }) => data.id);

export const fetchGetBoxClosureTransactions = (
  storeId: number,
  cashId?: string,
  limit: number = TRANSACTIONS_PER_PAGE,
): Promise<BoxClosureDetailTransactionsResponse> =>
  axios.get(`${TRANSACTIONS_API}/transaction/get-transactions-cash/${storeId}?cashId=${cashId}&limit=${limit}`)
    .then(resp => (resp.data));

export const fetchGetBoxClosureDetail = (storeId: number, cashId?: string): Promise<BoxClosureDetail> =>
  axios.get(`${TRANSACTIONS_API}/cash-deck-control/get-balance-cash/${storeId}?cashId=${cashId}`)
    .then(resp => (resp.data));
