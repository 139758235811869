import React, { FC, memo } from 'react';
import styles from './customers-empty-state.module.sass';

interface CustomersEmptyStateProps {
  title: string;
  text: string;
  clickableText?: string;
  onTextClick?: () => void;
}

const CustomersEmptyState: FC<CustomersEmptyStateProps> = ({ title, text, clickableText, onTextClick }) => {
  return (
    <div className={styles.wrapper}>

      <div className={styles.title}>{title}</div>

      <div className={styles.text}>{text}</div>

      {clickableText && 
        <div className={styles.clickableField} onClick={onTextClick}>
          <span className={styles.clickableText}>{clickableText}</span>
          <span className={`icon-arrow-1 ${styles.arrow}`}/>
        </div>
      }

    </div>
  )
}

export default memo(CustomersEmptyState);
