import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TABS } from '../constants';
import CreditDetail from '../pages/credit-detail';
import CreditPayment from '../pages/credit-payment';
import Debts from '../pages/debts';

interface CreditsRouterProps { }

const CreditsRouter: FC<CreditsRouterProps> = () => {

  return (
    <Routes>
      <Route path={`${TABS.home}/${TABS.debts}`} element={<Debts />} />
      <Route path={`${TABS.home}/:providerId`} element={<CreditDetail />} />
      <Route path={`${TABS.home}/:providerId/${TABS.pay}`} element={<CreditPayment />} />
      <Route path='*' element={<Navigate to={TABS.debts} />} />
    </Routes>
  );
}

export default CreditsRouter;
