import { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from './debts.module.sass';
import UiSearchBar from '@ui/ui-search-bar';
import DebtsProvidersList from '@modules/credits/components/debts-providers-list';
import { ROUTES } from '@constants';
import { actions as globalActions } from '@config/store';
import useCreditsFetch from '@modules/credits/hooks';
import { actions, creditsUiSelector } from '@modules/credits/store';
import UiGlobalLoader from '@ui/ui-global-loader/ui-global-loader';

interface DebtsProps { }

const Debts: FC<DebtsProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getDebtsProviders } = useCreditsFetch();
  const { isLoading } = useSelector(creditsUiSelector);

  const handleInputChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>): void => {
    dispatch(actions.setProviderSearchedValue(target.value.toLowerCase().trim()));
  }, [dispatch]);

  useEffect(() => {
    dispatch(globalActions.header({
      title: t('credits.my_doubts.header_title'),
      hasRightClick: false,
      hasBackClick: true,
      routeOnBackClick: ROUTES.home
    }));
    dispatch(actions.restartState());
  }, [dispatch, t]);

  useEffect(() => {
    getDebtsProviders();
  }, [getDebtsProviders])

  if (isLoading) return <UiGlobalLoader />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchBarWrapper}>
        <UiSearchBar onChange={handleInputChange} placeholder={t('credits.my_doubts.placeholder_text')} />
      </div>
      <DebtsProvidersList />
    </div>
  )
}

export default Debts;
