import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPhoneWithAreaCode } from "@modules/customers/utils";
import { sendWhatsApp } from "@utils/webview";
import Clevertap, { CUSTOMER_REMINDER } from '@config/clevertap';
import { customerDetailSelector } from "@modules/customers/store";
import { TABS } from "@modules/customers/constants";
import styles from "./customer-detail-buttons.module.sass";
import UiButton from "@ui/ui-button";

interface CustomerDetailButtonsProps { }

const CustomerDetailButtons: FC<CustomerDetailButtonsProps> = () => {
  const navigate = useNavigate();
  const customerDetail = useSelector(customerDetailSelector);
  const { t } = useTranslation();

  const isDisabledPayment = (): boolean => customerDetail.debt === 0;

  const handleRemainderButtonClick = (): void => {
    const message = t("customers.whatsapp_reminder.message", { name: customerDetail.name });
    const phone = getPhoneWithAreaCode(customerDetail.phone);
    sendWhatsApp(phone, message);
    Clevertap.pushEvent(CUSTOMER_REMINDER, { phone });
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.wrapperButton}>
          <UiButton
            text={t("Bolsillo.detalleCliente.deudaCliente.botonRegistrarPago")}
            onClick={() => navigate(TABS.payment_transaction)}
            isDisabled={isDisabledPayment()}
          />
        </div>
        <div className={styles.wrapperButton}>
          <UiButton
            text={t("Bolsillo.detalleCliente.deudaCliente.botonFiar")}
            onClick={() => navigate(TABS.loan_transaction)}
          />
        </div>
      </div>

      <UiButton
        text={t('Bolsillo.detalleCliente.deudaCliente.botonEnviarRecordatorio')}
        isDisabled={isDisabledPayment()}
        onClick={handleRemainderButtonClick}
        isSecondary
        leftIcon="icon-whatsapp"
        size="large"
      />
    </div>
  );
};

export default CustomerDetailButtons;
