import axios from "axios";
import { POCKET_API } from "@constants";
import { CreateOnboardingBody, Onboarding } from "@entities";

export const fetchCreateOnboarding = (body: CreateOnboardingBody): Promise<Onboarding> =>
  axios.post(`${POCKET_API}/onboarding-store`, body)
    .then(resp => resp.data)
    .catch(() => false);

export const fetchGetOnboarding = (storeId: number): Promise<Onboarding[]> =>
  axios.get(`${POCKET_API}/onboarding-store/${storeId}`)
    .then(resp => resp.data);
