import { FC, ChangeEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styles from "./search-bar-provider.module.sass";
import useDebounce from '@hooks/use-debounce';
import useInputFieldValidation from '@hooks/use-input-field-validation';
import useProviderExpense from '@modules/expenses/hooks/use-provider-expense';
import { actions, expensesMicroEgressNameSelector, expensesUiSelector } from '@modules/expenses/store';
import { globalUserSelector } from '@config/store';

interface SearchBarProviderProps { }

const SearchBarProvider: FC<SearchBarProviderProps> = () => {
  const dispatch = useDispatch();
  const currentMicroEgressName = useSelector(expensesMicroEgressNameSelector);
  const user = useSelector(globalUserSelector);
  const { getSearchProviderList } = useProviderExpense();
  const { t } = useTranslation();
  const { hasError } = useInputFieldValidation();
  const { selectionSuggestion } = useSelector(expensesUiSelector);

  const handleProviderSearch = useCallback(({ target }: ChangeEvent<HTMLInputElement>): void => {
    dispatch(actions.setCurrentMicroEgressName(target.value));
    if (!target.value.trim()) dispatch(actions.setSearchProvidersResults([]));
    else getSearchProviderList(user.storeId, target.value);
    dispatch(actions.setSelectionSuggestion({ showList: !!target.value.trim() }));
  }, [dispatch, user.storeId, getSearchProviderList]);

  const { handleInputChange, value, changeValue} = useDebounce(handleProviderSearch);

  const onClose = useCallback(() => {
    handleInputChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
  }, [handleInputChange]);

  const handleOnClick = useCallback((): void => {
    dispatch(actions.setSelectionSuggestion({ hasSearching: true, isSelected: false }));
    dispatch(actions.setProviderSearchbarStatus('toTop'));
  }, [dispatch])

  const handleOnBackClick = useCallback((): void => {
    dispatch(actions.setSelectionSuggestion({ hasSearching: false, showList: false }));
    onClose();
    dispatch(actions.setProviderSearchbarStatus('toBottom'));
  }, [dispatch, onClose])

  useEffect(() => {
    if (selectionSuggestion?.isSelected) changeValue(currentMicroEgressName);
  }, [changeValue, selectionSuggestion, currentMicroEgressName])

  return (
    <div className={styles.wrapper}>
      <span className={`icon-back ${styles.backArrow} ${selectionSuggestion?.hasSearching && styles.active}`} onClick={() => handleOnBackClick()} data-testid='icon-back-click'/>
      <div className={`${styles.inputWrapper} ${hasError && styles.error} ${(!!value) && styles.active}`}>
        <span className={`icon-search ${styles.searchIcon}`} />
        <input
          className={styles.input}
          placeholder={t('Bolsillo.gasto.searchbar.placeholder')}
          onChange={handleInputChange}
          id='ui-search-bar'
          autoComplete='off'
          value={value}
          onClick={handleOnClick}
        />
        {((!!value && !selectionSuggestion?.isSelected ) || hasError) && (
          <span className={`icon-close ${styles.icon}`} onClick={onClose} id='ui-search-bar-icon' data-testid="close-icon" />
        )}
        </div>
    </div>
  );
}

export default SearchBarProvider;
