import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from './recently-used-providers.module.sass';
import useProviderExpense from '@modules/expenses/hooks/use-provider-expense';
import { SearchedProvider } from '@modules/expenses/entities';
import { mostRecentProvidersSelector } from '@modules/expenses/store';

interface RecentlyUsedProvidersProps { }

const RecentlyUsedProviders: FC<RecentlyUsedProvidersProps> = () => {
  const { t } = useTranslation();
  const { setProviderSelection, deleteMostRecentProvider } = useProviderExpense();
  const mostRecentProviders = useSelector(mostRecentProvidersSelector);

  const handleItemClick = (item: SearchedProvider): void => {
    setProviderSelection(item, false);
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>
        {t('Bolsillo.gasto.content2')}
      </span>
      <div className={styles.items}>
      {mostRecentProviders?.length ? (mostRecentProviders.map((item) => (
        <div className={styles.item} key={`auto-complete-item-${item.id}`}>
          <div className={styles.itemInfo}>
            <div className={styles.left} onClick={() => handleItemClick(item)}>
              <span className={`icon-credit-clock ${styles.history}`} />
              <span className={styles.text} >{item.name}</span>
            </div>
            <div className={styles.right}>
              <span
                className={`icon-close ${styles.delete}`}
                onClick={() => deleteMostRecentProvider(Number(item.id))}
                data-testid='delete-provider-icon'
              />
              <span className={`icon-arrow-1 ${styles.icon}`} />
            </div>
          </div>
        </div>
      ))) : (
        <span className={styles.emptyState}>{t('expenses.provider_most_recent_empty_state_text')}</span>
      )
    }
      </div>
    </div>
  )
}

export default RecentlyUsedProviders;
