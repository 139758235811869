import { INITIAL_BALANCE_STATE, INITIAL_TRANSACTIONS_STATE } from "@constants";
import { Balances, ContactPhone, GetTransactionsResponse, Header, Notification, PaymentMethod } from "@entities";

export interface GlobalState {
  ui: GlobalUiState;
  data: GlobalDataState;
}

export interface GlobalUiState {
  notification: Notification;
  header: Header;
  isLoading: boolean;
  hasGlobalError: boolean;
  isReadyToUse: boolean;
  contactsPermissionGranted: boolean;
}

export interface UserState {
  storeId: number;
  id?: string;
}

export interface OnboardingDataState {
  pushNotificationId: number;
  done: number[];
  homeStepsCompleted: number[];
}

export interface GlobalDataState {
  user: UserState;
  balances: Balances;
  transactions: GetTransactionsResponse;
  paymentMethods: PaymentMethod[];
  contacts: ContactPhone[];
  onboarding: OnboardingDataState;
}

export const initialState: GlobalState = {
  ui: {
    isReadyToUse: false,
    notification: {
      show: false,
      message: 'Message',
      state: 'alert'
    },
    header: {
      title: '',
      rightIcon: 'close',
      hasBackClick: false,
      hasRightClick: false,
      routeOnBackClick: '/',
      routeOnRightClick: '/',
    },
    isLoading: false,
    hasGlobalError: false,
    contactsPermissionGranted: false,
  },
  data: {
    user: { storeId: 0, id: '' },
    balances: INITIAL_BALANCE_STATE,
    transactions: INITIAL_TRANSACTIONS_STATE,
    paymentMethods: [],
    contacts: [],
    onboarding: { 
      pushNotificationId: 0,
      done: [],
      homeStepsCompleted: [], 
    },
  }
};
