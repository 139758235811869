import React, { ChangeEvent, FC, memo } from 'react';
import styles from './ui-switch.module.sass';

interface UiSwitchProps {
  isActive: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void    
}

const UiSwitch: FC<UiSwitchProps> = ({ isActive, onChange }) => {
  return (
    <label className={styles.switch} data-testid='ui-switch'>
      <input
        className={styles.input}
        type='checkbox'
        role='switch'
        checked={isActive}
        onChange={onChange}
      />
      <span className={`${styles.slider} ${styles.round}`} />
    </label>
  )
}

export default memo(UiSwitch);