import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useFormatCurrency from "@hooks/use-format-currency";
import useNotification from "@hooks/use-notification";
import { baseDataSelector } from "@modules/base/store";
import { actions as globalActions, globalBalancesSelector, globalUserSelector } from "@config/store";
import { fetchCreateNewBase } from "@modules/base/services";
import { BASE_ONBOARDING_ID, BASE_TRANSACTION_TYPE_ID, ROUTES } from "@constants";
import { BASE_DEFAULT_IMAGE, BASE_DEFAULT_REASON, BASE_PAYMENT_METHOD } from "@modules/base/constants";
import { Base } from "@modules/base/entities";
import { useNavigate } from "react-router-dom";
import useUpdateWallet from "@hooks/use-update-wallet";
import { showEcommerceTabs } from "@utils/webview";
import useOnboarding from "@hooks/use-onboarding";
import Clevertap, { MOVEMENT_COMPLETE } from "@config/clevertap";

const useBaseFetch = () => {
  const dispatch = useDispatch();
  const data = useSelector(baseDataSelector);
  const { format } = useFormatCurrency();
  const { t } = useTranslation();
  const balances = useSelector(globalBalancesSelector);
  const user = useSelector(globalUserSelector);
  const navigate = useNavigate();
  const showNotification = useNotification();
  const { setNewBase } = useUpdateWallet();
  const { setBaseOnboardingDone, isOnboardingDone } = useOnboarding();

  const createNewBase = useCallback((): void => {
    const body: Base = {
      transactionTypeId: BASE_TRANSACTION_TYPE_ID,
      paymentMethodId: BASE_PAYMENT_METHOD,
      total: +data.currentAmount,
      userId: user.storeId,
      storeId: user.storeId,
      cashDeckControlId: balances.cashId,
      description: !data.currentReason ? t(BASE_DEFAULT_REASON) : data.currentReason,
      imageUrl: BASE_DEFAULT_IMAGE
    };

    dispatch(globalActions.setIsLoading(true));

    fetchCreateNewBase(body)
      .then((id) => {
        showEcommerceTabs();
        setNewBase(id, body);
        showNotification(t(!data.currentReason
          ? "base_module.notification_first_base_message"
          : "base_module.notification_message", {
          amount: format(+data.currentAmount, true)
        }), true);

        if (!isOnboardingDone(BASE_ONBOARDING_ID)) setBaseOnboardingDone(body.total);
        
        Clevertap.pushEvent(MOVEMENT_COMPLETE, {
          type: BASE_TRANSACTION_TYPE_ID,
          value: body.total,
          paymentMethod: body.paymentMethodId,
          otherDescription: body.description
        });

        navigate(ROUTES.home);
      })
      .catch(() => showNotification(t("base_module.notification_message_error"), false))
      .finally(() => dispatch(globalActions.setIsLoading(false)));
  }, [balances, data, dispatch, format, isOnboardingDone, navigate, setBaseOnboardingDone, setNewBase, showNotification, t, user]);

  return { createNewBase };
};

export default useBaseFetch;
