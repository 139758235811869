import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { globalTransactionsCompleteSelector } from '@config/store';
import usePagination from '@modules/home/hooks/use-pagination';
import UiPaginator from '@ui/ui-paginator';

interface HomePaginatorProps { }

const HomePaginator: FC<HomePaginatorProps> = () => {
  const { currentPage, totalPages } = useSelector(globalTransactionsCompleteSelector);
  const { getPage, isLoading } = usePagination();

  return (
    <UiPaginator
      onClick={getPage}
      isLoading={isLoading}
      hideButton={currentPage === totalPages}
    />
  );
}

export default HomePaginator;
