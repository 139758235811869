export const messages = {
  spanish: {
    "notification": {
      "message": "Fiado a {{customer}} por {{amount}}",
      "error": "Lo sentimos. Ha ocurrido un error y no pudimos registrar su transacción",
      "payment": "Abono por {{amount}} registrado exitosamente",
      "total_payment": "Abono deuda completa registrado exitosamente",
      "creation": "Cliente creado con éxito",
      "edition": "Cliente editado con éxito",
      "transaction": "Monto ingresado con éxito",
    },
    "whatsapp": {
      "partial_payment": "¡Hola {{name}} 👋,  acabas de abonar {{amount}} a tu deuda. Tu nueva deuda es de {{totalDebt}}.",
      "total_payment": "¡Hola {{name}} 👋, realizaste un abono total de tu deuda por {{amount}}. Ya no tienes deudas",
      "new_loan": "¡Hola {{name}} 👋, acabas de realizar una compra fiada por {{amount}}, tu nueva deuda es de {{totalDebt}}.",
      "new_payment": "¡Hola {{name}} 👋, confirmo que recibí tu pago, que tengas un excelente resto de día",
    },
    "error": {
      "transaction": "Lo sentimos. Ha ocurrido un error y no pudimos registrar su transacción",
      "customer_update": "Lo sentimos. No pudimos actualizar el cliente",
      "customer_delete": "Lo sentimos. No pudimos eliminar el cliente",
      "customer_creation": "Lo sentimos. No pudimos crear el nuevo cliente",
      "customer_already_exist": "Ya tienes un cliente registrado con el número {{phone}} en tu lista",
      "get_customers": "Lo sentimos. No pudimos obtener la lista de clientes",
      "get_detail": "Lo sentimos. No pudimos obtener la información de su cliente",
    },
    "unsuccessful": {
      "search_results": "No se encontraron coincidencias",
      "customers_registers": "No tienes clientes registrados",
    },
  },
  colombia: {
    "whatsapp_reminder": {
      "message": "¡Hola {{name}} 👋, Recuerda que tienes una deuda pendiente con la tienda, puedes acercarte a la tienda y cancelar el saldo.! ¡Muchas gracias y feliz día!",
    },
  },
  mexico: {
    "whatsapp_reminder": {
      "message": "¡Hola {{name}} 👋, Recuerda que tienes una deuda pendiente con la tienda, puedes acercarte a la tienda y cancelar el saldo.! ¡Muchas gracias y feliz día! :D",
    },
  },
  brazil: {
    "whatsapp_reminder": {
      "message": "Olá {{name}} 👋, Lembre que tem uma dívida pendente com a loja, pode ir à loja e cancelar o saldo! ¡Muito obrigado e um ótimo dia!",
    },
  },
  portuguese: {
    "notification": {
      "message": "Fiado de {{amount}} para {{customer}}",
      "error": "Desculpe. Ocorreu um erro e não pudemos registrar sua transação.",
      "payment": "Pagamento de {{amount}} registrado com sucesso",
      "total_payment": "Pagamento da dívida completa registrado com sucesso",
      "creation": "Cliente creado con éxito",
      "edition": "Cliente editado con éxito",
      "transaction": "Monto ingresado con éxito",
    },
    "whatsapp": {
      "partial_payment": "Olá {{name}} 👋,  acaba de descontar {{amount}} de sua dívida. Sua nova dívida é de {{totalDebt}}.",
      "total_payment": "Olá {{name}} 👋, realizou um pagamento total de sua dívida de {{amount}}. Já está livre de dívidas",
      "new_loan": "Olá {{name}} 👋, acaba de realizar uma compra em fiado de {{amount}}, sua nova dívida é de {{totalDebt}}.",
      "new_payment": "Olá {{name}} 👋, confirmo que recebi seu pagamento, tenha um ótimo resto de dia",
    },
    "error": {
      "transaction": "Desculpe. Ocorreu um erro e não pudemos registrar sua transação.",
      "customer_update": "Desculpe. Não pudemos atualizar o cliente",
      "customer_delete": "Desculpe. Não pudemos excluir o cliente",
      "customer_creation": "Desculpe. Não pudemos criar o novo cliente",
      "customer_already_exist": "Você já tem um cliente registrado com o número {{phone}} em sua lista",
      "get_customers": "Desculpe. Não pudemos obter a lista de clientes",
      "get_detail": "Desculpe. Não pudemos obter as informações do seu cliente",
    },
    "unsuccessful": {
      "search_results": "Nenhum resultado foi encontrado.",
      "customers_registers": "Você não tem clientes registrados.",
    },
  }
}
