import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { homeUiFilterCalendarSelector } from '@modules/home/store';
import FiltersSwitchesWrapper from '@modules/home/components/home-filters-switches-wrapper';
import FiltersButtons from '@modules/home/components/home-filters-buttons';
import UiDivider from '@ui/ui-divider';

interface HomeFiltersContentProps { }

const HomeFiltersContent: FC<HomeFiltersContentProps> = () => {
  const showCalendar = useSelector(homeUiFilterCalendarSelector);

  if (showCalendar) return <div />

  return (
    <>
      <UiDivider />

      <FiltersSwitchesWrapper />

      <FiltersButtons />
    </>
  );
}

export default HomeFiltersContent;
