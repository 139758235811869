import { ChangeEvent, FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './home-search.module.sass';
import useDebounce from '@hooks/use-debounce';
import useSearchTransactions from '@modules/home/hooks/use-search-transactions';
import UiPaginator from '@ui/ui-paginator';
import UiNoDataMessage from '@ui/ui-no-data-message';
import UiTransactionList from '@ui/ui-transaction-list';
import LoaderSkeleton from '@modules/home/components/home-loader-skeleton';
import Clevertap, { SEARCH_MOVEMENT } from '@config/clevertap';
import { ROUTES } from '@constants';

interface HomeSearchProps { }

const HomeSearch: FC<HomeSearchProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const ref = useRef<HTMLInputElement>(null);
  const { hasNoResults, isLoading, hasNextPage, results, search, isLoadingNextPage, getNextPage } = useSearchTransactions();

  const handleHomeSearch = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    if (!!target.value.trim()) {
      search(target.value);
      setPage(0);
      ref.current?.blur();
      Clevertap.pushEvent(SEARCH_MOVEMENT, { search: target.value.trim() });
      window.scrollTo(0, 0);
    }
  };

  const handleNextPage = () => {
    getNextPage(page + 1);
    setPage(page + 1);
  };

  const { handleInputChange } = useDebounce(handleHomeSearch);

  const displayResults = () => {
    return hasNoResults
      ? <UiNoDataMessage message={t('home.search.no_data_message')} />
      : (
        <>
          <UiTransactionList transactions={results} />
          <UiPaginator
            hideButton={!hasNextPage()}
            isLoading={isLoadingNextPage}
            onClick={handleNextPage}
          />
        </>
      )
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span
          className={`icon-back ${styles.iconLeft}`}
          onClick={() => navigate(ROUTES.home)}
          data-testid='header-left-click'
        />
        <div className={`${styles.wrapper} ${hasNoResults && styles.noResults}`}>
          <span className={`icon-search ${styles.searchIcon}`} />
          <input
            ref={ref}
            placeholder={t('Bolsillo.dashboard.searchbar.placeholder')}
            onChange={handleInputChange}
            className={styles.input}
            autoComplete='off'
            autoFocus
          />
        </div>
      </div>

      {isLoading
        ? <LoaderSkeleton />
        : displayResults()
      }

    </div>
  );
}

export default HomeSearch;
