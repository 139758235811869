import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styles from './debts-providers-list.module.sass'
import UiMoneyText from '@ui/ui-money-text';
import { providerSearchedValueSelector, providersWithCreditSelector } from '@modules/credits/store';
import { PATHS } from '@constants';
import { useNavigate } from 'react-router-dom';

interface DebtsProvidersListProps { }

const DebtsProvidersList: FC<DebtsProvidersListProps> = () => {
  const { t } = useTranslation();
  const searchValue = useSelector(providerSearchedValueSelector);
  const navigate = useNavigate();
  const providersWithCredit = useSelector(providersWithCreditSelector);

  const list = useMemo(() => (
    providersWithCredit.filter(e => e.provider.toLowerCase().includes(searchValue.toLowerCase().trim()) && !!e.total)
  ), [providersWithCredit, searchValue]);

  return (
    <>
      {list.map((item) => (
        <div className={styles.item} key={`date-item-${item.id}`}>
          <span className={`icon-egress ${styles.icon}`} />
          <div className={styles.description}>
            {item.provider}
            <span className={styles.subtitle}>
              {t('credits.my_doubts.subtitle_provider_card_text')}
            </span>
          </div>

          <UiMoneyText value={item.total} type='info' isSmall />

          <span 
            className={`icon-back ${styles.arrow}`} 
            onClick={() => navigate(`/${PATHS.credits}/${item.id}`)} 
            data-testid='arrow-icon'
          />
        </div>
      ))}
    </>
  )
}

export default DebtsProvidersList;
