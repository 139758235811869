import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { customerDetailSelector } from "@modules/customers/store";

import { useTranslation } from "react-i18next";
import styles from "./customer-detail-transactions-list.module.sass";
import CustomersEmptyState from "../customers-empty-state";
import UiTransactionList from "@ui/ui-transaction-list";
import { LOAN_TRANSACTION_TYPE_ID } from "@constants";
import { TransactionsByDate } from "@entities";
import { format } from "date-fns";

interface CustomerDetailTransactionsListProps { }

const CustomerDetailTransactionsList: FC<CustomerDetailTransactionsListProps> = () => {
  const { t } = useTranslation();
  const customerDetail = useSelector(customerDetailSelector);

  const transactions: TransactionsByDate[] = useMemo(() => customerDetail.transactions.map((e, i) => (
    {
      date: e.date,
      transactionsByDate: e.transactionsByDate.map(tr => ({
        total: tr.total,
        id: new Date().getTime() + i,
        description: t(tr.type === LOAN_TRANSACTION_TYPE_ID ? 'transactions.loan' : 'transactions.debt_payment'),
        imageUrl: '',
        subTitle: format(new Date(), 'yyyy-MM-dd hh:mm aa').slice(-8).toUpperCase(),
        transactionTypeId: tr.type,
        paymentMethodId: 1,
      }))
    }
  )), [customerDetail.transactions, t]);
    
  return (
    <div className={styles.wrapper}>
      {!!customerDetail.transactions?.length
        ? (
          <div className={styles.transactionsWrapper}>
            <UiTransactionList transactions={transactions} isSpecific withoutAnimation />
          </div>
        ) : (
          <div className={styles.emptyState}>
            <CustomersEmptyState
              title={t('Bolsillo.detalleCliente.deudaCliente.subTitulo1')}
              text={t('Bolsillo.detalleCliente.deudaCliente.cuerpo1')}
            />
          </div>

        )}
    </div>
  );
};

export default CustomerDetailTransactionsList;
