import { Balances, GetTransactionsResponse } from "@entities";

export enum MoneyTextTypes {
  expense = 'expense',
  normal = 'normal',
  info = 'info',
  deletion = 'deletion'
}

export enum TransactionType {
  balance = 'balance',
  expense = 'expense',
  income = 'income',
  base = 'base',
  closure = 'closure',
  credit = 'credit',
  paymentCredit = 'paymentCredit',
  loan = 'loan',
  paymentLoan = 'paymentLoan',
  deletion = 'deletion'
}

export const SHARED_PAYMENT_METHODS = [
  {
    id: "1",
    name: "Efectivo",
    imageUrl: "https://i.ibb.co/XZ95pzm/efectivo.png",
    createdAt: '',
    updatedAt: '',
    deletedAt: ''
  },
  {
    id: "5",
    name: "Tarjeta",
    imageUrl: "https://i.ibb.co/2NZk66M/tarejta.png",
    createdAt: '',
    updatedAt: '',
    deletedAt: ''
  },
  {
    id: "6",
    name: "Credito",
    imageUrl: "https://i.ibb.co/ccdXKqD/cr-dito.png",
    createdAt: "2022-04-18T19:30:53.000Z",
    updatedAt: "2022-04-18T19:30:53.000Z",
    deletedAt: ''
  }
];


export const CONTACT_LIST = [
  {
    contactId: 123,
    name: 'Migue',
    phone: '3057711660'
  },
  {
    contactId: 1234,
    name: 'Oscar',
    phone: '3224675745'
  },
  {
    contactId: 126,
    name: 'Jona V',
    phone: '3003664803'
  },
  {
    contactId: 129,
    name: 'Carlos Rubio',
    phone: '3188133092'
  },
  {
    contactId: 12969,
    name: 'Daniel',
    phone: '3162358461'
  },
  {
    contactId: 129639,
    name: 'Mariana',
    phone: '3137997958'
  }
];

export const INITIAL_BALANCE_STATE: Balances = {
  cashId: 0,
  balance: 0,
  income: 0,
  egress: 0,
  debt: 0,
  loan: 0,
  base: 0,
  startTime: '',
  hasBase: false
};

export const INITIAL_TRANSACTIONS_STATE: GetTransactionsResponse = {
  currentPage: 0,
  totalPages: 0,
  totalTransactions: 0,
  data: []
};

export const UXCAM_SCREENS = {
  home: 'HomeDashboardPocket',
  base: 'MovementBasePocket',
  income: 'MovementEntryPocket',
  expense: 'MovementEgressPocket',
  loan: 'MovementDebtPocket',
  box_closure: 'MovementCloseCashPocket'
};