import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@modules/expenses/store';
import { Expenses, PUBLIC_SERVICE_TABS, SERVICE_AMOUNT_STEP } from '@modules/expenses/constants';
import InputAmount from '@modules/expenses/components/input-amount';
import { ROUTES } from '@constants';

interface PublicServiceAmountProps { }

const PublicServiceAmount: FC<PublicServiceAmountProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setStep(SERVICE_AMOUNT_STEP));
    dispatch(actions.setFooter({
      routeToNext: ROUTES.home,
      routeToBack: `${Expenses.public_service}/${PUBLIC_SERVICE_TABS.paymentMethod}`,
      hasSubmitFunction: true,
    }));
    return () => { dispatch(actions.setFooter({ hasSubmitFunction: false })); }
  }, [dispatch]);

  return (
    <InputAmount />
  )
}

export default PublicServiceAmount;
