export const modification = {
  spanish: {
    "update": {
      "header_title": "Edición cliente",
      "customer_name_title": "Nombre cliente",
      "customer_phone_title": "Teléfono cliente",
      "customer_field_name": "Nombre y apellido",
      "customer_field_phone": "Número",
      "customer_save_button_text": "Guardar",
      "successful": "Cliente editado exitosamente",
    },
    "delete": {
      "with_debt_title": "¿Estás seguro de eliminar este contacto con deudas?",
      "without_debt_title": "¿Estás seguro de eliminar este contacto?",
      "successful": "Cliente eliminado exitosamente",
      "button_text": "Eliminar",
      "denied_confirmation_button_text": "No, volver",
    },
    "remainder": {
      "button_text": "Enviar recordatorio",
      "modal_title": "¿Deseas notificar via whatsapp?",
      "button_modal_text": "Si, enviar",
      "denied_confirmation_button_text": "No",
    }
  },
  portuguese: {
    "update": {
      "header_title": "Edição de cliente",
      "customer_name_title": "Nome do cliente",
      "customer_phone_title": "Telefone do cliente",
      "customer_field_name": "Nome e sobrenome",
      "customer_field_phone": "Número",
      "customer_save_button_text": "Salvar",
      "successful": "Cliente editado com sucesso",
    },
    "delete": {
      "with_debt_title": "Tem certeza que deseja excluir este contato com dívidas?",
      "without_debt_title": "Tem certeza que deseja excluir este contato?",
      "successful": "Cliente removido com sucesso",
      "button_text": "Excluir",
      "denied_confirmation_button_text": "Não, voltar",
    },
    "remainder": {
      "button_text": "Enviar recordatório",
      "modal_title": "Deseja notificar via whatsapp?",
      "button_modal_text": "Sim, enviar",
      "denied_confirmation_button_text": "Não",
    }
  },
}
