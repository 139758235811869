import { FC, memo } from 'react';
import styles from './ui-no-data-message.module.sass';
import NoData from '@img/no-data.png';

interface UiNoDataMessageProps {
  message: string,
}

const UiNoDataMessage: FC<UiNoDataMessageProps> = ({ message }) => {
  return (
    <div className={styles.wrapper} data-testid="no-data-message">
      <img src={NoData} alt='No Data' className={styles.image} />
      <p className={styles.message}>{message}</p>
    </div>
  );
}

export default memo(UiNoDataMessage);