import { FC, useCallback, useEffect, ChangeEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useCreditsFetch from "@modules/credits/hooks";
import { actions, creditPaymentSelector, creditsDetailSelector } from '@modules/credits/store';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@constants';
import { actions as globalActions, globalUiLoaderSelector } from '@config/store';
import useInputFieldValidation from '@hooks/use-input-field-validation';
import styles from "./credit-payment.module.sass";
import UiProgressBar from '@ui/ui-progress-bar';
import UiSteps from '@ui/ui-steps';
import UiInputMoney from '@ui/ui-input-money';
import UiFooter from "@ui/ui-footer";
import { PROGRESS_BAR } from '@modules/credits/constants';

interface CreditPaymentProps { }

const CreditPayment: FC<CreditPaymentProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const provider = useSelector(creditsDetailSelector);
  const { providerId } = useParams();
  const paymentTotal = useSelector(creditPaymentSelector);
  const { t } = useTranslation();
  const { hasError, validateInputMoney } = useInputFieldValidation();
  const { createNewCreditPayment } = useCreditsFetch();
  const isLoading = useSelector(globalUiLoaderSelector);

  useEffect(() => {
    dispatch(globalActions.header({
      title: t('credits.payment.header_title'),
      hasBackClick: false,
    }));
    dispatch(actions.setPaymentTotal(''));
  }, [dispatch, t]);

  const handleBackButtonClick = useCallback((): void => {
    navigate(`${ROUTES.credits}/${providerId}`)
  }, [navigate, providerId]);

  const handleInputChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>): void => {
    validateInputMoney(+target.value, t('credits.transaction_name'), provider.debt);
    dispatch(actions.setPaymentTotal(target.value));
  }, [dispatch, provider.debt, t, validateInputMoney]);

  return (
    <>
      <UiProgressBar {...PROGRESS_BAR} />

      <div className={styles.wrapper}>
        <UiSteps stepName={t("credits.payment.title")} />

        <div className={styles.amount}>
          <UiInputMoney
            name="amount"
            hasAutoFocus
            value={paymentTotal}
            onChange={handleInputChange}
            hasError={hasError}
          />
        </div>

        <UiFooter
          isDisabled={!paymentTotal || hasError}
          onLeftClick={handleBackButtonClick}
          onRightClick={createNewCreditPayment}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}

export default CreditPayment;
