import { ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { globalBalancesSelector } from "@config/store";
import { MAX_AMOUNT } from "@constants";
import { actions, boxClosureEditionUiSelector, boxClosureFormSelector, boxClosureInitialFormSelector } from "@modules/box-closure/store";
import useFormatCurrency from "@hooks/use-format-currency";
import useNotification from "@hooks/use-notification";
import { EditionTypeId } from "@modules/box-closure/entities";
import { NONE_EDITION_ID } from "@modules/box-closure/constants";

const useFormEdition = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const balances = useSelector(globalBalancesSelector);
  const activeEditionId = useSelector(boxClosureEditionUiSelector);
  const { format } = useFormatCurrency();
  const showNotification = useNotification();
  const form = useSelector(boxClosureFormSelector);
  const initialForm = useSelector(boxClosureInitialFormSelector);

  const isEditionActive = activeEditionId !== NONE_EDITION_ID;

  const restartForm = useCallback(() => {
    dispatch(actions.setForm({
      incomes: balances.income,
      expenses: balances.egress,
      base: balances.base,
      debts: balances.debt,
      loans: balances.loan
    }))
  }, [balances, dispatch]);

  const handleValueChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>, propName: string = '') => {
    const isValid = +target.value <= MAX_AMOUNT;
    if (isValid) dispatch(actions.setForm({ [propName]: +target.value }));
    else showNotification(t('box_closure.notification.validation_error', { max: format(MAX_AMOUNT, true) }), false)
  }, [dispatch, format, showNotification, t])

  const openEdition = useCallback((id: EditionTypeId) => {
    dispatch(actions.setIsEditionActiveId(id));
    dispatch(actions.setInitialForm(form));
  }, [dispatch, form]);

  const closeEditon = useCallback(() => {
    dispatch(actions.setIsEditionActiveId(NONE_EDITION_ID));
  }, [dispatch]);

  const cancelEdition = useCallback(() => {
    dispatch(actions.setForm(initialForm));
    closeEditon();
  }, [closeEditon, dispatch, initialForm]);

  const updateValues = useCallback(() => {
    closeEditon();
    showNotification(t('box_closure.notification.update_values_success'), true);
  }, [closeEditon, showNotification, t]);

  return {
    restartForm,
    handleValueChange,
    openEdition,
    closeEditon,
    cancelEdition,
    updateValues,
    isEditionActive
  };
}

export default useFormEdition;
