import React, { FC, useCallback, useEffect, useRef } from 'react';
import { isAfter } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actions } from '@modules/home/store';
import { actions as globalActions } from '@config/store';
import { isRangeError } from '@modules/home/utils';
import UiButton from '@ui/ui-button';
import UiDatePicker from '@ui/ui-date-picker';
import styles from './home-filters-date-picker.module.sass';
import { MAX_FILTER_RANGE } from '@modules/home/constants';

interface HomeFiltersDatePickerProps {
  setDates: (initDate: Date, endDate?: Date) => void;
  init?: Date;
  end?: Date;
}

const currentMonth = new Date().getMonth() + 1;

const HomeFiltersDatePicker: FC<HomeFiltersDatePickerProps> = ({ setDates, init, end }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!ref.current) ref.current.scrollTop = 2000;
    dispatch(actions.setMustApplyFilters(false));
  }, [dispatch]);

  const handleClick = useCallback((newDate: Date) => {
    if ((!!init && !!end) || !init) setDates(newDate)
    else if (isAfter(newDate, init)) setDates(init, newDate)
    else setDates(newDate, init);

  }, [end, init, setDates]);

  const handleSubmitClick = useCallback(() => {
    dispatch(actions.setMustApplyFilters(true));
    dispatch(actions.setFiltersDate({
      initDate: init?.toString(),
      endDate: end?.toString()
    }));
    dispatch(actions.setShowCalendar(false));
  }, [dispatch, end, init]);

  useEffect(() => {
    if (isRangeError(init, end)) {
      dispatch(globalActions.notification({
        message: t('home.filters.range_error_text', { max: MAX_FILTER_RANGE }),
        show: true,
        state: 'alert'
      }))
    }
  }, [dispatch, end, init, t]);

  const isDisabled = () => {
    return !init || !end || isRangeError(init, end);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.pickers} ref={ref}>
        {[5, 4, 3, 2, 1, 0].map(e => (
          <UiDatePicker
            key={`date-picker-${e}`}
            month={currentMonth - e}
            year={new Date().getFullYear()}
            onClick={handleClick}
            maxDate={new Date()}
            initDate={init}
            endDate={end}
          />
        ))}
      </div>

      <div className={styles.button}>
        <UiButton
          text={t('home.filters.picker_submit_text')}
          size='large'
          isDisabled={isDisabled()}
          onClick={handleSubmitClick}
        />
      </div>
    </div>
  );
}

export default HomeFiltersDatePicker;
