import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, expensesUiSelector } from '@modules/expenses/store';
import { Expenses, PROVIDER_NAME_STEP, PROVIDER_TABS } from '@modules/expenses/constants';
import { actions as globalActions } from '@config/store';
import styles from './provider-expenses-selection-name.module.sass';
import TopUsedProviders from '@modules/expenses/components/top-used-providers';
import RecentlyUsedProviders from '@modules/expenses/components/recently-used-providers';
import SearchProviderResults from '@modules/expenses/components/search-provider-results';
import SearchBarProvider from '@modules/expenses/components/search-bar-provider';

interface ProviderExpensesSelectionNameProps { }

const ProviderExpensesSelectionName: FC<ProviderExpensesSelectionNameProps> = () => {
  const dispatch = useDispatch();
  const { selectionSuggestion, providerSearchbarStatus } = useSelector(expensesUiSelector);

  useEffect(() => {
    dispatch(actions.setStep(PROVIDER_NAME_STEP));
    dispatch(actions.setFooter({
      routeToNext: `${Expenses.provider}/${PROVIDER_TABS.amount}`,
      routeToBack: `${Expenses.provider}/${PROVIDER_TABS.paymentMethod}`,
      isDisabled: true,
    }));
    dispatch(actions.setProviderSearchbarStatus('init'))
  }, [dispatch]);

  useEffect(() => {
    dispatch(globalActions.header({ isinvisible: selectionSuggestion.hasSearching }));
  }, [dispatch, selectionSuggestion.hasSearching]);

  return (
    <div className={`${styles.wrapper} ${selectionSuggestion.hasSearching && styles.topSearchBar} ${styles[providerSearchbarStatus]}`}>
      <SearchBarProvider />

      <SearchProviderResults />

      {(!selectionSuggestion?.isSelected && !selectionSuggestion.showList) && (
        <>
          <TopUsedProviders />

          <RecentlyUsedProviders />
        </>
      )}
    </div>
  )
}

export default ProviderExpensesSelectionName;
