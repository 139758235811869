import { GetTransactionsResponse } from "@entities";
import { INIT_FILTERS_STATE } from "../constants";

export interface HomeState {
  ui: HomeUiState;
  data: HomeDataState;
}

export interface HomeUiState {
  showCalendar: boolean;
  isFilterActive: boolean;
  mustApplyFilters: boolean;
}

export interface HomePaginationState {
  currentPage: number;
  totalPages: number;
}

export interface HomeDateState {
  initDate: string;
  endDate: string;
}

export interface HomeFiltersState {
  range: HomeDateState;
  movements: number[];
  results: GetTransactionsResponse;
}

export interface HomeDataState {
  searchValue: string;
  pagination: HomePaginationState;
  filters: HomeFiltersState;
}

export const initialState: HomeState = {
  ui: {
    showCalendar: false,
    isFilterActive: false,
    mustApplyFilters: false,
  },
  data: {
    searchValue: '',
    pagination: { currentPage: 0, totalPages: 0 },
    filters: INIT_FILTERS_STATE
  }
}
