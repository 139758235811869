import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from '@constants';
import { TABS } from '../constants';
import HeaderOutlet from '@router/header-outlet';
import HomeDashboard from '../pages/home-dashboard';
import HomeFilters from '../pages/home-filters';
import HomeSearch from '../pages/home-search';

interface HomeRouterProps { }

const HomeRouter: FC<HomeRouterProps> = () => {

  return (
    <Routes>
      <Route index element={<HomeDashboard />} />

      <Route path={TABS.search} element={<HomeSearch />} />

      <Route path='/*' element={<HeaderOutlet />}>
        <Route path={TABS.filters} element={<HomeFilters />} />
      </Route>

      <Route path='*' element={<Navigate to={ROUTES.home} />} />
    </Routes>
  );
}

export default HomeRouter;
