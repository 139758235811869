import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { actions } from '@config/store';
import { CONTACT_LIST, languageUrl } from '@constants';
import defaultTranslations from '@config/translations/es-CO.json'

const useWebview = () => {
  const dispatch = useDispatch();

  const getTranslations = useCallback((country: string) => {
    const addReources = (Bolsillo: object) => (
      i18next.addResourceBundle(country, 'translation', { Bolsillo })
    );

    fetch(String(languageUrl[country])).then(r => r.json())
      .then((data) => addReources(data.Bolsillo))
      .catch(() => addReources(defaultTranslations));
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const storeId = queryParams.get("storeId");
    const countryCode = queryParams.get("countryCode");
    const contacts = queryParams.get("contacts");
    const onboardingId = queryParams.get("ob");

    if (storeId) dispatch(actions.setUser({ storeId: +storeId }))
    if (contacts === 'true') {
      dispatch(actions.setContacts(CONTACT_LIST));
      dispatch(actions.setContactsPermissionGranted(true));
    }
    if (onboardingId) dispatch(actions.setOnboarding({ pushNotificationId: +onboardingId }));

    const lang = countryCode || i18next.languages[0];
    i18next.changeLanguage(lang);
    getTranslations(lang)
  }, [dispatch, getTranslations]);

  useEffect(() => {
    const handleWebviewMessage = (msg: any): void => {
      const data = JSON.parse(msg.data);
      if (data?.hasOwnProperty('language')) {
        const lang = data.language.toLowerCase()
        i18next.changeLanguage(lang || i18next.languages[0]);
        getTranslations(lang);
      }
      if (data?.hasOwnProperty('storeId')) {
        dispatch(actions.setUser({ storeId: +data.storeId, id: data.uid }));
      }
      if (data?.hasOwnProperty('contacts')) {
        const contacts = data.contacts
          .filter((e: any) => (!!e?.name && !!e?.name.trim() && !!e?.phone && !!e?.phone))
          .map((e: any, i: number) => ({
            name: e.name.trim(),
            phone: e.phone.trim().replace(new RegExp(/[^0-9]/g), '').slice(-i18next.t('country.phone_length')),
            contactId: i + 1
          }));
        dispatch(actions.setContacts(contacts));
      }
      if (data?.hasOwnProperty('contactsPermissionGranted')) {
        dispatch(actions.setContactsPermissionGranted(data.contactsPermissionGranted));
      }
      if (data?.hasOwnProperty('onboardingId')) {
        dispatch(actions.setOnboarding({ pushNotificationId: +data.onboardingId }));
      }
    };

    if (/iPad|iPhone|iPod/.test(navigator.userAgent))
      window.addEventListener('message', handleWebviewMessage);
    else document.addEventListener('message', handleWebviewMessage);
  }, [dispatch, getTranslations]);
};

export default useWebview;
