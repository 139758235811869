export const PAYMENT_TOTAL_STEPS = 1;

export const PROGRESS_BAR = {
  currentStep: PAYMENT_TOTAL_STEPS,
  totalSteps: PAYMENT_TOTAL_STEPS
};

export const TABS = {
  home: '',
  pay: 'pay',
  debts: 'debts',
};

export const PAYMENT_MACRO_TYPE_ID = 1;
