import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Clevertap, { CUSTOMER_CREATION, CUSTOMER_EDITION } from "@config/clevertap";
import { actions as globalActions, globalUserSelector } from "@config/store";
import { CUSTOMER_CREATION_ONBOARDING_ID, LOAN_TRANSACTION_TYPE_ID } from "@constants";
import useOnboarding from "@hooks/use-onboarding";
import useNotification from "@hooks/use-notification";
import { fetchCreateCustomer, fetchUpdateCustomer } from "@modules/customers/services";
import { actions, customerDetailSelector, customersDataSelector } from "@modules/customers/store";
import { CustomerCreationBody, CustomerSimpleForm, UpdateCustomerBody } from "@modules/customers/entities";
import { CUSTOMERS_DEFAULT_IMAGE, CUSTOMERS_PATH, CUSTOMER_ALREADY_EXIST, PHONE_ALREADY_EXIST, TABS } from "@modules/customers/constants";
import useUpdateWallet from "@hooks/use-update-wallet";

const useCustomerFormSubmit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showNotification = useNotification();
  const { setNewCustomerTransaction } = useUpdateWallet();
  const { isOnboardingDone, setCustomerCreationOnboardingDone } = useOnboarding();
  const customers = useSelector(customersDataSelector);
  const storeId = useSelector(globalUserSelector).storeId;
  const customerDetail = useSelector(customerDetailSelector);

  const postCreationRequest = (body: CustomerCreationBody) => {
    const creationDebt = body.loanDetail?.total || 0;
    dispatch(globalActions.setIsLoading(true));
    return fetchCreateCustomer(body)
      .then((id) => {
        const customerCreated = {
          customerId: +id,
          name: body.name,
          phone: String(body.phone),
          hasReminderSent: true,
        }

        dispatch(actions.setCustomers([{
          ...customerCreated,
          lastPaidLoan: !!creationDebt ? format(new Date(), 'dd/MM/yyyy') : '',
          totalDebt: creationDebt
        },
        ...customers]));
        
        const transactionId = new Date().getTime();
        dispatch(actions.setCustomerDetail({
          ...customerCreated,
          debt: creationDebt,
          transactions: !creationDebt ? [] : [{
            date: format(new Date(), 'dd MMM yyyy'),
            transactionsByDate: [{
              transactionId,
              date: new Date().toLocaleString(),
              total: body.loanDetail?.total || 0,
              type: LOAN_TRANSACTION_TYPE_ID,
            }]
          }]
        }));
        
        if (!!creationDebt) {
          setNewCustomerTransaction(String(transactionId), body.name, creationDebt, LOAN_TRANSACTION_TYPE_ID, +id);
        }

        dispatch(actions.setBackDetailRoute(CUSTOMERS_PATH));
        dispatch(actions.setisSearchBarActive(false));

        Clevertap.pushEvent(CUSTOMER_CREATION, { phone: body.phone, isFromContacts: !!creationDebt });
        return id;
      })
      .catch((res) => {
        showNotification((res?.response?.data?.message.includes(CUSTOMER_ALREADY_EXIST))
          ? t("customers.error.customer_already_exist", { phone: body.phone })
          : t("customers.error.customer_creation")
          , false);
        return Promise.reject();
      })
      .finally(() => dispatch(globalActions.setIsLoading(false)));
  }

  const createNewCustomer = (newCustomer: CustomerSimpleForm) => {
    const body: CustomerCreationBody = {
      ...newCustomer,
      phone: +newCustomer.phone,
      storeId
    }
    postCreationRequest(body)
      .then((customerId) => {
        navigate(`${TABS.transaction_amount}/${customerId}`)
        if (!isOnboardingDone(CUSTOMER_CREATION_ONBOARDING_ID)) {
          dispatch(actions.setIsCreationOb(true));
          setCustomerCreationOnboardingDone();
        }
      })
      .catch(() => false)
  }

  const createContactWithDebt = (newCustomer: CustomerSimpleForm, cashId: number, total: string) => {
    const body: CustomerCreationBody = {
      ...newCustomer,
      phone: +newCustomer.phone,
      storeId,
      loanDetail: {
        cashDeckControlId: cashId,
        total: +total,
        imageUrl: CUSTOMERS_DEFAULT_IMAGE
      }
    }
    return postCreationRequest(body);
  };

  const updateCustomer = (newCustomer: CustomerSimpleForm) => {
    const body: UpdateCustomerBody = { storeId, id: +customerDetail.customerId, ...newCustomer };

    dispatch(globalActions.setIsLoading(true));
    fetchUpdateCustomer(body)
      .then(() => {
        dispatch(actions.setCustomerDetail({ ...customerDetail, ...newCustomer }));

        dispatch(actions.setCustomers(customers.map(e =>
          e.customerId === +customerDetail.customerId ? { ...e, name: body.name, phone: body.phone } : e)
        ));
        dispatch(actions.setBackDetailRoute(CUSTOMERS_PATH));
        showNotification(t("customers.update.successful"), true);
        Clevertap.pushEvent(CUSTOMER_EDITION);
        navigate(-1);
      })
      .catch((res) => {
        showNotification((res?.response?.data?.message.includes(PHONE_ALREADY_EXIST))
          ? t("customers.error.customer_already_exist", { phone: newCustomer.phone })
          : t("customers.error.customer_update")
          , false);
      })
      .finally(() => dispatch(globalActions.setIsLoading(false)))
  }

  return {
    updateCustomer,
    createNewCustomer,
    createContactWithDebt
  }
}

export default useCustomerFormSubmit;
