export const base = {
  spanish: {
    "base": 'Base',
    "type": {
      "amount": 'Monto',
      "reason": 'Motivo',
    },
    "reason": {
      "loan": 'Préstamo'
    },
    "notification_first_base_message": 'Base por {{amount}} registrada exitosamente',
    "notification_message": 'Cambio de base por {{amount}} registrado exitosamente',
    "transaction_name": "base",
    "next_step": "Continuar",
    "submit": "Finalizar",
    "default_reason": "Cambio de base",
    "notification_message_error": "Lo sentimos. Ha ocurrido un error y no pudimos registrar su cambio de base",
    "home": {
      "actual" : 'Base actual',
      "update" : 'Actualizar',
      "transactions" : 'Movimientos'
    },
    "no_data": 'Aún no tiene movimientos registrados.',
    "tooltip": {
      "amount": 'Registra el valor de la base',
      "submit": 'Presiona confirmar'
    }
  },
  portuguese: {
    "base": 'Troco',
    "type": {
      "amount": 'Valor',
      "reason": 'Motivo',
    },
    "reason": {
      "loan": 'Empréstimo'
    },
    "notification_first_base_message": '{{amount}} registrado com sucesso em seu troco',
    "notification_message": 'Alteração de {{amount}} em seu troco registrado com sucesso',
    "transaction_name": "troco",
    "next_step": "Prosseguir",
    "submit": "Finalizar",
    "default_reason": "Alteração no troco",
    "notification_message_error": "Desculpe. Ocorreu um erro e não pudemos registrar sua alteração de troco",
    "home": {
      "actual" : 'Troco em caixa',
      "update" : 'Atualizar',
      "transactions" : 'Movimentos'
    },
    "no_data": 'Ainda não possui movimentos registradas.',
    "tooltip": {
      "amount": 'Registre o valor do troco',
      "submit": 'Pressione confirmar'
    }
  },
};
