export const customers = {
  spanish: {
    "header": {
      "title": "Me deben",
      "customer_title": "Fiar a {{customer}}",
    },
    "customer": {
      "title": "Detalle de cliente",
      "name": "Nombre",
      "phone": "Teléfono",
      "edit": "Editar",
      "phone_number": "Número de celular",
      "create_title": "Agregar cliente",
      "add_title": "Agregar nuevo cliente",
      "optional_clarification": "(opcional)"
    },
    "payment": {
      "action": "Abonar",
      "transaction_text": "Abono",
      "transaction_name": "abono",
    },
    "creation_successful": "Cliente creado exitosamente",
    "searchbar_placeholder": "Buscar en tus contactos",
    "footer_text": "Siguiente",
    "send_message_memo": "Enviar recordatorio",
    "amount_title": "Monto",
    "amount_creation_title": "Monto fiado",
    "transaction_name": "fiado",
    "total_debts": "Me deben",
    "details_title": "Historial del cliente",
    "loan_transaction_text": "Fiado",
    "customer_deleted_notification": "El cliente fue eliminado",
    "no_transactions_yet": 'Aún no tienes movimientos registrados',
    "loans_resume_title": 'Me deben',
    "loans_resume_placeholder": 'Buscar',
    "module_description_onboarding_text": "Selecciona las personas que quieres importar a tu bolsillo",
    "onboarding_amount_title": "Monto venta",
    "onboarding_success_message": "Ya sabes como fiar a un cliente!",
    "onboarding_tooltip_text": "Busca y selecciona el usuario para fiar un monto",
    "onboarding_second_tooltip_text": "Selecciona el usuario para fiar un monto",
    "onboarding_no_contacts_tooltip_text": "Crea un usuario para fiar un monto",
    "tour_tooltip": {
      "name": "Escribe el nombre del cliente",
      "phone": "Escribe el número del celular",
      "amount": "Registra el valor fiado",
      "next_step": "Presiona continuar",
      "submit": "Presiona finalizar"
    },
    "home": {
      "button": 'Crear nuevo cliente',
      "input": 'Buscar cliente',
      "total_debts": 'Deuda total',
      "my_customers": 'Mis clientes',
      "my_contacts": 'Mis contactos del celular',
      "my_contacts_card_loan": 'Fiar',
      "no_customers_title": 'Aún no tienes clientes en tu lista',
      "no_customers_text": 'Puedes crear un cliente nuevo o agregarlo a tu lista desde tu lista de contactos',
      "no_contacts_title": 'Necesitamos tu permiso',
      "no_contacts_text": 'Para mostrar a tus contactos del celular debes habilitar los permisos en configuración',
      "no_contacts_button": 'Ir a configuración',
      "no_results_title": 'No hay resultados',
      "no_results_text": 'Revisa si el nombre está bien escrito o prueba con un nombre diferente'
    },
    "headers": {
      "creation": "Crear nuevo cliente",
      "loan_creation": "Fiar a nuevo cliente",
      "edition": "Editar cliente",
      "loan": "Fiar a cliente",
      "payment": "Registrar pago",
      "loan_contact": "Fiar a contacto del celular"
    },
    "titles": {
      "loan_page": "Ingresa el monto que vas a fiar",
      "payment_page": "Ingresa el monto pagado",
      "detail_page": "Datos de contacto",
      "debts": "Deuda del cliente",
      "name": "Nombre y apellido",
      "phone": "Número",
      "total_debts": "Valor total"
    },
    "wp_checkbox_text": "Quiero notificar a mi cliente por WhatsApp",
    "form_field_name": "Nombre y apellido",
    "form_field_phone": "Número de contacto",
    "form_field_name_placeholder": "Escribe un nombre y apellido",
    "form_field_phone_placeholder": "3001234567",
    "submit_button_text": "Confirmar",
    "detail": {
      "text": {
        "total_debts": "Valor total",
        "edit": "Editar",
        "no_transactions": "Hasta el momento no le has fiado a este cliente",
        "button": {
          "payment": "Registrar pago",
          "loan": "Fiar",
        },
      },
      "title": {
        "name": "Nombre y apellido",
        "phone": "Número",
        "info": "Datos de contacto",
        "no_transactions": "Cliente sin movimientos",
        "total_debts": "Deuda del cliente",
        "transactions": "Historial del cliente"
      },
    },
    "without_transactions_card_subtitle": "Sin transacciones",
  },
  portuguese: {
    "header": {
      "title": "Me devem",
      "customer_title": "Vender fiado para {{customer}}",
    },
    "customer": {
      "title": "Detalhe do cliente",
      "name": "Nome",
      "phone": "Telefone",
      "edit": "Editar",
      "phone_number": "Número de celular",
      "create_title": "Adicionar cliente",
      "add_title": "Novo cliente",
      "optional_clarification": "(opcional)"
    },
    "payment": {
      "action": "Descontar",
      "transaction_text": "Pago",
      "transaction_name": "pago",
    },
    "creation_successful": "Cliente criado com sucesso",
    "searchbar_placeholder": "Buscar em seus contatos",
    "footer_text": "Prosseguir",
    "send_message_memo": "Alertar cliente",
    "amount_title": "Valor fiado",
    "amount_creation_title": "Valor",
    "transaction_name": "fiado",
    "total_debts": "Me devem",
    "details_title": "Histórico do cliente",
    "loan_transaction_text": "Fiado",
    "customer_deleted_notification": "O cliente foi excluído",
    "no_transactions_yet": 'Ainda não possui movimentos registrados',
    "loans_resume_title": 'Me devem',
    "loans_resume_placeholder": 'Buscar',
    "module_description_onboarding_text": "Selecione as pessoas que você quer importar para sua carteira",
    "onboarding_amount_title": "Valor de venda",
    "onboarding_success_message": "Já sabe como vender fiado a um cliente",
    "onboarding_tooltip_text": "Busque e selecione um cliente para vender um valor em fiado",
    "onboarding_second_tooltip_text": "Selecione o cliente para vender um valor em fiado",
    "onboarding_no_contacts_tooltip_text": "Crie um cliente para vender um valor em fiado",
    "tour_tooltip": {
      "name": "Escreva o nome do cliente",
      "phone": "Escreva o número do cliente",
      "amount": "Registre o valor da venda fiada",
      "next_step": "Pressione prosseguir",
      "submit": "Pressione finalizar"
    },
    "home": {
      "button": 'Criar novo cliente',
      "input": 'Buscar cliente',
      "total_debts": 'Dívida total',
      "my_customers": 'Meus clientes',
      "my_contacts": 'Meus contatos do celular',
      "my_contacts_card_loan": 'Fiar',
      "no_customers_title": 'Ainda não possui clientes em sua lista',
      "no_customers_text": 'Pode criar um cliente novo ou adicioná-lo desde sua lista de contatos',
      "no_contacts_title": 'Necessitamos sua permissão',
      "no_contacts_text": 'Para mostrar seus contatos do celular deve habilitar a permissão desde a configuração',
      "no_contacts_button": 'Ir a configuração',
      "no_results_title": 'Não há resultados',
      "no_results_text": 'Revise se o nome esta bem escrito ou tente um nome diferente'
    },
    "headers": {
      "creation": "Criar novo cliente",
      "loan_creation": "Fiar para novo cliente",
      "edition": "Editar cliente",
      "loan": "Fiar para cliente",
      "payment": "Registrar pagamento",
      "loan_contact": "Fiar para contato do celular"
    },
    "titles": {
      "loan_page": "Digite o valor que você vai fiar",
      "payment_page": "Digite o valor do pago",
      "detail_page": "Dados do contato",
      "debts": "Dívida do cliente",
      "name": "Nome e sobrenome",
      "phone": "Número",
      "total_debts": "Valor total"
    },
    "wp_checkbox_text": "Quero notificar meu cliente pelo WhatsApp",
    "form_field_name": "Nome e sobrenome",
    "form_field_phone": "Número de contato",
    "form_field_name_placeholder": "Escreva um nome e sobrenome",
    "form_field_phone_placeholder": "11123456789",
    "submit_button_text": "Confirme",
    "detail": {
      "text": {
        "total_debts": "Valor total",
        "edit": "Editar",
        "no_transactions": "Até o momento não foi vendido fiado a esse cliente",
        "button": {
          "payment": "Registrar pagamento",
          "loan": "Fiar",
        },
      },
      "title": {
        "name": "Nome e sobrenome",
        "phone": "Número",
        "info": "Dados do contato",
        "no_transactions": "Cliente sem transações",
        "total_debts": "Dívida do cliente",
        "transactions": "Histórico do cliente"
      },
    },
    "without_transactions_card_subtitle": "sem transações",
  },
};
