import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, currentPaymentMethodSelector } from '@modules/expenses/store';
import { globalPaymentMethodsSelector } from '@config/store';
import { PaymentMethod } from '@entities';
import { CREDIT_PAYMENT_METHOD_ID } from '@constants';
import UiSelectionList from '@ui/ui-selection-list';
import styles from './payment-methods.module.sass';
import { PAYMENT_METHOD_TOUR_STEP } from '@modules/expenses/constants';

interface PaymentMethodsProps {
  hasCreditOption?: boolean;
}

const PaymentMethods: FC<PaymentMethodsProps> = ({ hasCreditOption }) => {
  const dispatch = useDispatch();
  const currentPaymentMethodId = useSelector(currentPaymentMethodSelector);
  const paymentMethods = useSelector(globalPaymentMethodsSelector);

  useEffect(() => {
    dispatch(actions.setFooter({ isDisabled: !currentPaymentMethodId }));
  }, [currentPaymentMethodId, dispatch]);

  useEffect(() => {
    dispatch(actions.setTourStep(PAYMENT_METHOD_TOUR_STEP));
    return () => {
      dispatch(actions.setTourStep(0))
    }
  }, [dispatch]);

  const handleCurrentPayment = useCallback((item): void => {
    dispatch(actions.setCurrentPaymentMethod(item.id))
  }, [dispatch]);

  const getPaymentMethods = useMemo((): PaymentMethod[] => (
    hasCreditOption ? paymentMethods : paymentMethods.filter(e => e.id !== CREDIT_PAYMENT_METHOD_ID)
  ), [hasCreditOption, paymentMethods]);

  return (
    <div className={styles.wrapper}>
      <UiSelectionList
        list={getPaymentMethods}
        selected={currentPaymentMethodId}
        onItemClick={handleCurrentPayment}
      />
    </div>
  );
}

export default memo(PaymentMethods);
