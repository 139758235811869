import React, { FC, memo, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions, homeFiltersDateSelector, homeUiFilterCalendarSelector } from '@modules/home/store';
import styles from './home-filters-date-picker-fields.module.sass';
import FiltersDatePicker from '../home-filters-date-picker';
import { es } from 'date-fns/locale';

interface HomeFiltersDatePickerFieldsProps { }

const HomeFiltersDatePickerFields: FC<HomeFiltersDatePickerFieldsProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const range = useSelector(homeFiltersDateSelector);
  const showCalendar = useSelector(homeUiFilterCalendarSelector);
  const [initDate, setInitDate] = useState(range.initDate ? new Date(range.initDate) : undefined);
  const [endDate, setendDate] = useState(range.endDate ? new Date(range.endDate) : undefined)

  useEffect(() => {
    return () => { if (showCalendar) dispatch(actions.setShowCalendar(false)); }
  }, [dispatch, showCalendar]);

  useEffect(() => {
    if (!range.initDate && !range.endDate) {
      setInitDate(undefined);
      setendDate(undefined);
    }
  }, [dispatch, range]);

  const fields = [
    { isFocused: showCalendar && !initDate, label: 'init', date: initDate },
    { isFocused: showCalendar && !!initDate && !endDate, label: 'end', date: endDate },
  ];

  const handleClick = () => {
    if (!showCalendar) dispatch(actions.setShowCalendar(true));
  }

  const setDates = (init: Date, end?: Date) => {
    setInitDate(init);
    setendDate(end);
  }

  return (
    <>
      <div className={`${styles.wrapper} ${showCalendar && styles.top}`} onClick={handleClick}>
        <p className={styles.title}>{t('Bolsillo.dashboard.filters.title1')}</p>

        <div className={`${styles.pickers}`}>
          {fields.map(f => (
            <div className={`${styles.picker} ${f.isFocused && styles.focus}`} key={f.label}>

              <div className={styles.text}>
                {t(f.label === 'end' ? 'Bolsillo.dashboard.filters.datepicker.title2' : 'Bolsillo.dashboard.filters.datepicker.title1')}
                <span className={styles.date}>
                  {!!f.date && (
                    <span className={styles.show}>
                      {format(new Date(f.date), "dd MMM yyyy", { locale: es })}
                    </span>
                  )}
                </span>
              </div>

              <span className={`icon-calendar ${styles.icon}`} />
            </div>
          ))}
        </div>
      </div>

      {showCalendar && <FiltersDatePicker setDates={setDates} init={initDate} end={endDate} />}
    </>
  );
}

export default memo(HomeFiltersDatePickerFields);
