export const INCOME_ONBOARDING_ID = 1;
export const EXPENSES_ONBOARDING_ID = 2;
export const BASE_ONBOARDING_ID = 3;
export const CLOSURE_ONBOARDING_ID = 4;
export const LOAN_ONBOARDING_ID = 5;
export const CUSTOMER_CREATION_ONBOARDING_ID = 5;
export const CONTACT_LOAN_ONBOARDING_ID = 6;
export const FIRST_LOAN_ONBOARDING_ID = 7;
export const FIRST_PAYMENT_ONBOARDING_ID = 8;

export const COMPLETE_STATUS_ONBOARDING_ID = 1;
export const INCOMPLETE_STATUS_ONBOARDING_ID = 2;
export const SKIPPED_STATUS_ONBOARDING_ID = 3;

export const HOME_ONBOARDINGS = [
  INCOME_ONBOARDING_ID,
  EXPENSES_ONBOARDING_ID,
  BASE_ONBOARDING_ID,
  CLOSURE_ONBOARDING_ID,
  LOAN_ONBOARDING_ID,
  CUSTOMER_CREATION_ONBOARDING_ID,
];
