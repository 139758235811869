import React, { ChangeEvent, FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions as globalActions, globalUiLoaderSelector } from '@config/store';
import { Header } from '@entities';
import { MAX_AMOUNT } from '@constants';
import styles from './customers-transaction.module.sass';
import UiButton from '@ui/ui-button';
import UiInputMoney from '@ui/ui-input-money';
import UiRadio from '@ui/ui-radio';
import useInputFieldValidation from '@hooks/use-input-field-validation';
import CustomersTourTooltip from '../customers-tour-tooltip';

interface CustomersTransactionProps {
  header: Partial<Header>;
  title: string;
  onSubmit: (total: string, isWpChecked: boolean) => void;
  maxAmount?: number;
  obId: number;
}

const CustomersTransaction: FC<CustomersTransactionProps> = ({
  header, title, onSubmit, maxAmount = MAX_AMOUNT, obId
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(globalUiLoaderSelector);
  const { checkCustomersAmount } = useInputFieldValidation();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    dispatch(globalActions.header(header))
  }, [dispatch, header]);

  const handleSubmit = () => { onSubmit(value, isChecked) }

  const handleCheck = () => {
    if (!isDisabled()) setIsChecked(prev => !prev)
  }

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue(target.value);
    if (!!target.value) checkCustomersAmount(target.value, maxAmount, setError)
    else setError('')
  }

  const isDisabled = useCallback(() => !!error || !value, [error, value]);

  return (
    <div className={styles.wrapper}>
      <CustomersTourTooltip
        obId={obId}
        amount={value}
      />

      <p className={styles.title}>{title}</p>

      <UiInputMoney
        name='total'
        onChange={handleInputChange}
        value={value}
        hasAutoFocus
        hasError={!!error}
        errorMessage={error}
      />

      <div className={`${styles.radioWrapper} ${isDisabled() && styles.disabled}`} >
        <div className={styles.radio}>
          <UiRadio
            isChecked={isChecked}
            isSquare
            onChange={handleCheck}
            isDisabled={isDisabled()}
          />
        </div>
        <span onClick={handleCheck}>{t('Bolsillo.fiar.notificacion.mensaje1')}</span>
      </div>

      <div className={styles.button}>
        <UiButton
          text={t('Bolsillo.fiar.footerButton.confirmar')}
          size='large'
          onClick={handleSubmit}
          isDisabled={isDisabled()}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default memo(CustomersTransaction);
