import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { baseTransactionsSelector, baseUiSelector } from '@modules/base/store';
import { useTranslation } from 'react-i18next';
import styles from './transaction-list.module.sass';
import UiTransactionList from '@ui/ui-transaction-list';
import UiPaginator from '@ui/ui-paginator';
import usePagination from '@modules/base/hooks/use-pagination';
import UiNoDataMessage from '@ui/ui-no-data-message';

interface TransactionListProps { }

const TransactionList: FC<TransactionListProps> = () => {
  const { t } = useTranslation();
  const transactions = useSelector(baseTransactionsSelector);
  const { paginator } = useSelector(baseUiSelector);
  const { getPage, isLoading } = usePagination();

  const list = useMemo(() => (
    transactions.map(e => ({
      ...e, transactionsByDate: e.transactionsByDate.map(tr => ({
        ...tr, subTitle: tr.createdAt?.slice(-8).toUpperCase()
      }))
    }))
  ), [transactions]);

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>{t('base_module.home.transactions')}</span>

      {!transactions.length
        ? <UiNoDataMessage message={t('base_module.no_data')} />
        : (

          <>
            <UiTransactionList transactions={list} isSpecific={true} />

            <UiPaginator
              onClick={getPage}
              isLoading={isLoading}
              hideButton={paginator.currentPage === paginator.totalPages}
              delayToShow={1000}
            />
          </>
        )
      }

    </div>
  )
}

export default TransactionList;