import axios, { AxiosError, AxiosResponse } from 'axios';
import { INVALID_TOKEN_STATUS, POCKET_API, STORAGE_TOKEN } from '@constants';
import { Tokens } from '@entities';
import { actions, store } from '..';

axios.defaults.baseURL = process.env.REACT_APP_API_CHIPER;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(request => {
  const tokenString = localStorage.getItem(STORAGE_TOKEN);
  const tokens: Tokens = JSON.parse(tokenString || "{}");
  if (tokens.accessToken && request.headers) {
    request.headers['Authorization'] = `Bearer ${tokens.accessToken}`;
  }
  return request;
}, error => { return Promise.reject(error); });

interface QueueItem {
  resolve: (value?: string) => void;
  reject: (reason: string) => void;
}
const failedQueue: QueueItem[] = [];
let isRefreshing: boolean = false;

axios.interceptors.response.use((request: AxiosResponse) => request, async (error: AxiosError) => {
  if (error.response?.status === INVALID_TOKEN_STATUS) {
    try {
      if (isRefreshing) return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
        .then(() => axios(error.response!.config))
        .catch(err => Promise.reject(err));
      isRefreshing = true;

      const credentials = JSON.parse(localStorage.getItem(STORAGE_TOKEN) || '');
      const { data } = await axios.post<Tokens>(`${POCKET_API}/auth/refresh-token`, {
        customToken: credentials.customToken,
        storeId: credentials.token,
        uid: credentials.id
      });
      localStorage.setItem(STORAGE_TOKEN, JSON.stringify({ ...credentials, ...data }));

      failedQueue.forEach(request => request.resolve());
      isRefreshing = false;

      return axios(error.response.config);
    }
    catch {
      localStorage.removeItem(STORAGE_TOKEN);
      store.dispatch(actions.setHasGlobalError(true));
    }
  }
  return Promise.reject(error);
});
