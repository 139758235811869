import { BaseStepMap } from "../entities";

export const STEP_MAP: BaseStepMap = {
  1: {
    1: {
      title: 'base_module.tooltip.amount',
      x: 'center',
      y: 'top'
    },
    2: {
      title: 'base_module.tooltip.submit',
      x: 'right',
      y: 'bottom'
    }
  }
};