import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './others-expenses-type.module.sass';
import useInputFieldValidation from '@hooks/use-input-field-validation';
import { ROUTES } from '@constants';
import { actions as globalActions } from '@config/store';
import { actions, expensesUiSelector } from '@modules/expenses/store';
import { Expenses, OTHERS_REASON_STEP, OTHERS_TABS } from '@modules/expenses/constants';
import OthersTypeSuggestionsList from '@modules/expenses/components/others-type-suggestions-list';
import SearchBarOthersType from '@modules/expenses/components/search-bar-others-type';
import useProviderExpense from '@modules/expenses/hooks/use-provider-expense';

interface OthersExpensesTypeProps { }

const OthersExpensesType: FC<OthersExpensesTypeProps> = () => {
  const dispatch = useDispatch();
  const { hasError } = useInputFieldValidation();
  const { selectionSuggestion } = useSelector(expensesUiSelector);
  const { getOthersTypeSuggestions } = useProviderExpense();

  useEffect(() => {
    dispatch(globalActions.header({ hasRightClick: true }));
    dispatch(actions.setStep(OTHERS_REASON_STEP));
    dispatch(actions.setFooter({
      isDisabled: true,
      routeToBack: ROUTES.expenses,
      routeToNext: `${Expenses.others}/${OTHERS_TABS.paymentMethod}`
    }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.setFooter({ isDisabled: hasError || !selectionSuggestion.isSelected }));
  }, [dispatch, hasError, selectionSuggestion]);

  useEffect(() => {
    getOthersTypeSuggestions();
  }, [getOthersTypeSuggestions]);

  return (
    <div className={styles.wrapper}>
      <SearchBarOthersType />

      { selectionSuggestion?.showList && <OthersTypeSuggestionsList /> }
  </div>
  )
}

export default OthersExpensesType;
