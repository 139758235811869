import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CUSTOMERS_PATH, TABS } from '@modules/customers/constants';
import styles from './customers-creation-button.module.sass';
import UiButton from '@ui/ui-button';

interface CustomersCreationButtonProps { }

const CustomersCreationButton: FC<CustomersCreationButtonProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <UiButton
        text={t('Bolsillo.misClientes.footerButton.nuevoCliente')}
        leftIcon='icon-user-plus'
        hasBoldIcon
        size='large'
        onClick={() => navigate(`${CUSTOMERS_PATH}/${TABS.creation}`)}
      />
    </div>
  );
}

export default CustomersCreationButton;
