import React, { FC } from 'react';
import UiDivider from '@ui/ui-divider';
import styles from './closure-detail-skeleton.module.sass';

interface ClosureDetailSkeletonProps { }

const ClosureDetailSkeleton: FC<ClosureDetailSkeletonProps> = () => {
  return (
    <div className={styles.container}>

      <div className={styles.wrapper}>

        <div className={styles.title}>
          <div className={styles.text}/>
          <div className={styles.value}/>
        </div>

        <UiDivider isSmall/>

        {Array(5).fill('').map((_, index) => (
          <div className={styles.balancesBox} key={`balance-${index}`}>
            <div className={styles.text}/>
            <div className={styles.value}/>
          </div>
        ))}

        <div className={styles.incomeTitle}/>
        <div className={styles.incomeText}/>

        {Array(5).fill('').map((_, index) => (
          <div className={styles.incomesBox} key={`incomes-${index}`}>
            <div className={styles.text}/>
            <div className={styles.value}/>
          </div>
        ))}

        <UiDivider isSmall/>

        <div className={styles.expensesTitle}/>

        {Array(4).fill('').map((_, index) => (
          <div className={styles.expensesBox} key={`expenses-${index}`}>
            <div className={styles.text}/>
            <div className={styles.value}/>
          </div>
        ))}
      </div>
    
    </div>
  )
}

export default ClosureDetailSkeleton;