import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from './store';
import { actions as globalActions } from '@config/store';
import { setUXCamScreen } from '@utils/webview';
import { ROUTES, UXCAM_SCREENS } from '@constants';
import styles from './customers.module.sass';
import CustomersRouter from './routers'
import useGetCustomers from './hooks/use-get-customers/use-get-customers';

interface CustomersProps { }

const Customers: FC<CustomersProps> = () => {
  const dispatch = useDispatch();
  useGetCustomers();

  useEffect(() => {
    setUXCamScreen(UXCAM_SCREENS.loan)
  }, []);

  useEffect(() => {
    return () => {
      dispatch(globalActions.header({ hasBackClick: false }));
      dispatch(actions.setBackDetailRoute(ROUTES.home));
      dispatch(actions.restartDetailState());
    }
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <CustomersRouter />
    </div>
  );
};

export default Customers;
