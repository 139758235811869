import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { format as formatDate } from "date-fns";
import { CREDIT_PAYMENT_TRANSACTION_TYPE_ID, ROUTES } from "@constants";
import { globalUserSelector, actions as globalActions, globalBalancesSelector } from "@config/store";
import { fetchCreateNewCreditPayment, fetchGetCreditDetail, fetchGetDebtsProviders } from "@modules/credits/services";
import useFormatCurrency from "@hooks/use-format-currency";
import useNotification from "@hooks/use-notification";
import { actions, creditPaymentSelector, creditsDetailSelector, providersWithCreditSelector } from "../store";
import { CreditPaymentBody } from "../entities";
import useUpdateWallet from "@hooks/use-update-wallet";
import { PAYMENT_MACRO_TYPE_ID, TABS } from "../constants";

const useCreditsFetch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { providerId } = useParams()
  const user = useSelector(globalUserSelector);
  const { t } = useTranslation();
  const paymentTotal = useSelector(creditPaymentSelector);
  const provider = useSelector(creditsDetailSelector);
  const { format } = useFormatCurrency();
  const showNotification = useNotification();
  const balances = useSelector(globalBalancesSelector);
  const { setNewCreditPayment } = useUpdateWallet();
  const providersWithCredit = useSelector(providersWithCreditSelector);

  const getProviderCreditDetail = useCallback((): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetCreditDetail(user.storeId, providerId)
      .then(data => dispatch(actions.setProviderCreditDetail(data)))
      .catch(() => {
        showNotification(t('credits.detail.notification_error'), false);
        navigate(`${ROUTES.credits}/${TABS.debts}`);
      })
      .finally(() => dispatch(actions.setIsLoading(false)))
  }, [dispatch, navigate, providerId, showNotification, user.storeId, t]);

  const createNewCreditPayment = useCallback((): void => {
    const body: CreditPaymentBody = {
      transactionTypeId: CREDIT_PAYMENT_TRANSACTION_TYPE_ID,
      paymentMethodId: 1,
      cashDeckControlId: balances.cashId,
      total: +paymentTotal,
      userId: user.storeId,
      storeId: user.storeId,
      macroType: { macroEgressTypeId: PAYMENT_MACRO_TYPE_ID },
      microType: { microEgressTypeId: provider.id, name: provider.name, image: provider.image },
    };
    dispatch(globalActions.setIsLoading(true));
    fetchCreateNewCreditPayment(body)
      .then((transactionId) => {
        const message = t("credits.payment.notification_message", {
          amount: format(Number(paymentTotal), true),
          provider: provider.name
        });
        showNotification(message, true);
        setNewCreditPayment(transactionId, body, provider.id);
        dispatch(actions.setProviderCreditDetail({
          ...provider,
          debt: provider.debt - +paymentTotal,
          transactions: [{
            total: +paymentTotal,
            transactionTypeId: CREDIT_PAYMENT_TRANSACTION_TYPE_ID,
            createdAt: formatDate(new Date(), 'dd/MM/yyyy')
          }, ...provider.transactions]
        }))

        dispatch(actions.setProvidersWithCredit(providersWithCredit.map(e => (
          e.id === provider.id ? { ...e, total: e.total - body.total } : e
        ))));

        navigate(`${ROUTES.credits}/${provider.id}`);
      })
      .catch(() => showNotification(t("credits.payment.notification_error_message"), false))
      .finally(() => dispatch(globalActions.setIsLoading(false)))

  }, [balances.cashId, paymentTotal, user.storeId, provider, dispatch, t, format, showNotification, setNewCreditPayment, providersWithCredit, navigate]);

  const getDebtsProviders = useCallback((): void => {
    dispatch(actions.setIsLoading(true));
    fetchGetDebtsProviders(user.storeId)
      .then(data => {
        dispatch(actions.setProvidersWithCredit(data));
        dispatch(actions.setProviderSearchedValue(''));
      })
      .catch(() => {
        showNotification(t('credits.detail.notification_error'), false);
        navigate(ROUTES.home);
      })
      .finally(() => dispatch(actions.setIsLoading(false)))
  }, [dispatch, navigate, showNotification, user.storeId, t]);

  return {
    getProviderCreditDetail,
    createNewCreditPayment,
    getDebtsProviders,
  }
};

export default useCreditsFetch;
