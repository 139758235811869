import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@config/store';
import { CreditsDataState, CreditsUiState } from './credits.state';

export const creditsUiSelector = createSelector(
  (state: RootState) => state.credits.ui,
  (state: CreditsUiState) => state
);

export const creditsDetailSelector = createSelector(
  (state: RootState) => state.credits.data,
  (state: CreditsDataState) => state.provider
);

export const providersWithCreditSelector = createSelector(
  (state: RootState) => state.credits.data,
  (state: CreditsDataState) => state.providersWithCredit
);

export const providerSearchedValueSelector = createSelector(
  (state: RootState) => state.credits.data,
  (state: CreditsDataState) => state.providerSearchedValue.searchedValue
);

export const creditPaymentSelector = createSelector(
  (state: RootState) => state.credits.data,
  (state: CreditsDataState) => state.payment.total
);
