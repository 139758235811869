import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@modules/expenses/store';
import { Expenses, PUBLIC_SERVICE_TABS, SERVICE_PAYMENT_METHOD } from '@modules/expenses/constants';
import PaymentMethods from '../../components/payment-methods';

interface PublicServicePaymentMethodProps { }

const PublicServicePaymentMethod: FC<PublicServicePaymentMethodProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setStep(SERVICE_PAYMENT_METHOD));
    dispatch(actions.setFooter({
      routeToNext: `${Expenses.public_service}/${PUBLIC_SERVICE_TABS.amount}`,
      routeToBack: `${Expenses.public_service}/${PUBLIC_SERVICE_TABS.serviceType}`,
    }));
  }, [dispatch]);

  return (
    <PaymentMethods />
  )
}

export default PublicServicePaymentMethod;
