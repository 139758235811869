import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { globalContactsSelector } from "@config/store";
import { customersDataSelector, customersSearchSelector } from "@modules/customers/store";
import CustomersEmptyState from "@modules/customers/components/customers-empty-state";
import CustomersHomeContactList from "../customers-home-contact-list";
import CustomersHomeList from "../customers-home-list";
import UiDivider from "@ui/ui-divider";

interface CustomersHomeListsProps { }

const CustomersHomeLists: FC<CustomersHomeListsProps> = () => {
  const { t } = useTranslation();
  const searchValue = useSelector(customersSearchSelector);
  const customers = useSelector(customersDataSelector);
  const contacts = useSelector(globalContactsSelector);

  const customersList = customers
    .filter((e) => e.name.toLowerCase().includes(searchValue.trim().toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name));

  const contactsList = contacts
    .filter((e) => e.name.toLowerCase().includes(searchValue.trim().toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name));

  const showDivider = () => (!searchValue || (!!customersList.length && !!contactsList.length));

  const hasNoResults = () => (!!searchValue && !customersList.length && !contactsList.length);

  return (
    <>
      <CustomersHomeList customersList={customersList} />

      {showDivider() && <UiDivider />}

      <CustomersHomeContactList contactsList={contactsList} />

      {hasNoResults() &&
        <CustomersEmptyState
          title={t("Bolsillo.misClientes.searchBar.sinResultados.Titulo")}
          text={t("Bolsillo.misClientes.searchBar.sinResultados.cuerpo")}
        />
      }
    </>
  );
}

export default CustomersHomeLists;
