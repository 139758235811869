import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@config/store';
import { IncomeUiState, IncomeDataState } from '.';

export const incomeUiSelector = createSelector(
  (state: RootState) => state.income.ui,
  (state: IncomeUiState) => state
);

export const incomeDataSelector = createSelector(
  (state: RootState) => state.income.data,
  (state: IncomeDataState) => state
);

export const currentAmountSelector = createSelector(
  (state: RootState) => state.income.data,
  (state: IncomeDataState) => state.currentAmount
);

export const currentPaymentMethodSelector = createSelector(
  (state: RootState) => state.income.data,
  (state: IncomeDataState) => state.currentPaymentMethodId
);
