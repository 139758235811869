export const transactions = {
  spanish: {
    'income': 'Ingreso',
    'base': 'Cambio de base',
    'box_closure': 'Cierre de Caja',
    'debt_payment': 'Abono',
    'loan': 'Fiado',
    'money': 'Efectivo'
  },
  portuguese: {
    'income': 'Entrada',
    'base': 'Alteração no troco',
    'box_closure': 'Fechamento do caixa',
    'debt_payment': 'Pago',
    'loan': 'Fiado',
    'money': 'Dinheiro'
  }
}