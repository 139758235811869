import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { customerDetailSelector } from '@modules/customers/store';
import CustomersForm from '@modules/customers/components/customers-form';
import useCustomerFormSubmit from '@modules/customers/hooks/use-customer-form-submit';
import { To } from 'react-router-dom';

interface CustomersEditionProps { }

const CustomersEdition: FC<CustomersEditionProps> = () => {
  const { t } = useTranslation();
  const { updateCustomer } = useCustomerFormSubmit();
  const details = useSelector(customerDetailSelector);

  return (
    <CustomersForm
      header={{
        title: t('Bolsillo.editarCliente.topBar.titulo1'),
        hasRightClick: true,
        hasBackClick: false,
        routeOnRightClick: -1 as To
      }}
      onSubmit={updateCustomer}
      initialValues={{
        name: details.name,
        phone: details.phone,
        areaCode: t('country.area_code')
      }}
    />
  );
}

export default CustomersEdition;
