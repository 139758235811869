export const general = {
  spanish: {
    "step": "Paso {{current}} de {{total}}",
    "notification_min_amount_message": "El valor del {{transaction}} debe ser mayor a cero",
    "notification_max_amount_message": "El valor del {{transaction}} debe ser menor a {{max}}",
    "notification_payment_methods_failed": "Lo sentimos. Ha ocurrido un error. Algunos métodos de pago no están disponibles",
    "notification_push_received": "Nuevo gasto en Chiper por {{total}} registrado exitosamente",
    "general_error": "Se ha presentado un error, por favor refresca o intenta más tarde",
    "general_error_button_text": "Refrescar",
    "pocket": "Bolsillo",
    "footer_next_button_text": "Continuar",
    "footer_final_button_text": "Finalizar",
  },
  portuguese: {
    "step": "Passo {{current}} de {{total}}",
    "notification_min_amount_message": "O valor da {{transaction}} deve ser maior que zero",
    "notification_max_amount_message": "O valor da {{transaction}} deve ser menor a {{max}}",
    "notification_payment_methods_failed": "Sentimos muito. Ocorreu um erro. Alguns métodos de pagamento não estão disponíveis",
    "notification_push_received": "Nova gasto em Chiper de {{total}} registrada com sucesso",
    "general_error": "Se ha presentado un error, por favor refresca o intenta más tarde",
    "general_error_button_text": "Refrescar",
    "pocket": "Carteira",
    "footer_next_button_text": "Prosseguir",
    "footer_final_button_text": "Finalizar",
  }
};
 