import { StepSection } from '@entities';
import { BaseDataState } from '@modules/base/store';
import { ROUTES } from '@constants';

export const BASE_HEADER_TITLE = 'Bolsillo.base.topbar.title';
export const AMOUNT_NAME = 'Bolsillo.base.subtitle1';
export const BASE_REASON_TITLE = 'Bolsillo.base.subtitle2';

export const DEFAULT_TOTAL_STEPS = 1;
export const BASE_REASON_TOTAL_STEPS = 2;

export const BASE_AMOUNT_STEP: StepSection = {
  currentStep: 1,
  stepName: AMOUNT_NAME,
};

export const BASE_AMOUNT_ONE_STEPS: StepSection = {
  totalSteps: DEFAULT_TOTAL_STEPS,
  ...BASE_AMOUNT_STEP
};

export const BASE_AMOUNT_TWO_STEPS: StepSection = {
  totalSteps: BASE_REASON_TOTAL_STEPS,
  ...BASE_AMOUNT_STEP
};

export const TABS = {
  home: ROUTES.empty,
  reason: 'reason',
  amount: 'amount'
};

export const EMPTY_STATE: BaseDataState = {
  currentAmount: '',
  currentReason: '',
  baseTransactions: []
};

export const BASE_PAYMENT_METHOD = 1;
export const BASE_DEFAULT_IMAGE = "https://i.ibb.co/mCYf5YQ/base.png";
export const BASE_DEFAULT_REASON = "base_module.default_reason";