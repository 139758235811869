import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, expensesMicroEgressSelector } from '@modules/expenses/store';
import { actions as globalActions } from '@config/store';
import {
  Expenses, PUBLIC_SERVICE_TABS,
  PUBLIC_SERVICE_TYPES, SERVICE_TYPE_STEP
} from '@modules/expenses/constants';
import { ROUTES } from '@constants';
import UiSelectionList from '@ui/ui-selection-list';
import styles from './public-service-type.module.sass';

interface PublicServiceTypeProps { }

const PublicServiceType: FC<PublicServiceTypeProps> = () => {
  const dispatch = useDispatch();
  const currentMicroEgress = useSelector(expensesMicroEgressSelector);

  useEffect(() => {
    dispatch(actions.setStep(SERVICE_TYPE_STEP));
    dispatch(actions.setFooter({
      routeToNext: `${Expenses.public_service}/${PUBLIC_SERVICE_TABS.paymentMethod}`,
      routeToBack: ROUTES.expenses,
      isDisabled: true,
    }));
    dispatch(globalActions.header({ hasRightClick: true }));
  }, [dispatch]);

  useEffect(() => {
    if (currentMicroEgress) dispatch(actions.setFooter({ isDisabled: false }));
  }, [currentMicroEgress, dispatch]);

  const handleCurrentService = useCallback((item): void => {
    dispatch(actions.setCurrentMicroEgressTypeId(item.id));
    dispatch(actions.setCurrentMicroEgressName(item.name));
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <UiSelectionList
        list={PUBLIC_SERVICE_TYPES}
        selected={currentMicroEgress}
        onItemClick={handleCurrentService}
      />
    </div>
  );
}

export default PublicServiceType;
