import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UiMoneyValueProps } from '@entities';
import { globalBalancesSelector } from '@config/store';
import { ROUTES, TransactionType } from '@constants';
import styles from './home-dashboard-balances.module.sass';
import UiMoneyValue from '@ui/ui-money-value';
import UiDivider from '@ui/ui-divider';
import { useNavigate } from 'react-router-dom';

interface HomeDashboardBalancesProps { }

const HomeDashboardBalances: FC<HomeDashboardBalancesProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const balances = useSelector(globalBalancesSelector);

  const balanceItems: { text: string, moneyValueProps: UiMoneyValueProps }[] = [
    { text: 'Bolsillo.dashboard.subtitle1', moneyValueProps: { value: balances.income, type: TransactionType.income } },
    { text: 'Bolsillo.dashboard.subtitle2', moneyValueProps: { value: balances.egress, type: TransactionType.expense } },
    { text: 'Bolsillo.dashboard.subtitle3', moneyValueProps: { value: balances.balance, type: TransactionType.balance, size: 'large' } },
  ];

  const infoItems = [
    { text: 'Bolsillo.dashboard.subtitle4', value: balances.base, route: ROUTES.base, type: TransactionType.base },
    { text: 'Bolsillo.dashboard.subtitle5', value: balances.loan, route: ROUTES.customers, type: TransactionType.loan },
    // { text: 'dashboard.my_doubts', value: balances.debt, route: `${ROUTES.credits}`, canNavigate: true },
  ];

  return (
    <div>
      <UiDivider />

      <div className={styles.box}>
        {balanceItems.map((item, i) => (
          <div className={styles.item} key={`balance-item-${i}`}>
            <span className={styles.text}>{t(item.text)}</span>
            <UiMoneyValue {...item.moneyValueProps} />
          </div>
        ))}
      </div>

      <UiDivider />

      <div className={styles.box}>
        {infoItems.map((item, i) => (
          <div className={styles.item} key={`balance-info-item-${i}`} onClick={() => navigate(item.route)}>
            <span className={styles.text}>{t(item.text)}</span>
            <div
              className={styles.resume}
              data-testid="arrow-icon"
            >
              <UiMoneyValue value={item.value} type={item.type} />
              <span className={`icon-back ${styles.arrow}`} />
            </div>
          </div>
        ))}
      </div>

      <UiDivider />
    </div>
  )
}

export default HomeDashboardBalances;
