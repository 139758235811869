import React, { FC } from "react";
import { useSelector } from "react-redux";
import { customersUiSearchBarSelector } from "@modules/customers/store";
import CustomersHomeLists from "@modules/customers/components/customers-home-lists";
import CustomersCreationButton from "@modules/customers/components/customers-creation-button";
import CustomersHomeInfoSearchBar from "@modules/customers/components/customers-home-info-search-bar";
import CustomersHomeActiveSearchBar from "@modules/customers/components/customers-home-active-search-bar";
import styles from "./customers-home-content.module.sass";

interface CustomersHomeContentProps { }

const CustomersHomeContent: FC<CustomersHomeContentProps> = () => {
  const isSearchBarActive = useSelector(customersUiSearchBarSelector);
  
  return (
    <div className={styles.wrapper}>

      {isSearchBarActive
        ? <CustomersHomeActiveSearchBar />
        : <CustomersHomeInfoSearchBar />
      }

      <CustomersHomeLists />

      <CustomersCreationButton />
    </div>
  );
}

export default CustomersHomeContent;
