import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from '@config/store';
import { setUXCamScreen } from '@utils/webview';
import { UXCAM_SCREENS } from '@constants';
import BoxClosureRouter from './routers';
import styles from './box-closure.module.sass';

export interface BoxClosureProps { }

const BoxClosure: FC<BoxClosureProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    setUXCamScreen(UXCAM_SCREENS.box_closure)
  }, []);

  useEffect(() => {
    return () => { dispatch(globalActions.header({ title: '', hasBackClick: false, hasRightClick: false })) }
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <BoxClosureRouter />
    </div>
  )
}

export default BoxClosure;
