import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { boxClosureDetailTransactionsSelector } from '@modules/box-closure/store';
import BoxClosureDetailTransactionItem from '../box-closure-detail-transaction-item';
import styles from './box-closure-detail-transactions-list.module.sass';
import UiPaginator from '@ui/ui-paginator';
import useBoxClosureDetailPagination from '@modules/box-closure/hooks/use-box-closure-detail-pagination';

interface BoxClosureDetailTransactionsListProps { }

const BoxClosureDetailTransactionsList: FC<BoxClosureDetailTransactionsListProps> = () => {
  const [showTransactions, setShowTransactions] = useState<boolean>(true);
  const { t } = useTranslation();
  const transactions = useSelector(boxClosureDetailTransactionsSelector);
  const { isLoading, getNextPage } = useBoxClosureDetailPagination();

  const handleIconClick = () => {
    setShowTransactions(prev => !prev);
    setTimeout(() => window.scrollTo({ top: 1200, behavior: 'smooth' }), 200);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <span className={styles.title}>{t('box_closure.title.transactions')}</span>
        <span
          className={`icon-back ${styles.icon} ${!showTransactions && styles.toTop}`}
          onClick={handleIconClick}
          data-testid={`${showTransactions ? 'hide' : 'show'}-transactions-icon`}
        />
      </div>

      {showTransactions && transactions.data.map((item) =>
        <BoxClosureDetailTransactionItem transaction={item} key={`transaction-item-id=${item.id}`} />
      )}

      <UiPaginator
        hideButton={transactions.currentPage === transactions.totalPages}
        onClick={getNextPage}
        isLoading={isLoading}
        delayToShow={0}
      />
    </div>
  )
}

export default BoxClosureDetailTransactionsList;