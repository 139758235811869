import React, { ChangeEvent, FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { actions as globalActions } from '@config/store';
import styles from "./income-amount.module.sass";
import useInputFieldValidation from '@hooks/use-input-field-validation';
import UiInputMoney from "@ui/ui-input-money";
import { actions, currentAmountSelector } from "@modules/income/store";
import { TABS, INCOME_AMOUNT_STEP } from "@modules/income/constants";
import { ROUTES } from "@constants";


interface IncomeProps { }

const IncomeAmount: FC<IncomeProps> = () => {
  const dispatch = useDispatch();
  const currentAmount = useSelector(currentAmountSelector);
  const { t } = useTranslation();
  const { hasError, validateInputMoney } = useInputFieldValidation();

  useEffect(() => {
    dispatch(actions.setStep(INCOME_AMOUNT_STEP));
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.setFooter({
      routeToNext: `${TABS.paymentMethod}`,
      routeToBack: ROUTES.home,
      isDisabled: !currentAmount || hasError
    }));
    dispatch(globalActions.header({ 
      hasRightClick: true,
      routeOnRightClick: ROUTES.home 
    }));
  }, [currentAmount, dispatch, hasError]);

  const handleInputChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>): void => {
      validateInputMoney(+target.value, t('income.transaction_name'));
      dispatch(actions.setCurrentAmount(target.value));
    },
    [dispatch, t, validateInputMoney]
  );

  return (
    <div className={styles.wrapper}>
      <UiInputMoney
        name="amount"
        hasAutoFocus
        value={currentAmount}
        onChange={handleInputChange}
        hasError={hasError}
      />
    </div>
  );
};

export default IncomeAmount;
