import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { actions as globalActions, globalContactsPermissionSelector, } from "@config/store";
import Clevertap, { CUSTOMERS_START } from "@config/clevertap";
import { requestContactsPermissions } from "@utils/webview";
import { ROUTES } from "@constants";
import { actions, customersUiSelector } from "@modules/customers/store";
import CustomersHomeContent from "@modules/customers/components/customers-home-content";
import CustomersHomeSkeleton from "@modules/customers/components/customers-home-skeleton";

interface CustomersHomeProps { }

const CustomersHome: FC<CustomersHomeProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoading } = useSelector(customersUiSelector);
  const contactsPermissionGranted = useSelector(globalContactsPermissionSelector);

  useEffect(() => {
    dispatch(globalActions.header({
      title: t("Bolsillo.misClientes.topBar.meDeben"),
      hasRightClick: true,
      hasBackClick: false,
      routeOnRightClick: ROUTES.home,
    }));
  }, [dispatch, t]);

  useEffect(() => { Clevertap.pushEvent(CUSTOMERS_START) }, []);

  useEffect(() => {
    if (!contactsPermissionGranted) requestContactsPermissions()
  }, [contactsPermissionGranted]);

  useEffect(() => {
    dispatch(actions.restartDetailState());
  }, [dispatch]);

  if (isLoading) return <CustomersHomeSkeleton />

  return <CustomersHomeContent />
};

export default CustomersHome;
