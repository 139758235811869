import { formatISO9075 } from "date-fns";
import { sendEvent } from "@utils/webview";
import { ClevertapEventPayload } from "./clevertap.entities";

const pushEvent = (eventName: string, payload?: ClevertapEventPayload): void => {
  sendEvent(eventName, {
    date: formatISO9075(new Date()),
    ...payload,
  });
};

const Clevertap = {
  pushEvent
};

export default Clevertap;
