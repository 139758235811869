import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProviderCreditDetail, ProvidersWithCredit } from "../entities";
import { initialState } from './credits.state';

export const creditsSlice = createSlice({
  name: 'credits',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoading = payload;
    },
    setProviderCreditDetail: (state, { payload }: PayloadAction<ProviderCreditDetail>) => {
      state.data.provider = payload;
    },
    setProvidersWithCredit: (state, { payload }: PayloadAction<ProvidersWithCredit[]>) => {
      state.data.providersWithCredit = payload;
    },
    setProviderSearchedValue: (state, { payload }: PayloadAction<string>) => {
      state.data.providerSearchedValue.searchedValue = payload;
    },
    restartState: (state) => {
      state.data.provider = {} as ProviderCreditDetail;
    },
    setPaymentTotal: (state, { payload }: PayloadAction<string>) => {
      state.data.payment.total = payload;
    },
  },
});

export const actions = creditsSlice.actions;
export default creditsSlice.reducer;
