import { ChangeEvent, FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions as globalActions } from '@config/store';
import styles from "./base-reason.module.sass";
import UiInputField from "@ui/ui-input-field";
import { BASE_REASON_TITLE, TABS } from "@modules/base/constants";
import { actions, currentReasonSelector, currentAmountSelector } from "@modules/base/store";
import { ROUTES } from "@constants";
import useInputFieldValidation from "@hooks/use-input-field-validation";

interface BaseReasonProps { }

const BaseReason: FC<BaseReasonProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentReason = useSelector(currentReasonSelector);
  const amountBase = useSelector(currentAmountSelector);
  const { hasError, validateBaseReason } = useInputFieldValidation();

  useEffect(() => {
    if (!amountBase) navigate(ROUTES.base);
  }, [amountBase, navigate]);

  useEffect(() => {
    dispatch(actions.setStep({ currentStep: 2, stepName: BASE_REASON_TITLE }));
    dispatch(actions.setFooter({
      routeToNext: ROUTES.base,
      routeToBack: `${ROUTES.base}/${TABS.amount}`,
      isDisabled: true,
      hasSubmitFunction: true,
    }));
    dispatch(globalActions.header({
      hasRightClick: true,
      hasBackClick: false
    }));

    return () => { dispatch(actions.setFooter({ hasSubmitFunction: false })) };
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.setFooter({ isDisabled: !currentReason || hasError }));
  }, [currentReason, dispatch, hasError]);

  const handleInputChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>): void => {
    validateBaseReason(target.value);
    dispatch(actions.setCurrentReason(target.value));
  }, [dispatch, validateBaseReason]);

  return (
    <div className={styles.wrapper}>
      <UiInputField
        label=""
        name="reason"
        value={currentReason}
        onChange={handleInputChange}
        hasAutoFocus
        hasError={hasError}
      />
    </div>
  );
};

export default BaseReason;
