import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { actions, homeFilterResultsSelector } from '@modules/home/store';
import { actions as globalActions } from '@config/store';
import { ROUTES } from '@constants';
import UiButton from '@ui/ui-button';
import styles from './home-filters-buttons.module.sass';

interface HomeFiltersButtonsProps { }

const HomeFiltersButtons: FC<HomeFiltersButtonsProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const results = useSelector(homeFilterResultsSelector);

  const clearFilters = useCallback(() => {
    dispatch(actions.setMustApplyFilters(false));
    dispatch(actions.resetFilters());
  }, [dispatch]);

  const showResults = useCallback(() => {
    dispatch(actions.setIsFilterActive(true));
    dispatch(globalActions.setTransactionsResponse(results))
    navigate(ROUTES.home);
  }, [dispatch, navigate, results]);

  return (
    <div className={styles.wrapper}>
      <UiButton
        text={t('Bolsillo.dashboard.filters.footerbutton1.title1')}
        isSecondary
        size='large'
        onClick={clearFilters}
      />

      <UiButton
        text={t('Bolsillo.dashboard.filters.footerbutton2.title2', { Value: results.totalTransactions || 0 })}
        size='large'
        onClick={showResults}
        isDisabled={!results.totalTransactions}
      />
    </div>
  );
}

export default HomeFiltersButtons;
