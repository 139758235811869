import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { globalTransactionsSelector } from '@config/store';
import styles from './home-transactions-list-wrapper.module.sass';
import Paginator from '../home-paginator';
import UiNoDataMessage from '@ui/ui-no-data-message';
import UiTransactionsList from '@ui/ui-transaction-list/ui-transactions-list';

interface HomeTransactionsListWrapperProps { }

const HomeTransactionsListWrapper: FC<HomeTransactionsListWrapperProps> = () => {
  const { t } = useTranslation();
  const transactions = useSelector(globalTransactionsSelector);

  if (!transactions.length) return (
    <div className={styles.noData}>
      <UiNoDataMessage message={t('dashboard.no_transactions_yet')} />
    </div>
  )

  return (
    <>
      <UiTransactionsList transactions={transactions} />
      <Paginator />
    </>
  );
};

export default HomeTransactionsListWrapper;
