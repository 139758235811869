import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { globalUiLoaderSelector } from "@config/store";
import styles from "./box-closure-home-footer.module.sass";
import useBoxClosureFetch from "@modules/box-closure/hooks/use-box-closure-fetch";
import UiButton from "@ui/ui-button";
import useFormEdition from "@modules/box-closure/hooks/use-form-edition";

interface BoxClosureHomeFooterProps { }

const BoxClosureHomeFooter: FC<BoxClosureHomeFooterProps> = () => {
  const { t } = useTranslation();
  const isLoading = useSelector(globalUiLoaderSelector);
  const { createNewBoxClosure } = useBoxClosureFetch();
  const { cancelEdition, updateValues, isEditionActive } = useFormEdition();

  return (
    <>
      {isEditionActive ? (
        <div className={styles.buttons}>
          <UiButton
            text={t("Bolsillo.caja.footerbutton1.actualizar")}
            onClick={updateValues}
          />
          <UiButton
            text={t("Bolsillo.caja.footerbutton2.cancelar")}
            isSecondary
            onClick={cancelEdition}
          />
        </div>
      ) : (
        <div className={styles.button}>
          <UiButton
            text={t("Bolsillo.caja.footerbutton3.confirmar")}
            onClick={createNewBoxClosure}
            isLoading={isLoading}
            size="large"
          />
        </div>
      )}
    </>
  );
}

export default BoxClosureHomeFooter;
