export const ob = {
  spanish: {
    "onboarding": {
      "id-3": {
        "title": "Base",
        "description": "Comienza el día asignando el dinero para abrir tu tienda."
      },
      "id-1": {
        "title": "Ingreso",
        "description": "Registra el valor total del ingreso por método de pago al finalizar el día."
      },
      "id-2": {
        "title": "Gasto",
        "description": "Anota los gastos que se pagan con el dinero de tu caja, desde donde estés."
      },
      "id-5": {
        "title": "Fiar",
        "description": "Controla tus ventas fiadas, administrando tus clientes."
      },
      "id-4": {
        "title": "Caja",
        "description": "Controla tus ventas diarias, administrando tus ingresos y gastos."
      }
    }
  },

  portuguese: {
    "onboarding": {
      "id-3": {
        "title": "Troco",
        "description": "Comece o dia reservando um dinheiro para abrir seu comércio."
      },
      "id-1": {
        "title": "Entrada",
        "description": "Registre o valor total de entradas por método de pagamento ao fim do dia."
      },
      "id-2": {
        "title": "Saída",
        "description": "Anote os gastos pagos com o valor em seu caixa, onde estiver."
      },
      "id-5": {
        "title": "Fiado",
        "description": "Controle suas vendas fiadas, administrando seus clientes."
      },
      "id-4": {
        "title": "Caixa",
        "description": "Controle suas vendas diárias, administrando suas entradas e saídas."
      }
    }
  }
};