import { IncomeStepMap } from "../entities";

export const STEPS_MAP: IncomeStepMap = {
    1: {
      1: {
        title: 'income.tour_tooltip.amount',
        x: 'center',
        y: 'top'
      },
      2: {
        title: 'income.tour_tooltip.next_step',
        x: 'right',
        y: 'bottom'
      }
    },
    2: {
      1: {
        title: 'income.tour_tooltip.payment_method',
        x: 'center',
        y: 'top'
      },
      2: {
        title: 'income.tour_tooltip.submit',
        x: 'right',
        y: 'bottom'
      }
    }
  };