import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@constants';
import { actions } from '@modules/box-closure/store';
import { actions as globalActions, globalUserSelector } from '@config/store';
import { fetchGetBoxClosureDetail, fetchGetBoxClosureTransactions } from '@modules/box-closure/services';

const useGetBoxClosureDetailInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector(globalUserSelector);
  const { cashId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      fetchGetBoxClosureDetail(user.storeId, cashId),
      fetchGetBoxClosureTransactions(user.storeId, cashId)
    ])
      .then(([detail, transactions]) => {
        dispatch(actions.setBoxClosureDetail(detail));
        dispatch(actions.setTransactionsToDetail(transactions))
      })
      .catch(() => {
        dispatch(globalActions.notification({
          message: t('box_closure.notification.info_error'),
          show: true,
          state: 'alert'
        }));
        navigate(ROUTES.home)
      })
      .finally(() => setIsLoading(false))
  }, [cashId, dispatch, navigate, user.storeId, t]);

  return {
    isLoading
  }
}

export default useGetBoxClosureDetailInfo;
