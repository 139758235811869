import { FC, ChangeEvent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from "./search-bar-others-type.module.sass";
import useDebounce from '@hooks/use-debounce';
import useInputFieldValidation from '@hooks/use-input-field-validation';
import useProviderExpense from '@modules/expenses/hooks/use-provider-expense';
import { actions, expensesMicroEgressNameSelector, expensesUiSelector } from '@modules/expenses/store';
import { globalUserSelector } from '@config/store';
import UiInputField from '@ui/ui-input-field';

interface SearchBarOthersTypeProps { }

const SearchBarOthersType: FC<SearchBarOthersTypeProps> = () => {
  const dispatch = useDispatch();
  const user = useSelector(globalUserSelector);
  const currentMicroEgressName = useSelector(expensesMicroEgressNameSelector);
  const { hasError } = useInputFieldValidation();
  const { getSearchOthersTypeList } = useProviderExpense();
  const { selectionSuggestion } = useSelector(expensesUiSelector);

  const handleOthersTypeSearch = useCallback(({ target }: ChangeEvent<HTMLInputElement>): void => {
    dispatch(actions.setCurrentMicroEgressName(target.value));
    dispatch(actions.setSelectionSuggestion({ isSelected: false, hasSearching: true }));
    if (!target.value.trim()) {
      dispatch(actions.setOthersTypeSuggestionsResults([]));
      dispatch(actions.setSelectionSuggestion({ showList: true, hasSearching: false }));
    } else getSearchOthersTypeList(user.storeId, target.value);
  }, [dispatch, user.storeId, getSearchOthersTypeList]);

  const { handleInputChange, value, changeValue } = useDebounce(handleOthersTypeSearch);

  const handleOnFocus = useCallback((): void => {
    dispatch(actions.setSelectionSuggestion({ showList: true, isSelected: false }));
  }, [dispatch])

  useEffect(() => {
    if (selectionSuggestion.isSelected) changeValue(currentMicroEgressName);
  }, [changeValue, selectionSuggestion, currentMicroEgressName])

  return (
    <div className={styles.wrapper}>
      <UiInputField
        name='' 
        label='' 
        onChange={handleInputChange}
        hasAutoFocus
        value={value}
        hasError={hasError}
        onFocus={handleOnFocus}
      />
    </div>
  );
}

export default SearchBarOthersType;
