import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from './income.state';
import { FooterSection, StepSection } from "@entities";
import { EMPTY_STATE } from "../constants";

export const incomeSlice = createSlice({
  name: 'income',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoading = payload;
    },
    setStep: (state, { payload }: PayloadAction<StepSection>) => {
      state.ui.step = { ...state.ui.step, ...payload };
    },
    setFooter: (state, { payload }: PayloadAction<FooterSection>) => {
      state.ui.footer = { ...state.ui.footer, ...payload };
    },
    setCurrentAmount: (state, { payload }: PayloadAction<string>) => {
      state.data.currentAmount = payload;
    },
    setCurrentPaymentMethod: (state, { payload }: PayloadAction<string>) => {
      state.data.currentPaymentMethodId = payload;
    },
    restartState: (state) => {
      state.data = EMPTY_STATE;
    },
  },
});

export const actions = incomeSlice.actions;
export default incomeSlice.reducer;
