import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from './base.state';
import { FooterSection, StepSection, TransactionsByDate } from "@entities";
import { PaginatorSection } from "../entities";
import { EMPTY_STATE } from "../constants";

export const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isLoading = payload;
    },
    setEscapeRoute: (state, { payload }: PayloadAction<string>) => {
      state.ui.escapeRoute = payload;
    },
    setStep: (state, { payload }: PayloadAction<StepSection>) => {
      state.ui.step = { ...state.ui.step, ...payload };
    },
    setFooter: (state, { payload }: PayloadAction<FooterSection>) => {
      state.ui.footer = { ...state.ui.footer, ...payload };
    },
    setCurrentAmount: (state, { payload }: PayloadAction<string>) => {
      state.data.currentAmount = payload;
    },
    setCurrentReason: (state, { payload }: PayloadAction<string>) => {
      state.data.currentReason = payload;
    },
    restartState: (state) => {
      state.data = EMPTY_STATE;
    },
    setPagination: (state, { payload }: PayloadAction<PaginatorSection>) => {
      state.ui.paginator = payload;
    },
    setBaseTransactions: (state, { payload }: PayloadAction<TransactionsByDate[]>) => {
      state.data.baseTransactions = payload;
    },
  },
});

export const actions = baseSlice.actions;
export default baseSlice.reducer;
