import { useCallback, useRef, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import useNotification from './use-notification';
import useFormatCurrency from './use-format-currency';
import {
  BASE_REASON_LENGTH, BRAZIL_PHONE_LENGTH, CUSTOMER_NAME_LENGTH, EXPENSE_NAME_LENGTH, MAX_AMOUNT, MIN_AMOUNT, PHONE_LENGTH,
  REG_EXP_NO_ONLY_SPACES, REG_EXP_ONLY_LETTERS, REG_EXP_ONLY_NUMBERS
} from '@constants';

const useInputFieldValidation = () => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const debounceFunction = useRef<NodeJS.Timeout>({} as NodeJS.Timeout)
  const showNotification = useNotification();
  const { format } = useFormatCurrency();
  const { i18n } = useTranslation();

  const countryPhoneLength = i18n.language === 'br' ? BRAZIL_PHONE_LENGTH : PHONE_LENGTH;

  const pushNotification = useCallback((message: string): void => {
    showNotification(message, false)
  }, [showNotification]);

  const validateName = (name: string, currentName?: string) => {
    yup.string()
      .required(t('errors.required'))
      .max(CUSTOMER_NAME_LENGTH, t('errors.max_characters', { max: CUSTOMER_NAME_LENGTH }))
      .matches(REG_EXP_ONLY_LETTERS, t('errors.only_letters'))
      .matches(new RegExp(`^(?!${currentName}$)`, "i"), t('errors.not_same_name'))
      .validate(name)
      .then(() => { if (hasError) setHasError(false) })
      .catch((res) => {
        if (!hasError) setHasError(true);
        pushNotification(res.message);
      });
  };

  const reasonValidation = (reason: string, maxLength: number) => {
    yup.string()
      .required(t('errors.required'))
      .max(maxLength, t('errors.max_characters', { max: maxLength }))
      .matches(REG_EXP_NO_ONLY_SPACES, t('errors.spaces_init'))
      .validate(reason)
      .then(() => { if (hasError) setHasError(false) })
      .catch((res) => {
        if (!hasError) setHasError(true);
        pushNotification(res.message);
      });
  };

  const validateExpenseName = (name: string) => {
    reasonValidation(name, EXPENSE_NAME_LENGTH);
  };

  const validateBaseReason = (reason: string) => {
    reasonValidation(reason, BASE_REASON_LENGTH);
  };

  const validatePhone = (phone: string, currentPhone?: string) => {
    const validation = () => yup.string()
      .required(t('errors.required'))
      .matches(REG_EXP_ONLY_NUMBERS, t('errors.only_numbers'))
      .matches(new RegExp(`^(?!${currentPhone}$)`, "i"), t('errors.not_same_phone'))
      .max(countryPhoneLength, t('errors.max_digits', { digits: countryPhoneLength }))
      .validate(phone)
      .then(() => { if (hasError) setHasError(false) })
      .catch((res) => {
        if (!hasError) setHasError(true);
        pushNotification(res.message);
      });
    validation();
    if (debounceFunction.current) clearTimeout(debounceFunction.current);
    debounceFunction.current = setTimeout(() => {
      yup.string().min(countryPhoneLength, t('errors.min_digits', { digits: countryPhoneLength })).validate(phone)
        .then(validation)
        .catch((res) => {
          if (!hasError) {
            setHasError(true);
            pushNotification(res.message);
          }
        });
    }, 800)
  };

  const validateopcionalInputMoney = (amount: number | string, transaction: string) => {
    yup.number()
      .test('min', t('general.notification_min_amount_message', { transaction }),
        () => amount === '' || amount > 0
      )
      .max(MAX_AMOUNT, t('general.notification_max_amount_message', {
        transaction,
        max: format(MAX_AMOUNT, true)
      }))
      .validate(+amount)
      .then(() => { if (hasError) setHasError(false) })
      .catch((res) => {
        if (!hasError) setHasError(true);
        pushNotification(res.message);
      });
  }

  const validateInputMoney = (amount: number | string, transaction: string, currentAmount: number = MAX_AMOUNT, isOpcional: boolean = false) => {
    if (isOpcional) validateopcionalInputMoney(amount, transaction)
    else yup.number()
      .required(t('errors.required'))
      .min(MIN_AMOUNT, t('general.notification_min_amount_message', { transaction }))
      .max(currentAmount, t('general.notification_max_amount_message', {
        transaction,
        max: format(currentAmount, true)
      }))
      .validate(+amount)
      .then(() => { if (hasError) setHasError(false) })
      .catch((res) => {
        if (!hasError) setHasError(true);
        pushNotification(res.message);
      });
  }

  const checkCustomersAmount = (amount: string, max: number, setError: (e: string) => void) => {
    yup.number()
      .min(MIN_AMOUNT, t('Bolsillo.fiar.textfieldError.texto1', { reference: 'mayor', value: format(MIN_AMOUNT, false) }))
      .max(max, t('Bolsillo.fiar.textfieldError.texto1',  { reference: 'menor', value: format(max, true) }))
      .validate(+amount)
      .then(() => {
        if (hasError) {
          setHasError(false);
          setError('');
        }
      })
      .catch((res) => {
        if (!hasError) setHasError(true);
        setError(res.message);
      });
  }

  return {
    hasError,
    validateName,
    validatePhone,
    validateBaseReason,
    validateExpenseName,
    validateInputMoney,
    checkCustomersAmount
  };
}

export default useInputFieldValidation;
