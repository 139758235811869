import React, { FC } from 'react';
import styles from './base-skeleton.module.sass';
import UiDivider from '@ui/ui-divider';

interface BaseSkeletonProps { }

const BaseSkeleton: FC<BaseSkeletonProps> = () => {
  return (
    <div className={styles.container}>

      <div className={styles.wrapper} data-testid="base-skeleton">

        <div className={styles.info} />

        <UiDivider />

        <div className={styles.title}/>

        <div className={styles.list}>
          {Array(4).fill('').map((_, i) => (
            <div className={styles.animation} key={`skeleton-transaction-${i}`}>
              <div className={styles.day} />
              <div className={styles.transaction}/>
            </div>
          ))}
        </div>

      </div>
      
    </div>
  )
}

export default BaseSkeleton;