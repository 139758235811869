import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@modules/expenses/store';
import { Expenses, RENT_AMOUNT_STEP, RENT_TABS } from '@modules/expenses/constants';
import { ROUTES } from '@constants';
import InputAmount from '@modules/expenses/components/input-amount';

interface RentAmountProps { }

const RentAmount: FC<RentAmountProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setStep(RENT_AMOUNT_STEP));
    dispatch(actions.setFooter({
      routeToNext: ROUTES.home,
      routeToBack: `${Expenses.rent}/${RENT_TABS.paymentMethod}`,
      hasSubmitFunction: true,
    }));
    return () => { dispatch(actions.setFooter({ hasSubmitFunction: false })); }
  }, [dispatch]);

    return (
      <InputAmount />
    );
}

export default RentAmount;