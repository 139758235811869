import { actions as globalActions } from "@config/store";
import { useCallback } from "react";
import { useDispatch, } from "react-redux";

const useNotification = () => {
  const dispatch = useDispatch();

  const showNotification = useCallback((message: string, isSucces: boolean): void => {
    dispatch(globalActions.notification({
      message,
      show: true,
      state: isSucces ? 'success' : 'alert',
    }));
  }, [dispatch]);

  return showNotification
};

export default useNotification;
