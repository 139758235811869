import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeFilterMovementsSelector, homeFiltersDateSelector } from "@modules/home/store";
import { actions as globalActions, globalTransactionsCompleteSelector, globalUserSelector } from "@config/store";
import { DEFAULT_TRANSACTIONS_PER_PAGE, TRANSACTIONS_PER_PAGE } from "@constants";
import { fetchGetTransactions } from "@services";
import { format } from "date-fns";

const usePagination = () => {
  const dispatch = useDispatch();
  const user = useSelector(globalUserSelector);
  const transactions = useSelector(globalTransactionsCompleteSelector);
  const homeFilter = useSelector(homeFiltersDateSelector);
  const transactionTypes = useSelector(homeFilterMovementsSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getPage = useCallback((): void => {
    const body = {
      search: '',
      limit: TRANSACTIONS_PER_PAGE,
      skip: transactions.currentPage === 1 ? DEFAULT_TRANSACTIONS_PER_PAGE : TRANSACTIONS_PER_PAGE * transactions.currentPage,
      startDate: !!homeFilter.initDate ? format(new Date(homeFilter.initDate), "yyyy/MM/dd") : undefined,
      endDate: !!homeFilter.endDate ? format(new Date(homeFilter.endDate), "yyyy/MM/dd") : undefined,
      transactionTypes
    };

    setIsLoading(true);
    fetchGetTransactions(user.storeId, body)
      .then(({ data, currentPage, totalPages }) => {
        let currentTransactions = [...transactions.data];
        let newTransactions = [...data];
        const lastIndex = transactions.data.length - 1;
        if (transactions.data[lastIndex].date === data[0].date) {
          let lastDayTransactions = newTransactions.splice(0, 1);
          currentTransactions[lastIndex] = {
            date: data[0].date,
            transactionsByDate: [...transactions.data[lastIndex].transactionsByDate, ...lastDayTransactions[0].transactionsByDate]
          };
        }
        dispatch(globalActions.setTransactionsPagination({ currentPage, totalPages }));
        dispatch(globalActions.setTransactions([...currentTransactions, ...newTransactions]));
      })
      .catch(() => dispatch(globalActions.setHasGlobalError(true)))
      .finally(() => setIsLoading(false));
  }, [transactions, homeFilter.initDate, homeFilter.endDate, transactionTypes, user.storeId, dispatch]);

  return {
    isLoading,
    getPage
  }
};

export default usePagination;
