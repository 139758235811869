import axios from "axios";
import { POCKET_API, TRANSACTIONS_API } from "@constants";
import { CreditPaymentBody, ProviderCreditDetail, ProvidersWithCredit } from "../entities";

export const fetchGetCreditDetail = (storeId: number, id?: string): Promise<ProviderCreditDetail> =>
  axios.get<ProviderCreditDetail>(`${TRANSACTIONS_API}/credit/${id}/${storeId}`)
    .then(({ data }) => data);

export const fetchCreateNewCreditPayment = (body: CreditPaymentBody): Promise<string> =>
  axios.post(`${POCKET_API}/transaction/credit`, body)
    .then(({ data }) => data.id);

export const fetchGetDebtsProviders = (storeId: number): Promise<ProvidersWithCredit[]> =>
  axios.get(`${TRANSACTIONS_API}/credit/${storeId}`)
    .then(({ data }) => data);
