import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions as globalActions } from '@config/store';
import { actions, currentExpenseSelector, expensesDataSelector } from '@modules/expenses/store';
import { EGRESS_TYPE_TOUR_STEP, EXPENSES_HEADER_NAME, EXPENSES_TYPES, HOME_STEP } from '@modules/expenses/constants';
import { ROUTES } from '@constants';
import UiSelectionList from '@ui/ui-selection-list';
import { UiSelectionListItem } from '@entities';
import styles from './expenses-home.module.sass';

interface ExpensesHomeProps { }

const ExpensesHome: FC<ExpensesHomeProps> = () => {
  const dispatch = useDispatch();
  const currentExpense = useSelector(currentExpenseSelector);
  const { currentMacroEgressTypeId } = useSelector(expensesDataSelector);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(globalActions.header({
      title: t(EXPENSES_HEADER_NAME),
      hasRightClick: true,
      routeOnRightClick: ROUTES.home,
    }));
  }, [dispatch, t])

  useEffect(() => {
    dispatch(actions.setStep(HOME_STEP));
    dispatch(actions.setTourStep(EGRESS_TYPE_TOUR_STEP));
    dispatch(actions.restartState());
    return () => {
      dispatch(actions.setTourStep(0))
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.setFooter({
      routeToNext: currentExpense,
      routeToBack: ROUTES.home,
      isDisabled: !currentExpense
    }));
  }, [currentExpense, dispatch]);

  const handleCurrentExpense = useCallback((item): void => {
    dispatch(actions.setCurrentMacroEgress(item.id));
    dispatch(actions.setCurrentExpense(EXPENSES_TYPES.find(i => i.id === item.id)?.path!));
  }, [dispatch]);

  const getList = useMemo((): UiSelectionListItem[] => {
    return EXPENSES_TYPES.map((type) => ({ id: type.id, name: type.name, icon: type.icon }));
  }, []);

  return (
    <div className={styles.wrapper}>
      <UiSelectionList
        list={getList}
        selected={currentMacroEgressTypeId}
        onItemClick={handleCurrentExpense}
      />
    </div>
  );
}

export default ExpensesHome;
