import React, { FC, useCallback } from 'react';
import { To, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CONTACT_LOAN_ONBOARDING_ID } from '@constants';
import useCustomerTransaction from '@modules/customers/hooks/use-customer-transaction';
import CustomersTransaction from '@modules/customers/components/customers-transaction';
import { globalContactsSelector } from '@config/store';

interface CustomersContactTransactionProps { }

const CustomersContactTransaction: FC<CustomersContactTransactionProps> = () => {
  const { t } = useTranslation();
  const { contactId } = useParams();
  const { createNewLoanToContact } = useCustomerTransaction();
  const contacts = useSelector(globalContactsSelector);

  const handleSubmit = useCallback((total: string, mustSendWp: boolean) => {
    const contact = contacts.find(c => contactId && c.contactId === +contactId);
    if (contact) {
      createNewLoanToContact({
        name: contact.name,
        phone: contact.phone,
        areaCode: t('country.area_code')
      }, total, mustSendWp)
    }
  }, [contactId, contacts, createNewLoanToContact, t]);

  return (
    <CustomersTransaction
      header={{
        title: t('Bolsillo.fiar.topBar.titulo1'),
        hasRightClick: true,
        hasBackClick: false,
        routeOnRightClick: -1 as To
      }}
      title={t('Bolsillo.fiar.title1')}
      onSubmit={handleSubmit}
      obId={CONTACT_LOAN_ONBOARDING_ID}
    />
  );
}

export default CustomersContactTransaction;
