export const publicService = {
  spanish: {
    "public_service": {
      "water": 'Agua',
      "energy": 'Energia',
      "gas": 'Gas',
      "internet": 'Internet',
      "telephone": 'Teléfono',
    }
  },
  portuguese: {
    "public_service": {
      "water": 'Água',
      "energy": 'Energia',
      "gas": 'Gás',
      "internet": 'Internet',
      "telephone": 'Telefone',
    }
  }
};
