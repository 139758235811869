import React, { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useOnboarding from '@hooks/use-onboarding';
import Clevertap, { OB_START } from '@config/clevertap';
import { STEP_MAP } from '@modules/base/constants';
import { BASE_ONBOARDING_ID } from '@constants';
import { currentAmountSelector } from '@modules/base/store';
import UiTourTooltip from '@ui/ui-tour-tooltip';

interface BaseTourTooltipProps { }

const BaseTourTooltip: FC<BaseTourTooltipProps> = () => {
  const currentAmount = useSelector(currentAmountSelector);
  const { isOnboardingDone } = useOnboarding();

  useEffect(() => {
    if (!isOnboardingDone(BASE_ONBOARDING_ID)) {
      Clevertap.pushEvent(OB_START, { obType: BASE_ONBOARDING_ID });
    }
  }, [isOnboardingDone]);

  const currentStep = useMemo(() => {
    return !currentAmount ? STEP_MAP[1][1] : STEP_MAP[1][2]
  }, [currentAmount]);

  return (
    <UiTourTooltip
      currentStep={currentStep}
      onboardingId={BASE_ONBOARDING_ID}
    />
  )
}

export default BaseTourTooltip;
