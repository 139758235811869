import { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useMoneyField = (onChange: ChangeEventHandler<HTMLInputElement>, value?: string) => {
  const { t } = useTranslation();
  const [moneyValue, setMoneyValue] = useState('');

  const conversion = useCallback((number?: string): string => {
    if (!number) return '';

    let [integer, decimal] = number.split('.');
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, t("currency.thousand_separator"));
    const hasDecimals = number.includes('.') && +t("currency.decimals");

    return integer + (hasDecimals ? t("currency.decimal_separator") + (decimal || '') : '')
  }, [t]);

  useEffect(() => {
    setMoneyValue(conversion(String(value)))
  }, [conversion, value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let newValue = event.target.value.trim();
    const currentInput = newValue.slice(0, -1).replace(new RegExp(`\\${t("currency.thousand_separator")}`, 'g'), '');
    const lastInput = newValue.slice(-1).replace(/[^0-9,.]/g, '');
    newValue = (currentInput + lastInput).replace(/[.,]/g, '.');

    const hasDecimalOverflow = newValue.split('.')[1]?.length > +t("currency.decimals");

    if (!isNaN(+newValue) && !hasDecimalOverflow && newValue !== '00') {
      if (newValue[0] === '0' && (newValue[1] && newValue[1] !== '.')) {
        newValue = newValue.replace(/^0+/, '');
      }
      event.target.value = newValue
      onChange(event);
    }
  };

  return {
    moneyValue,
    handleChange
  };
};

export default useMoneyField;