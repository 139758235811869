import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@config/store';
import { HomeDateState, HomePaginationState } from '.';
import { GetTransactionsResponse } from '@entities';

export const homeUiFilterCalendarSelector = createSelector(
  (state: RootState) => state.home.ui.showCalendar,
  (state: boolean) => state
);

export const homeUiFiltersSelector = createSelector(
  (state: RootState) => state.home.ui.isFilterActive,
  (state: boolean) => state
);

export const homeUiApplyFiltersSelector = createSelector(
  (state: RootState) => state.home.ui.mustApplyFilters,
  (state: boolean) => state
);

export const homeSearchSelector = createSelector(
  (state: RootState) => state.home.data.searchValue,
  (state: string) => state
);

export const homePaginationSelector = createSelector(
  (state: RootState) => state.home.data.pagination,
  (state: HomePaginationState) => state
);

export const homeFiltersDateSelector = createSelector(
  (state: RootState) => state.home.data.filters.range,
  (state: HomeDateState) => state
);

export const homeFilterMovementsSelector = createSelector(
  (state: RootState) => state.home.data.filters.movements,
  (state: number[]) => state
);

export const homeFilterResultsSelector = createSelector(
  (state: RootState) => state.home.data.filters.results,
  (state: GetTransactionsResponse) => state
);
