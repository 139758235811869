import React, { FC } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { customerDetailSelector } from '@modules/customers/store';
import { TABS } from '@modules/customers/constants';
import styles from "./customer-detail-info.module.sass";

interface CustomerDetailInfoProps { }

const CustomerDetailInfo: FC<CustomerDetailInfoProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const customerDetail = useSelector(customerDetailSelector);

  const items = [
    { title: "Bolsillo.detalleCliente.datosContacto.subtitulo1", value: customerDetail.name },
    { title: "Bolsillo.detalleCliente.datosContacto.subtitulo2", value: customerDetail.phone }
  ];

  const handleEditClick = (): void => navigate(TABS.edition);

  return (
    <div className={styles.wrapper}>

      <div className={styles.top}>
        <div className={styles.title}>{t('Bolsillo.detalleCliente.datosContacto.titulo1')}</div>
        <span className={styles.update} onClick={handleEditClick}>
          {t('Bolsillo.detalleCliente.datosContacto.botonEditar')}
        </span>
      </div>

      {items.map((item, i) => (
        <div className={styles.costumerInfo} key={`update-item-${i}`}>
          <div className={styles.name}>{t(item.title)}</div>
          <span className={styles.item}>{item.value}</span>
        </div>
      ))}

    </div>
  )
}

export default CustomerDetailInfo;
