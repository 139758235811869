import { BoxClosureDetail, BoxClosureDetailTransactionsResponse, EditionTypeId } from "../entities";

export interface BoxClosureState {
  ui: BoxClosureUiState;
  data: BoxClosureDataState;
}

export interface BoxClosureUiState {
  editionActiveId: EditionTypeId;
}

export interface BoxClosureFormState {
  incomes: number;
  expenses: number;
  base: number;
  loans: number;
  debts: number;
}

export interface BoxClosureDetailState {
  data: BoxClosureDetail;
  transactions: BoxClosureDetailTransactionsResponse;
}

export interface BoxClosureDataState {
  form: BoxClosureFormState;
  initialForm: BoxClosureFormState;
  detail: BoxClosureDetailState;
}

export const initialState: BoxClosureState = {
  ui: {
    editionActiveId: 0,
  },
  data: {
    form: {} as BoxClosureFormState,
    initialForm: {} as BoxClosureFormState,
    detail: {
      data: {} as BoxClosureDetail,
      transactions: {
        currentPage: 0,
        totalPages: 0,
        data: []
      }
    },
  },
};
