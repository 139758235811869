import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { TABS } from "@modules/income/constants";
import IncomeAmount from "@modules/income/pages/income-amount";
import IncomePaymentMethods from "@modules/income/pages/income-payment-methods";
import { ROUTES } from "@constants";

interface IncomeRouterProps { }

const IncomeRouter: FC<IncomeRouterProps> = () => {
  return (
    <Routes>
      <Route index element={<IncomeAmount />} />
      <Route path={TABS.paymentMethod} element={<IncomePaymentMethods />} />
      <Route path="*" element={<Navigate to={ROUTES.income} />} />
    </Routes>
  );
};

export default IncomeRouter;
