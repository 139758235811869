export const others = {
  spanish: {
    "others_title": "Tipo",
    "reason_title": "Motivo",
    "reason_options_title": "Selecciona una opción",
    "reason_helper_text": "Nómina, Mobiliario, Arreglos, Deudas etc.",
    "reason": {
      "unexpected": "Imprevistos",
      "financial_services": "Servicios financieros",
      "public_services": "Servicios públicos",
      "general_services": "Servicios generales/mantenimiento",
      "payroll": "Nómina",
      "buy_technology": "Compra tecnología",
      "taxes": "Impuestos",
      "furniture": "Mobiliario",
      "arrangements": "Arreglos",
      "fuels": "Combustibles",
      "debts": "Deudas"
    },
  },
  portuguese: {
    "others_title": "Tipo",
    "reason_title": "Motivo",
    "reason_options_title": "Selecione uma opção",
    "reason_helper_text": "Folha de pagamento, Mobiliário, Arranjos, Dívidas etc.",
    "reason": {
      "unexpected": "Imprevistos",
      "financial_services": "Serviços financeiros",
      "public_services": "Serviços públicos",
      "general_services": "Serviços gerais/mantimentos",
      "payroll": "Folha de pagamento",
      "buy_technology": "Compra de tecnología",
      "taxes": "Impostos",
      "furniture": "Mobiliário",
      "arrangements": "Arranjos",
      "fuels": "Combustível",
      "debts": "Dívidas"
    },
  },
};
