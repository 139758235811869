import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TransactionType } from '@constants';
import { UiMoneyValueProps } from '@entities';
import { boxClosureDetailSelector } from '@modules/box-closure/store';
import styles from './box-closure-balances-detail.module.sass';
import UiDivider from '@ui/ui-divider';
import UiMoneyValue from '@ui/ui-money-value';

interface BoxClosureBalancesDetailProps { }

const BoxClosureBalancesDetail: FC<BoxClosureBalancesDetailProps> = () => {
  const { t } = useTranslation();
  const detail = useSelector(boxClosureDetailSelector);

  const balances: { text: string, moneyValueProps: UiMoneyValueProps }[] = [
    { text: 'income', moneyValueProps: { value: detail.income, type: TransactionType.income } },
    { text: 'expenses', moneyValueProps: { value: detail.egress, type: TransactionType.expense } },
    { text: 'base', moneyValueProps: { value: detail.base, type: TransactionType.base } },
    { text: 'loan', moneyValueProps: { value: detail.loan, type: TransactionType.loan } },
    // { text: 'debts', moneyValueProps: { value: detail.debt, type: TransactionType.loan } }
  ];

  return (
    <div className={styles.wrapper}>

      <div className={styles.total}>
        {t('Bolsillo.caja.topbar.title')}
        <UiMoneyValue value={detail.balance} size='large' type='regular' />
      </div>

      <UiDivider isSmall />

      <div className={styles.balances}>
        {balances.map((item) => (
          <div className={styles.balanceItem} key={`closure-balances-${item.text}`}>
            {t(`box_closure.title.${item.text}`)}
            <UiMoneyValue {...item.moneyValueProps} />
          </div>
        ))}
      </div>

    </div>
  )
}

export default BoxClosureBalancesDetail;
