import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CUSTOMERS_PATH, TABS } from '@modules/customers/constants';
import { FIRST_LOAN_ONBOARDING_ID } from '@constants';
import useCustomerTransaction from '@modules/customers/hooks/use-customer-transaction';
import CustomersTransaction from '@modules/customers/components/customers-transaction';
import useNotification from '@hooks/use-notification';

interface CustomersCreationLoanAmountProps { }

const CustomersCreationLoanAmount: FC<CustomersCreationLoanAmountProps> = () => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const { createNewLoanBeforeCreation } = useCustomerTransaction();
  const showNotification = useNotification();

  useEffect(() => {
    return () => { showNotification(t('customers.notification.creation'), true) }
  }, [showNotification, t]);

  return (
    <CustomersTransaction
      header={{
        title: t('Bolsillo.fiar.topBar.titulo3'),
        hasRightClick: true,
        hasBackClick: false,
        routeOnRightClick: `${CUSTOMERS_PATH}/${TABS.detail}/${customerId}`
      }}
      title={t('Bolsillo.fiar.title1')}
      onSubmit={createNewLoanBeforeCreation}
      obId={FIRST_LOAN_ONBOARDING_ID}
    />
  );
}

export default CustomersCreationLoanAmount;
