import React, { FC } from 'react';
import styles from './ui-divider.module.sass';

interface UiDividerProps {
  isSmall?: boolean;
  isDarker?: boolean;
  isGray25?: boolean;
}

const UiDivider: FC<UiDividerProps> = ({ isSmall, isDarker, isGray25 }) => {
  return (
    <div
      className={`
      ${styles.divider} 
      ${isSmall ? styles.small : ""} 
      ${isDarker ? styles.darker : ""}
      ${isGray25 ? styles.gray25 : ""}
    `}
    />
  );
}

export default UiDivider;
