import axios from "axios";
import { CREDIT_TRANSACTION_TYPE_ID, POCKET_API, TRANSACTIONS_API } from "@constants";
import { Expense, MacroEgress, MicroEgress, OthersTypesSuggestions, ProviderSuggestions, SearchedProvider, TypeSuggestion } from "../entities";

export const fetchCreateNewExpense = (body: Expense): Promise<{ id: string; providerId?: number }> =>
  axios.post(`${POCKET_API}/transaction/${body.transactionTypeId === CREDIT_TRANSACTION_TYPE_ID ? 'credit' : 'egress'}`, body)
    .then(({ data }) => ({ id: data.id, providerId: data.providerId }));

export const fetchGetMacroEgress = (): Promise<MacroEgress[]> =>
  axios.get<MacroEgress[]>(`${POCKET_API}/macro-egress-type`)
    .then(resp => resp.data);

export const fetchGetMicroEgress = (macroEgessTypeId: string): Promise<MicroEgress[]> =>
  axios.get<MicroEgress[]>(`${POCKET_API}/macro-egress-type/${macroEgessTypeId}`)
    .then(resp => resp.data);

export const fetchSearchProvider = (storeId: number, value: string): Promise<SearchedProvider[]> =>
  axios.get<SearchedProvider[]>(`${TRANSACTIONS_API}/provider/all/${storeId}?search=${value}`)
    .then(resp => resp.data)
    .catch(() => []);

export const fetchProviderSuggestions = (storeId: number): Promise<ProviderSuggestions> =>
  axios.get<ProviderSuggestions>(`${TRANSACTIONS_API}/provider/most-recent/${storeId}`)
    .then(resp => resp.data)
    .catch(() => { return { mostUsed: [], mostRecent: [] } });

export const fetchDeleteMostRecentProvider = (providerId: number, storeId: number): Promise<SearchedProvider[]> =>
  axios.post(`${TRANSACTIONS_API}/provider/analysis/delete`, { providerId, storeId })
    .then((resp) => (resp.data.mostRecent))
    .catch(() => []);

export const fetchDeleteLastOthersTypeSuggestion = (otherEgressId: number, storeId: number): Promise<TypeSuggestion> =>
  axios.post(`${TRANSACTIONS_API}/other-egress/reset-use`, { storeId, otherEgressId })
    .then((resp) => (resp.data));

export const fetchOthersType = (storeId: number): Promise<OthersTypesSuggestions> =>
  axios.get<OthersTypesSuggestions>(`${TRANSACTIONS_API}/other-egress/suggest/${storeId}`)
    .then(resp => resp.data)
    .catch(() => { return { lastUsed: { id: 0, name: '', macroEgressTypeId: 0 }, suggestions: [] } });

export const fetchSearchOthersType = (storeId: number, value: string): Promise<TypeSuggestion[]> =>
  axios.get<TypeSuggestion[]>(`${TRANSACTIONS_API}/other-egress/search/${storeId}?search=${value}`)
    .then(resp => resp.data)
    .catch(() => []);
