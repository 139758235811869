import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@config/store';
import { GlobalDataState, UserState } from '.';
import { GetTransactionsResponse, Header, Notification, TransactionsByDate } from '@entities';

export const globalUiLoaderSelector = createSelector(
  (state: RootState) => state.global.ui.isLoading,
  (state: boolean) => state
);

export const globalUiReadyPocketSelector = createSelector(
  (state: RootState) => state.global.ui.isReadyToUse,
  (state: boolean) => state
);

export const globalUiNotificationSelector = createSelector(
  (state: RootState) => state.global.ui.notification,
  (state: Notification) => state
);

export const globalUiHeaderSelector = createSelector(
  (state: RootState) => state.global.ui.header,
  (state: Header) => state
);

export const globalUiErrorSelector = createSelector(
  (state: RootState) => state.global.ui.hasGlobalError,
  (state: boolean) => state
);

export const globalUserSelector = createSelector(
  (state: RootState) => state.global.data.user,
  (state: UserState) => state
);

export const globalBalancesSelector = createSelector(
  (state: RootState) => state.global.data,
  (state: GlobalDataState) => state.balances
);

export const globalTransactionsSelector = createSelector(
  (state: RootState) => state.global.data.transactions.data,
  (state: TransactionsByDate[]) => state
);

export const globalTransactionsCompleteSelector = createSelector(
  (state: RootState) => state.global.data.transactions,
  (state: GetTransactionsResponse) => state
);

export const globalPaymentMethodsSelector = createSelector(
  (state: RootState) => state.global.data,
  (state: GlobalDataState) => state.paymentMethods
);

export const globalContactsSelector = createSelector(
  (state: RootState) => state.global.data,
  (state: GlobalDataState) => state.contacts
);

export const globalOnboardingSelector = createSelector(
  (state: RootState) => state.global.data,
  (state: GlobalDataState) => state.onboarding
);

export const globalContactsPermissionSelector = createSelector(
  (state: RootState) => state.global.ui.contactsPermissionGranted,
  (state: boolean) => state
);
