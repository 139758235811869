import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Clevertap, { START_LEDGER } from '@config/clevertap';
import { Tokens } from "@entities";
import { actions as globalActions, actions, globalUserSelector } from "@config/store";
import {
  fetchCreateNewCashId, fetchGetBalances, fetchGetTransactions,
  fetchGetOnboarding, fetchUserLogin
} from "@services";
import { DEFAULT_TRANSACTIONS_PER_PAGE, INCOMPLETE_STATUS_ONBOARDING_ID, STORAGE_TOKEN } from "@constants";

const useSessionManager = () => {
  const dispatch = useDispatch();
  const user = useSelector(globalUserSelector);

  const getOnboardings = useCallback((storeId: number) => {
    return fetchGetOnboarding(storeId)
      .then((data) => dispatch(globalActions.setOnboarding({
        done: data
          .filter(item => item.onboardingStatusId !== INCOMPLETE_STATUS_ONBOARDING_ID)
          .map(ob => ob.onboardingId)
      })))
      .catch(() => false)
  }, [dispatch]);

  const login = useCallback((storeId: number) => {
    const tokenString = localStorage.getItem(STORAGE_TOKEN) || '';
    const credentials: Tokens = JSON.parse(tokenString || '{}');

    if (tokenString && credentials.token === storeId)
      return Promise.resolve(true);

    return fetchUserLogin(storeId)
      .then((data) => localStorage.setItem(STORAGE_TOKEN, JSON.stringify({ ...data, token: storeId })))
      .then(() => true)
      .catch(() => {
        dispatch(actions.setHasGlobalError(true));
        localStorage.removeItem(STORAGE_TOKEN);
        return false;
      })
  }, [dispatch]);

  const getDashboardInfo = useCallback((storeId: number) => {
    return Promise.all([
      fetchGetBalances(storeId),
      fetchGetTransactions(storeId, { search: '', limit: DEFAULT_TRANSACTIONS_PER_PAGE, skip: 0 })
    ]).then(([balances, transactions]) => {
      dispatch(globalActions.setTransactionsResponse(transactions));
      if (balances.cashId === -1) {
        const body = { storeId, userId: storeId, startTime: new Date(), endTime: null, hasBase: false };
        return fetchCreateNewCashId(body)
          .then(cashId => dispatch(globalActions.setBalance({ ...balances, cashId })))
      } else dispatch(globalActions.setBalance(balances));
    })
      .catch(() => dispatch(globalActions.setHasGlobalError(true)));
  }, [dispatch]);

  const startSession = useCallback((storeId: number) => {
    login(storeId)
      .then((isAuthenticated) => {
        if (isAuthenticated)
          getOnboardings(storeId)
            .then(() => getDashboardInfo(storeId))
            .then(() => dispatch(actions.setIsReadyToUse(true)))
            .then(() => Clevertap.pushEvent(START_LEDGER))
      });
  }, [dispatch, getDashboardInfo, getOnboardings, login]);

  useEffect(() => {
    if (user.storeId) startSession(user.storeId)
  }, [startSession, user.storeId]);

}

export default useSessionManager;
