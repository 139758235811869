import React, { ChangeEvent, FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actions as globalActions } from "@config/store";
import { actions, customersSearchSelector } from "@modules/customers/store";
import styles from "./customers-home-active-search-bar.module.sass";

interface CustomersHomeActiveSearchBarProps { }

const CustomersHomeActiveSearchBar: FC<CustomersHomeActiveSearchBarProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const searchValue = useSelector(customersSearchSelector);

  useEffect(() => {
    dispatch(globalActions.header({ ishidden: true }))
  }, [dispatch]);

  useEffect(() => {
    return () => { dispatch(globalActions.header({ ishidden: false })) }
  }, [dispatch]);

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    dispatch(actions.setSearchValue(target.value));
    window.scrollTo(0, 0);
  }

  const goBack = () => {
    dispatch(actions.setisSearchBarActive(false));
  }

  const clearField = () => {
    ref.current!.value = "";
    dispatch(actions.setSearchValue(""));
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <span className={`icon-back ${styles.backIcon}`} onClick={goBack} />

        <div className={styles.inputWrapper}>
          <span className={`icon-search ${styles.searchIcon}`} />

          <input
            ref={ref}
            placeholder={t("Bolsillo.misClientes.searchBar.buscarCliente")}
            onChange={handleInputChange}
            className={styles.input}
            autoComplete="off"
            autoFocus
            defaultValue={searchValue}
            data-testid="customers-home-active-searchbar"
          />
          {!!searchValue && <span className={`icon-close ${styles.closeIcon}`} onClick={clearField} />}
        </div>

      </div>
    </div>
  );
}

export default CustomersHomeActiveSearchBar;
