import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { actions as globalActions } from '@config/store';
import Clevertap, { CUSTOMER_DETAIL_START } from '@config/clevertap';
import { actions, customerDetailSelector, customersUiBackDetailSelector, customersUiSelector } from '@modules/customers/store';
import { TransactionType } from '@constants';
import styles from './customer-detail.module.sass';
import useNotification from '@hooks/use-notification';
import useCustomersFetch from '@modules/customers/hooks/use-customers-fetch';
import CustomerDetailInfo from '@modules/customers/components/customer-detail-info';
import CustomerDetailButtons from '@modules/customers/components/customer-detail-buttons';
import CustomerDetailSkeleton from '@modules/customers/components/customer-detail-skeleton';
import UiDivider from '@ui/ui-divider';
import UiMoneyValue from '@ui/ui-money-value';
import CustomerDetailTransactionsList from '@modules/customers/components/customer-detail-transactions-list';

interface CustomerDetailProps {
  wasDeleted?: boolean;
}

const CustomerDetail: FC<CustomerDetailProps> = ({ wasDeleted }) => {
  const { t } = useTranslation();
  const { isLoading, isCreationOb } = useSelector(customersUiSelector);
  const { getCustomerDetail } = useCustomersFetch();
  const { id } = useParams();
  const dispatch = useDispatch();
  const showNotification = useNotification();
  const customerDetail = useSelector(customerDetailSelector);
  const backDetailRoute = useSelector(customersUiBackDetailSelector);

  useEffect(() => {
    if (!customerDetail.customerId) {
      getCustomerDetail(Number(id));
      Clevertap.pushEvent(CUSTOMER_DETAIL_START, { clientId: id! });
    }
    return () => {
      if(isCreationOb) dispatch(actions.setIsCreationOb(false));
    }
  }, [dispatch, getCustomerDetail, customerDetail, id, isCreationOb]);

  useEffect(() => {
    dispatch(globalActions.header({
      title: t("Bolsillo.detalleCliente.topBar.detalleDeCliente"),
      routeOnRightClick: backDetailRoute,
      hasBackClick: false,
      hasRightClick: true,
    }));
    window.scrollTo(0, 0);

    if (wasDeleted) showNotification(t('customers.customer_deleted_notification'), false);

    return () => { if (wasDeleted) dispatch(actions.restartDetailState()) }
  }, [backDetailRoute, dispatch, showNotification, t, wasDeleted]);

  if (isLoading) return <CustomerDetailSkeleton />;

  return (
    <div className={styles.wrapper}>
      {wasDeleted && <div className={styles.deleted} />}

      <CustomerDetailInfo />

      <UiDivider />

      <div className={styles.debtsTitle}>
        {t('Bolsillo.detalleCliente.deudaCliente.titulo1')}
      </div>

      <div className={styles.debts}>
        <div className={styles.text}>
          {t('Bolsillo.detalleCliente.deudaCliente.deudaTotalComponente.total')}
        </div>
        <UiMoneyValue value={customerDetail.debt} type={TransactionType.loan} size='large' />
      </div>

      <CustomerDetailButtons />

      <div className={styles.transactionsTitle}>
        {t('Bolsillo.detalleCliente.deudaCliente.titulo2')}
      </div>

      <CustomerDetailTransactionsList />
    </div>
  );
};

export default CustomerDetail;
