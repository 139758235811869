import { GetTransactionsResponse } from "@entities";

export const TABS = {
  filters: 'filters',
  search: 'search',
};

export const MAX_FILTER_RANGE = 90;

export const INIT_FILTERS_STATE = {
  range: { initDate: '', endDate: '' },
  movements: [],
  results: {} as GetTransactionsResponse,
};
