import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './customers-home-skeleton.module.sass';
import UiDivider from '@ui/ui-divider';
import CustomersCreationButton from '../customers-creation-button';

interface CustomersHomeSkeletonProps { }

const CustomersHomeSkeleton: FC<CustomersHomeSkeletonProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      
      <div className={styles.wrapper}>

      <div className={styles.searchBar}>
        <span className={`icon-search ${styles.searchIcon}`} />
        <input className={styles.input} placeholder={t('Bolsillo.misClientes.searchBar.buscarCliente')} disabled />
      </div>

        <div className={styles.total}>
          <div className={styles.text} />
          <div className={styles.value} />
        </div>

        <div className={styles.title} />

        <div className={styles.customer}>
          <div className={styles.info}>
            <div className={styles.icon} />
            <div className={styles.mid}>
              <div className={styles.name} />
              <div className={styles.dateText} />
            </div>
          </div>
          <div className={styles.value} />
        </div>

        <UiDivider />

        <div className={styles.customersTitle} />

        {Array(2).fill('').map((_, i) => (
          <div key={`customers-info-list-${i}`}>
            <div className={styles.customersDate} />
            {Array(4).fill('').map((__, j) => (
              <div className={styles.contact} key={`customer-info-${j}`}>
                <div className={styles.info}>
                  <div className={styles.icon} />
                  <div className={styles.mid}>
                    <div className={styles.name} />
                    <div className={styles.dateText} />
                  </div>
                </div>
                <div className={styles.value} />
              </div>
            ))}
            <UiDivider isSmall />
          </div>
        ))}

        <CustomersCreationButton />

      </div>

    </div>
  )
}

export default CustomersHomeSkeleton;