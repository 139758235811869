import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { expensesDataSelector } from "@modules/expenses/store";
import { actions as globalActions, globalBalancesSelector, globalPaymentMethodsSelector, globalUserSelector } from "@config/store";
import { fetchCreateNewExpense } from "@modules/expenses/services";
import useFormatCurrency from "@hooks/use-format-currency";
import {
  CUSTOM_OTHERS_EXPENSE_ID,
  CUSTOM_PROVIDER_EXPENSE_ID, Expenses,
  OTHERS_EXPENSE_ID, PROVIDER_EXPENSE_ID, PROVIDER_IMAGE
} from "@modules/expenses/constants";
import {
  CREDIT_PAYMENT_METHOD_ID, CREDIT_TRANSACTION_TYPE_ID,
  EXPENSES_ONBOARDING_ID, EXPENSE_TRANSACTION_TYPE_ID, ROUTES
} from "@constants";
import { Expense, MicroType } from "@modules/expenses/entities";
import useUpdateWallet from "@hooks/use-update-wallet";
import useNotification from "@hooks/use-notification";
import { showEcommerceTabs } from "@utils/webview";
import useOnboarding from "@hooks/use-onboarding";
import Clevertap, { MOVEMENT_COMPLETE } from "@config/clevertap";

const useExpensesFetch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(expensesDataSelector);
  const { format } = useFormatCurrency();
  const { t } = useTranslation();
  const paymentMethods = useSelector(globalPaymentMethodsSelector);
  const { setNewExpense } = useUpdateWallet();
  const user = useSelector(globalUserSelector);
  const balances = useSelector(globalBalancesSelector);
  const showNotification = useNotification();
  const { setExpensesOnboardingDone, isOnboardingDone } = useOnboarding();

  const generateBody = useCallback((): Expense => {
    const name = t(data.currentMicroEgressName);
    const getMicroType = (): MicroType => {
      switch (data.currentMacroEgressTypeId) {
        case PROVIDER_EXPENSE_ID: return { microEgressTypeId: data.currentMicroEgressTypeId };
        case CUSTOM_PROVIDER_EXPENSE_ID: return { name, image: PROVIDER_IMAGE };
        case OTHERS_EXPENSE_ID: return { microEgressTypeId: data.currentMicroEgressTypeId }
        case CUSTOM_OTHERS_EXPENSE_ID: {
          if (data.currentMicroEgressTypeId === 0) {
            return { name: data.currentMicroEgressName }
          }
          return { microEgressTypeId: data.currentMicroEgressTypeId }
        }
        default: return { name, microEgressTypeId: data.currentMicroEgressTypeId }
      }
    }
    return {
      transactionTypeId: data.currentPaymentMethodId === CREDIT_PAYMENT_METHOD_ID
        ? CREDIT_TRANSACTION_TYPE_ID
        : EXPENSE_TRANSACTION_TYPE_ID,
      paymentMethodId: +data.currentPaymentMethodId,
      total: +data.currentAmount,
      userId: user.storeId,
      storeId: user.storeId,
      macroType: { macroEgressTypeId: +data.currentMacroEgressTypeId },
      microType: getMicroType(),
      cashDeckControlId: balances.cashId
    }
  }, [data, t, user.storeId, balances]);

  const createNewExpense = useCallback((): void => {
    dispatch(globalActions.setIsLoading(true));
    const body = generateBody();
    fetchCreateNewExpense(body)
      .then(({ id, providerId }) => {
        showEcommerceTabs();
        setNewExpense(id, data, providerId);
        const message = t("expenses.notification_message", {
          amount: format(+data.currentAmount, true),
          method: data.currentExpense === Expenses.rent
            ? t("expenses.type.rent")
            : t(`${paymentMethods.find(e => e.id === data.currentPaymentMethodId)?.name}`)
        });
        showNotification(message, true);

        if (!isOnboardingDone(EXPENSES_ONBOARDING_ID)) setExpensesOnboardingDone(body.total);
        
        Clevertap.pushEvent(MOVEMENT_COMPLETE, {
          type: body.transactionTypeId,
          value: body.total,
          paymentMethod: body.paymentMethodId,
        });
        navigate(ROUTES.home)
      })
      .catch(() => showNotification(t('expenses.notification_error_message'), false))
      .finally(() => dispatch(globalActions.setIsLoading(false)));
  }, [dispatch, generateBody, setNewExpense, data, t, format, paymentMethods, showNotification, isOnboardingDone, setExpensesOnboardingDone, navigate]);

  return { createNewExpense }
};

export default useExpensesFetch;
