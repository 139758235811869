import React, { FC } from 'react';
import { To } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FIRST_LOAN_ONBOARDING_ID } from '@constants';
import useCustomerTransaction from '@modules/customers/hooks/use-customer-transaction';
import CustomersTransaction from '@modules/customers/components/customers-transaction';

interface CustomersLoanTransactionProps { }

const CustomersLoanTransaction: FC<CustomersLoanTransactionProps> = () => {
  const { t } = useTranslation();
  const { createNewLoan } = useCustomerTransaction();

  return (
    <CustomersTransaction
      header={{
        title: t('Bolsillo.fiar.topBar.titulo2'),
        hasRightClick: true,
        hasBackClick: false,
        routeOnRightClick: -1 as To
      }}
      title={t('Bolsillo.fiar.title1')}
      onSubmit={createNewLoan}
      obId={FIRST_LOAN_ONBOARDING_ID}
    />
  );
}

export default CustomersLoanTransaction;
