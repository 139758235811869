import { ClevertapEventPayload } from "@config/clevertap/clevertap.entities";

interface ReactNativeWebViewGlobal {
  postMessage: (json: string) => void;
}

declare global {
  interface Window { ReactNativeWebView: ReactNativeWebViewGlobal }
}

const handleTabs = ({ showTabs }: { showTabs: boolean }): void => {
  window.ReactNativeWebView?.postMessage(JSON.stringify({ showTabs }));
}

export const showEcommerceTabs = (): void => handleTabs({ showTabs: true });

export const hideEcommerceTabs = (): void => handleTabs({ showTabs: false });

export const sendNotification = (message: string, state: 'success' | 'error'): void => {
  window.ReactNativeWebView?.postMessage(JSON.stringify({
    notification: { state, message }
  }));
}

export const sendEvent = (name: string, properties?: ClevertapEventPayload): void => {
  window.ReactNativeWebView?.postMessage(JSON.stringify({
    event: {
      name,
      payload: {
        ...properties
      }
    }
  }));
}

export const requestContactsPermissions = (): void => {
  window.ReactNativeWebView?.postMessage(JSON.stringify({ requestContactsPermission: true }));
}

export const navigateToPermissionsPanel = (): void => {
  window.ReactNativeWebView?.postMessage(JSON.stringify({ navigateToPermissions: true }));
}

export const setUXCamScreen = (screen: string): void => {
  window.ReactNativeWebView?.postMessage(JSON.stringify({ screen }));
}

export const sendWhatsApp = (phone: string, message: string): void => {
  window.ReactNativeWebView?.postMessage(JSON.stringify({
    whatsapp: { to: phone, message }
  }));
}
