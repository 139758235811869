import { GetTransactionsResponse } from "@entities";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INIT_FILTERS_STATE } from "../constants";
import { HomeDateState, HomePaginationState, initialState } from './home.state';

export const homeSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setShowCalendar: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.showCalendar = payload;
    },
    setIsFilterActive: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.isFilterActive = payload;
    },
    setMustApplyFilters: (state, { payload }: PayloadAction<boolean>) => {
      state.ui.mustApplyFilters = payload;
    },
    setSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.data.searchValue = payload;
    },
    setPagination: (state, { payload }: PayloadAction<HomePaginationState>) => {
      state.data.pagination = payload;
    },
    setFiltersDate: (state, { payload }: PayloadAction<Partial<HomeDateState>>) => {
      state.data.filters.range = { ...state.data.filters.range, ...payload };
    },
    setFiltersMovements: (state, { payload }: PayloadAction<number[]>) => {
      state.data.filters.movements = payload;
    },
    setFiltersResults: (state, { payload }: PayloadAction<GetTransactionsResponse>) => {
      state.data.filters.results = payload;
    },
    resetFilters: (state) => {
      state.data.filters = INIT_FILTERS_STATE;
      if (state.ui.isFilterActive) state.ui.isFilterActive = false;
    },
  },
});

export const actions = homeSlice.actions;
export default homeSlice.reducer;
