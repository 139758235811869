import { BASE_TRANSACTION_TYPE_ID, EXPENSE_TRANSACTION_TYPE_ID, INCOME_TRANSACTION_TYPE_ID } from "@constants";

export const TABS = {
  edition: 'edition',
  detail: 'detail',
};

export const TRANSACTIONS_ID = {
  expense: EXPENSE_TRANSACTION_TYPE_ID,
  income: INCOME_TRANSACTION_TYPE_ID,
  base: BASE_TRANSACTION_TYPE_ID,
};

export const TRANSACTIONS_PER_PAGE = 10;

export const NONE_EDITION_ID = 0;
export const INCOME_EDITION_ID = 1;
export const EXPENSE_EDITION_ID = 2;
export const BASE_EDITION_ID = 3;
