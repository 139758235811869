import { FC, memo } from 'react';
import styles from "./ui-money-text.module.sass";
import useFormatCurrency from '@hooks/use-format-currency';
import { MoneyTextTypes } from '@constants';

interface UiMoneyTextProps {
  value?: number;
  type?: string;
  isSmall?: boolean;
  isLarge?: boolean;
  negative?: boolean;
}

const UiMoneyText: FC<UiMoneyTextProps> = ({
  value = 0,
  type = 'normal',
  isSmall,
  isLarge,
  negative,
}) => {
  const { sign, format } = useFormatCurrency();
  const isNegative = (): boolean => negative || (type === MoneyTextTypes.expense && value !== 0) || value < 0

  return (
    <div className={`
      ${styles.value} 
      ${styles[type]}
      ${value === 0 && styles.zero}
      ${isSmall && styles.small}
      ${isLarge && styles.large}
      ${isNegative() && styles.expense}
    `
    }>
      <div className={styles.sign}>
        {<span className={styles.minus}>-</span>}
        {sign}
      </div>
      <span className={styles.number}>{format(Math.abs(value))}</span>
    </div>
  );
}

export default memo(UiMoneyText);
