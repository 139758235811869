import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from '@config/store';
import { actions } from './store';
import CreditsRouter from './routers';

interface CreditsProps { }

const Credits: FC<CreditsProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.restartState());
    return () => { dispatch(globalActions.header({ hasBackClick: false })) }
  }, [dispatch]);

  return (
    <CreditsRouter />
  );
}

export default Credits;
