import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@modules/expenses/store';
import { Expenses, OTHERS_PAYMENT_METHOD_STEP, OTHERS_TABS } from '@modules/expenses/constants';
import PaymentMethods from '../../components/payment-methods';

interface OthersExpensesPaymentMethodProps { }

const OthersExpensesPaymentMethod: FC<OthersExpensesPaymentMethodProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setStep(OTHERS_PAYMENT_METHOD_STEP));
    dispatch(actions.setFooter({
      routeToNext: `${Expenses.others}/${OTHERS_TABS.amount}`,
      routeToBack: `${Expenses.others}/${OTHERS_TABS.type}`,
    }));
  }, [dispatch]);

  return (
    <PaymentMethods />
  )
}

export default OthersExpensesPaymentMethod;
