import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@config/store';
import { BaseUiState, BaseDataState } from '.';

export const baseUiSelector = createSelector(
  (state: RootState) => state.base.ui,
  (state: BaseUiState) => state
);

export const baseNavigationSelector = createSelector(
  (state: RootState) => state.base.ui.escapeRoute,
  (state: string) => state
);

export const baseDataSelector = createSelector(
  (state: RootState) => state.base.data,
  (state: BaseDataState) => state
);

export const currentAmountSelector = createSelector(
  (state: RootState) => state.base.data,
  (state: BaseDataState) => state.currentAmount
);

export const currentReasonSelector = createSelector(
  (state: RootState) => state.base.data,
  (state: BaseDataState) => state.currentReason
);

export const baseTransactionsSelector = createSelector(
  (state: RootState) => state.base.data,
  (state: BaseDataState) => state.baseTransactions
);
