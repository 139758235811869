import React, { ChangeEventHandler, FC, memo, useRef } from 'react';

import styles from './ui-input-field.module.sass';

interface UiInputFieldProps {
  label: string;
  type?: string;
  name: string;
  value?: string;
  disabled?: boolean;
  hasAutoFocus?: boolean;
  hasError?: boolean;
  error?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  helperText?: boolean;
  hint?: string;
  placeholder?: string;
  onFocus?: ChangeEventHandler<HTMLInputElement>;
  withoutBorder?: boolean;
  errorMessage?: string;
}

const UiInputField: FC<UiInputFieldProps> = ({
  hasError = false,
  label = '',
  name,
  value,
  disabled,
  hasAutoFocus,
  type = 'text',
  onChange,
  onBlur,
  helperText,
  hint = '',
  placeholder = '',
  onFocus,
  withoutBorder,
  errorMessage = '',
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <label className={`
        ${styles.wrapper} 
        ${hasError && styles.error} 
        ${disabled && styles.disabled}
        ${withoutBorder && styles.withoutBorder}
      `}>
        <div className={styles.left}>
          <span className={styles.label}>{label}</span>
          <input
            ref={inputRef}
            data-testid="ui-input-field"
            type={type}
            name={name}
            value={value}
            className={styles.input}
            onChange={onChange}
            onBlur={onBlur}
            autoFocus={hasAutoFocus}
            disabled={disabled}
            placeholder={placeholder}
            autoComplete='off'
            onFocus={onFocus}
          />
        </div>
      </label>
      {helperText && <span className={styles.helperText}>{hint}</span>}
      {(hasError && !!errorMessage) && <p className={styles.errorMessage}>{errorMessage}</p>}
    </>
  );
}

export default memo(UiInputField);
