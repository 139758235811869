import { StepSection } from "@entities";
import { TITLES } from "./expenses.constants";

export const RENT_TOTAL_STEPS = 3;

export const RENT_PAYMENT_METHOD_STEP: StepSection = {
  totalSteps: RENT_TOTAL_STEPS,
  currentStep: 2,
  stepName: TITLES.paymenentMethod
};

export const RENT_AMOUNT_STEP: StepSection = {
  totalSteps: RENT_TOTAL_STEPS,
  currentStep: 3,
  stepName: TITLES.amount
};

export const RENT_TABS = {
  paymentMethod: 'payment_method',
  amount: 'amount'
};

export const RENT_MICRO_EGRESS = {
  id: 13,
  name: 'expenses.type.rent',
};
