import { FC, useCallback, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { actions, baseUiSelector } from "@modules/base/store";
import Clevertap, { MOVEMENT_START } from '@config/clevertap';
import { BASE_TRANSACTION_TYPE_ID } from "@constants";
import { globalBalancesSelector, globalUiLoaderSelector } from "@config/store";
import useBaseFetch from "@modules/base/hooks/use-base-fetch/use-base-fetch";
import styles from "./base-edition-container.module.sass";
import UiProgressBar from "@ui/ui-progress-bar";
import UiFooter from "@ui/ui-footer";
import UiSteps from "@ui/ui-steps";
import BaseTourTooltip from "../base-tour-tooltip";

interface BaseEditionContainerProps { }

const BaseEditionContainer: FC<BaseEditionContainerProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(globalUiLoaderSelector);
  const { step, footer } = useSelector(baseUiSelector);
  const { t } = useTranslation();
  const { createNewBase } = useBaseFetch();
  const balances = useSelector(globalBalancesSelector);

  useEffect(() => {
    Clevertap.pushEvent(MOVEMENT_START, { type: BASE_TRANSACTION_TYPE_ID });
  }, []);

  useEffect(() => {
    dispatch(actions.restartState());
  }, [dispatch]);

  useEffect(() => {
    return () => { dispatch(actions.setStep({ currentStep: 1 })) }
  }, [dispatch]);

  const handleNextButtonClick = useCallback((): void => {
    if (footer.hasSubmitFunction) createNewBase();
    else navigate(String(footer.routeToNext));
  }, [createNewBase, footer, navigate]);

  const handleBackButtonClick = useCallback((): void => {
    navigate(String(footer.routeToBack));
  }, [footer.routeToBack, navigate]);

  return (
    <div className={styles.container}>
      {(balances.hasBase && step.totalSteps) && <UiProgressBar currentStep={step.currentStep || 0} totalSteps={step.totalSteps} />}

      <div className={styles.steps}>
        {balances.hasBase ? (
          <UiSteps
          step={t("general.step", { current: step.currentStep, total: step.totalSteps })}
          stepName={t(`${step.stepName}`)}
          />
          ) : <span className={styles.stepName}>{t(`${step.stepName}`)}</span>
        }
      </div>

      <Outlet />

      <BaseTourTooltip />

      <UiFooter
        isDisabled={footer.isDisabled}
        onLeftClick={handleBackButtonClick}
        onRightClick={handleNextButtonClick}
        isLoading={isLoading}
        hasBackButtonHidden={!balances.hasBase}
        currentStep={step.currentStep}
        totalSteps={step.totalSteps}
        nextButtonText="Bolsillo.base.footerbutton1.title1"
        finalButtonText="Bolsillo.base.footerbutton2.title1"
      />
    </div>
  );
};

export default BaseEditionContainer;
