import { useState } from "react";
import { useSelector } from "react-redux";
import { TransactionsByDate } from "@entities";
import { globalUserSelector } from "@config/store";
import { fetchGetTransactions } from "@services";
import { DEFAULT_TRANSACTIONS_PER_PAGE, TRANSACTIONS_PER_PAGE } from "@constants";

const useSearchTransactions = () => {
  const user = useSelector(globalUserSelector);
  const [pagination, setPagination] = useState({ currentPage: 0, totalPages: 0 })
  const [results, setResults] = useState<TransactionsByDate[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [hasNoResults, sethasNoResults] = useState(false);
  const [currentValue, setCurrentValue] = useState('');

  const search = (value: string) => {
    setIsLoading(true);
    fetchGetTransactions(user.storeId, {
      search: value.trim(),
      limit: DEFAULT_TRANSACTIONS_PER_PAGE,
      skip: 0,
    })
      .then(({ data, currentPage, totalPages }) => {
        setResults(data);
        setPagination({ currentPage, totalPages });
        sethasNoResults(!data.length);
        setCurrentValue(value);
      })
      .catch(() => true)
      .finally(() => setIsLoading(false))
  };

  const getNextPage = (page: number) => {
    setIsLoadingNextPage(true);
    fetchGetTransactions(user.storeId, {
      search: currentValue,
      limit: DEFAULT_TRANSACTIONS_PER_PAGE + (page * TRANSACTIONS_PER_PAGE),
      skip: 0,
    })
      .then(({ data, currentPage, totalPages }) => {
        setResults(data);
        setPagination({ currentPage, totalPages });
      })
      .catch(() => true)
      .finally(() => setIsLoadingNextPage(false))
  }

  const hasNextPage = () => pagination.currentPage !== pagination.totalPages;

  return {
    results,
    hasNextPage,
    isLoading,
    search,
    hasNoResults,
    isLoadingNextPage,
    getNextPage
  }

}

export default useSearchTransactions;
