import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SERVICES_MENU_ITEMS } from '@modules/home/constants';
import styles from './home-services-menu.module.sass';
import DashboardObTooltip from '../home-dashboard-ob-tooltip';

interface HomeServicesMenuProps { }

const HomeServicesMenu: FC<HomeServicesMenuProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      {SERVICES_MENU_ITEMS.map((i, index) => (
        <div key={`services-menu-item-${index}`} className={styles.item} onClick={() => navigate(i.routeTo)}>
          <div className={styles.wrapperIcon}>
            <span className={`${styles.icon} ${i.icon}`} />
          </div>
          <span className={styles.text}>{t(i.name)}</span>
        </div>
      ))}

      <DashboardObTooltip />
      
    </div>
  );
}

export default HomeServicesMenu;
