export const provider = {
  spanish: {
    "provider_name_title": 'Nombre del proveedor',
    "provider_name_searchbar_placeholder": 'Seleccione o cree sus proveedores',
    "provider_name_top_selection": 'Más usados',
    "provider_name_recently_used": 'Usados recientemente',
    "provider_options_title": 'Selecciona tu proveedor',
    "provider_anounce_text": 'Llevando la lista de tu proveedor hacer tus cierres será más fácil que nunca',
    "provider_creation_button_text": 'Crear',
    "provider_most_used_empty_state_text": 'Aún no has seleccionado un proveedor',
    "provider_most_recent_empty_state_text": 'No tienes proveedores usados recientemente',
  },
  portuguese: {
    "provider_name_title": 'Nome do fornecedor',
    "provider_name_searchbar_placeholder": 'Selecione ou crie seus fornecedores',
    "provider_name_top_selection": 'Mais usados',
    "provider_name_recently_used": 'Mais recentes',
    "provider_options_title": 'Selecione o seu fornecedor',
    "provider_anounce_text": 'Trazer a lista do seu fornecedor para seus balanços será mais fácil do que nunca',
    "provider_creation_button_text": 'Criar',
    "provider_most_used_empty_state_text": 'Ainda não selecionou nenhum fornecedor',
    "provider_most_recent_empty_state_text": 'Não possui nenhum fornecedor recente',
  }
};
