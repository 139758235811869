import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ROUTES, UXCAM_SCREENS } from '@constants';
import { setUXCamScreen } from '@utils/webview';
import { actions } from './store';
import BaseRouter from './routers';
import styles from './base.module.sass';

interface BaseProps { }

const Base: FC<BaseProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    setUXCamScreen(UXCAM_SCREENS.base)
  }, [])

  useEffect(() => {
    return () => { dispatch(actions.setEscapeRoute(ROUTES.home)); }
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <BaseRouter />
    </div>
  )
}

export default Base;