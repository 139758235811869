import React, { memo, FC } from "react";
import { t } from 'i18next';
import styles from "./ui-footer.module.sass";
import UiSquareButtonIcon from "@ui/ui-square-button-icon";
import UiLoaderSpinner from "@ui/ui-loader-spinner";

interface UiFooterProps {
  isDisabled?: boolean;
  hasBackButtonHidden?: boolean;
  onRightClick?: () => void;
  onLeftClick?: () => void;
  id?: string;
  isLoading?: boolean;
  nextButtonText?: string;
  finalButtonText?: string;
  totalSteps?: number;
  currentStep?: number;
}

const UiFooter: FC<UiFooterProps> = ({
  isDisabled,
  hasBackButtonHidden,
  onRightClick,
  onLeftClick,
  id,
  isLoading,
  nextButtonText = 'general.footer_next_button_text',
  finalButtonText = 'general.footer_final_button_text',
  totalSteps = 1,
  currentStep = 1,
}) => {
  const handleButtonClick = (): void => {
    if (!isDisabled && !isLoading && !!onRightClick) onRightClick();
  }

  const handleBackButtonClick = (): void => {
    if (!isLoading && !!onLeftClick) onLeftClick();
  }

  const isLastStep = currentStep === totalSteps;

  return (
    <div className={styles.wrapper} id={id}>
      <div className={`${styles.backButton} ${hasBackButtonHidden && styles.backButtonHidden}`}>
        <UiSquareButtonIcon
          icon="icon-arrow-1"
          onClick={handleBackButtonClick}
          isSecundary
        />
      </div>
      <div className={styles.resumeWrapper} onClick={handleButtonClick} data-testid='resume-footer-button'>

        <span className={`${styles.resume} ${isDisabled && styles.disabled}`}>
          {t(isLastStep ? finalButtonText : nextButtonText)}
        </span>

        <button
          data-testid='next-footer-button'
          className={`${styles.button} ${isDisabled && styles.disabled}`}
          disabled={isDisabled}
        >
          {isLoading
            ? <UiLoaderSpinner />
            : <span className={`icon-${isLastStep ? 'check' : 'arrow-1'} ${styles.icon} ${!isLastStep && styles.rotate}`} />
          }
        </button>
      </div>
    </div>
  );
};

export default memo(UiFooter);
