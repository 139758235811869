import { t } from "i18next";

export const getPhoneWithAreaCode = (phone: string) => {
  return `${t("country.area_code")}${phone}`
}

export const getWhatsAppUrl = (phone: string, message: string) => {
  return `https://api.whatsapp.com/send?phone=${getPhoneWithAreaCode(phone)}&text=${message}`
};

export const sendWhatsApp = (message: string, phone: string) => {
  let link = document.createElement('a');
  link.setAttribute('href', getWhatsAppUrl(phone, message));
  link.setAttribute('target', '_blank');
  link.setAttribute('rel', 'noreferrer');
  link.click();
};
