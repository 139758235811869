import React, { FC } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { CUSTOMERS_PATH, TABS } from "../constants";
import CustomersHome from "../pages/customers-home";
import CustomerDetail from "../pages/customer-detail";
import CustomersEdition from "../pages/customers-edition";
import CustomersCreation from "../pages/customers-creation";
import CustomersLoanTransaction from "../pages/customers-loan-transaction";
import CustomersContactTransaction from "../pages/customers-contact-transaction";
import CustomersPaymentTransaction from "../pages/customers-payment-transaction";
import CustomersCreationLoanAmount from "../pages/customers-creation-loan-amount";

interface CustomersRouterProps { }

const CustomersRouter: FC<CustomersRouterProps> = () => {

  return (
    <Routes>
      <Route index element={<CustomersHome />} />

      <Route path={`${TABS.contact_transaction}/:contactId`} element={<CustomersContactTransaction />} />

      <Route path={`${TABS.creation}/*`} element={<Outlet />}>
        <Route index element={<CustomersCreation />} />
        <Route path={`${TABS.transaction_amount}/:customerId`} element={<CustomersCreationLoanAmount />} />
      </Route>

      <Route path={`${TABS.detail}/:id/*`} element={<Outlet />} >
        <Route index element={<CustomerDetail />} />
        <Route path={TABS.deleted} element={<CustomerDetail wasDeleted />} />
        <Route path={TABS.loan_transaction} element={<CustomersLoanTransaction />} />
        <Route path={TABS.payment_transaction} element={<CustomersPaymentTransaction />} />
        <Route path={TABS.edition} element={<CustomersEdition />} />
      </Route>

      <Route path="*" element={<Navigate to={CUSTOMERS_PATH} />} />
    </Routes>
  );
};

export default CustomersRouter;

