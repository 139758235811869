import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { ROUTES } from '@constants';
import { actions as globalActions } from '@config/store';
import { boxClosureDetailSelector } from '@modules/box-closure/store';
import styles from './box-closure-detail.module.sass';
import TransactionsDetailTransactionList from '@modules/box-closure/components/box-closure-detail-transactions-list';
import ClosureDetailSkeleton from '@modules/box-closure/components/closure-detail-skeleton';
import BoxClosureDetailResumen from '@modules/box-closure/components/box-closure-detail-resumen';
import BoxClosureBalancesDetail from '@modules/box-closure/components/box-closure-balances-detail';
import useGetBoxClosureDetailInfo from '@modules/box-closure/hooks/use-get-box-closure-detail-info';
import UiDivider from '@ui/ui-divider';

interface BoxClosureDetailProps { }

const BoxClosureDetail: FC<BoxClosureDetailProps> = () => {
  const { isLoading } = useGetBoxClosureDetailInfo();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => { dispatch(globalActions.header({ title: '', hasBackClick: false })) }
  }, [dispatch]);

  if (isLoading) return <ClosureDetailSkeleton />

  return <BoxClosureDetailContent />
}

const BoxClosureDetailContent = () => {
  const dispatch = useDispatch();
  const boxClosureDetail = useSelector(boxClosureDetailSelector);

  useEffect(() => {
    dispatch(globalActions.header({
      hasBackClick: true,
      routeOnBackClick: ROUTES.home,
      hasRightClick: false,
      title: format(new Date(boxClosureDetail.startTime), 'dd MMMM yyyy', { locale: es })
    }))
  }, [dispatch, boxClosureDetail]);

  return (
    <div className={styles.wrapper}>

      <BoxClosureBalancesDetail />

      <UiDivider />

      <BoxClosureDetailResumen />

      <TransactionsDetailTransactionList />

    </div>
  )
}

export default BoxClosureDetail;
