import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as globalActions, globalUserSelector } from "@config/store";
import { actions, baseDataSelector, baseUiSelector } from "@modules/base/store";
import { BASE_TRANSACTION_TYPE_ID, TRANSACTIONS_PER_PAGE } from "@constants";
import { fetchGetTransactions } from "@services";

const usePagination = () => {
  const dispatch = useDispatch();
  const user = useSelector(globalUserSelector);
  const transactions = useSelector(baseDataSelector);
  const { paginator } = useSelector(baseUiSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getPage = useCallback((): void => {
    const body = {
      search: '',
      limit: TRANSACTIONS_PER_PAGE,
      skip: TRANSACTIONS_PER_PAGE * paginator.currentPage,
      transactionTypes: [BASE_TRANSACTION_TYPE_ID]
    };

    setIsLoading(true);
    fetchGetTransactions(user.storeId, body)
      .then(({ data, currentPage, totalPages }) => {
        let currentTransactions = [...transactions.baseTransactions];
        let newTransactions = [...data];
        const lastIndex = transactions.baseTransactions.length - 1;
        if (transactions.baseTransactions[lastIndex].date === data[0].date) {
          let lastDayTransactions = newTransactions.splice(0, 1);
          currentTransactions[lastIndex] = {
            date: data[0].date,
            transactionsByDate: [...transactions.baseTransactions[lastIndex].transactionsByDate, ...lastDayTransactions[0].transactionsByDate]
          };
        }
        dispatch(actions.setPagination({ currentPage, totalPages }));
        dispatch(actions.setBaseTransactions([...currentTransactions, ...newTransactions]));
      })
      .catch(() => dispatch(globalActions.setHasGlobalError(true)))
      .finally(() => setIsLoading(false));
  }, [dispatch, transactions, paginator.currentPage, user.storeId]);

  return {
    isLoading,
    getPage
  }
};

export default usePagination;
