import axios from "axios";
import { POCKET_API, TRANSACTIONS_API } from "@constants";
import {
  Customer, CustomerCreationBody, CustomerTransactionBody,
  CustomerDetail, DeleteCustomerBody, UpdateCustomerBody
} from "../entities";

export const fetchGetCustomers = (storeId: number): Promise<Customer[]> =>
  axios.get<Customer[]>(`${TRANSACTIONS_API}/customer/all/${storeId}`)
    .then(resp => resp.data);

export const fetchGetCustomerDetail = (customerId: number): Promise<CustomerDetail> =>
  axios.get<CustomerDetail>(`${TRANSACTIONS_API}/customer/detail/${customerId}`)
    .then(resp => resp.data);

export const fetchCreateNewTransaction = (body: CustomerTransactionBody): Promise<string> =>
  axios.post(`${POCKET_API}/transaction/loan`, body)
    .then(({ data }) => data.transactionId);

export const fetchCreateCustomer = (body: CustomerCreationBody): Promise<string> =>
  axios.post(`${POCKET_API}/customer`, body)
    .then(({ data }) => data.id);

export const fetchUpdateCustomer = (body: UpdateCustomerBody): Promise<boolean> =>
  axios.put(`${POCKET_API}/customer`, body)
    .then(() => true);

export const fetchDeleteCustomer = (body: DeleteCustomerBody): Promise<boolean> =>
  axios.put(`${POCKET_API}/customer/delete`, body)
    .then(() => true);

export const fetchSetReminderSent = (storeId: number, customerId: number): Promise<boolean> =>
  axios.put(`${TRANSACTIONS_API}/customer/has-reminder-sent`, { storeId, customerId })
    .then(() => true);
