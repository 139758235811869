import { CUSTOMERS_DELETED_PATH, CUSTOMERS_DETAIL_PATH, ROUTES } from "@constants";
import { CustomerDetail } from "../entities";

export const CUSTOMERS_PATH = ROUTES.customers;

export const TABS = {
  home: '',
  detail: CUSTOMERS_DETAIL_PATH,
  creation: 'creation',
  transaction: 'transaction',
  update: 'update',
  edition: 'edition',
  deleted: CUSTOMERS_DELETED_PATH,
  loans: 'loans',
  onboarding: 'onboarding',
  search: 'search',
  loan_transaction: 'loan_transaction',
  transaction_amount: 'transaction_amount',
  payment_transaction: 'payment_transaction',
  contact_transaction: 'contact_transaction',
}

export const CUSTOMER_DETAIL_INITIAL_STATE: CustomerDetail = {
  customerId: 0,
  debt: 0,
  hasReminderSent: false,
  name: '',
  phone: '',
  transactions: []
}

export const CUSTOMERS_DEFAULT_IMAGE = 'https://i.ibb.co/GJBcXsR/whatsapp-logo.png';

export const CUSTOMERS_PAYMENT_METHOD_ID = 1;

export const CUSTOMER_ALREADY_EXIST_REGEX = /This customer already exist!./ig;
export const PHONE_ALREADY_EXIST_REGEX = /This phone already exist./i;
export const CUSTOMER_ALREADY_EXIST = 'customer already exist';
export const PHONE_ALREADY_EXIST = 'phone already exist';
