import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions as globalActions } from '@config/store';
import PaymentMethod from "@modules/income/components/payment-method";
import { INCOME_PAYMENT_METHOD_STEP } from "@modules/income/constants";
import { actions, currentPaymentMethodSelector, currentAmountSelector } from "@modules/income/store";
import { ROUTES } from "@constants";

interface IncomePaymentMethodsProps { }

const IncomePaymentMethods: FC<IncomePaymentMethodsProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentIncome = useSelector(currentPaymentMethodSelector);
  const amountIncome = useSelector(currentAmountSelector);

  useEffect(() => {
    if (!amountIncome) navigate(ROUTES.income);
  }, [amountIncome, navigate]);

  useEffect(() => {
    dispatch(actions.setStep(INCOME_PAYMENT_METHOD_STEP));
    dispatch(
      actions.setFooter({
        routeToBack: ROUTES.income,
        isDisabled: !currentIncome,
        hasSubmitFunction: true,
      }));
    dispatch(globalActions.header({ hasRightClick: true }));
    return () => {
      dispatch(actions.setFooter({ hasSubmitFunction: false }));
    };
  }, [currentIncome, dispatch]);

  return (
    <PaymentMethod />
  );
};

export default IncomePaymentMethods;
